import VectorLeftSmall from '../../assets/images/b2b/vector-left-small.svg';
import VectorRightSmall from '../../assets/images/b2b/vector-right-small.svg';
import { translate } from 'i18n';

export const TransformWorkplace = () => {
    return (
      <div
        className="flex relative bg-dark mx-auto my-16 w-[80%] justify-content items-center justify-center md:justify-around min-h-[374px] md:min-h-[427px] rounded-tl-[24px] md:rounded-tl-[40px] rounded-tr-[64px] rounded-bl-[64px] md:rounded-br-[40px] rounded-br-[24px] flex-col md:flex-row">
        <div className="w-[80%] md:w-[35%]">
            <h1 className='font-semibold  text-2xl sm:text-4xl md:text-5xl/normal pb-4 text-white z-40'>Ready to Transform Your Workplace?</h1>
        </div>
        <div className="w-[80%] md:w-[35%]">
            <p className='text-white text-[14px] sm:text-xl leading-[18px] md:leading-[35px]'>
                {translate('b2b_transform_desc')}
            </p>
            <button className='px-5 py-3 mt-10 rounded-md font-medium text-white text-sm bg-gradient-custom from-start-carousel-one to-end-carousel-one'>{translate('contact_us_button')}</button>
        </div>
        <img src={VectorLeftSmall} className="absolute top-0 left-0 rounded-tl-[24px] md:rounded-tl-[40px] w-[160px] md:w-[300px]"/>
        <img src={VectorRightSmall} className="absolute bottom-0 right-0 rounded-br-[24px] md:rounded-br-[40px] w-[160px] md:w-[300px]"/>
      </div>
    );
};