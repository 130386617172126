import {YES_NO} from 'components/psychological_tests/yes_no';
import {IRIASEC} from 'model/psychological_tests/RIASEC';
import {useState} from 'react';
import {FiCheck, FiChevronDown, FiChevronLeft, FiChevronRight, FiClock, FiEdit, FiX} from 'react-icons/fi';
import Lottie from 'lottie-react';
import {lottieAnimation} from 'assets/lotte';
import R from 'assets/images/R.png';
import I from 'assets/images/I.png';
import A from 'assets/images/A.png';
import S from 'assets/images/S.png';
import E from 'assets/images/E.png';
import C from 'assets/images/C.png';

import Reactotron from 'reactotron-react-js';
import {PsychologicalTestsQuery} from 'lib/react-query/query-hooks';
import {useNavigate} from 'react-router-dom';
import {Social} from 'components/psychological_tests/social';

export const RIASECTest = () => {
    const questions: [IRIASEC.Item[], IRIASEC.Item[], IRIASEC.Item[]] = require('../../statics/riasec.json');
    const RIASEC_result: IRIASEC.Result = require('../../statics/riasec_result.json');
    const navigate = useNavigate();
    const [active, setActive] = useState(questions[0][0].id);
    const [active_part, setActivePart] = useState(0);
    const [show_part, setShowPart] = useState(0);
    const [modal_question, setModalQuestion] = useState(false);
    const [modal_done, setModalDone] = useState(false);
    const [modal_part_intro, setModalPartIntro] = useState(true);
    const [modal_quit, setModalQuit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [answears_part_1, setAnswaresPart1] = useState<any>({});
    const [final_result, setResultFinal] = useState<[] | undefined>(undefined);
    const [final_result_all, setResultFinalAll] = useState<{} | undefined>(undefined);
    const [answears_part_2, setAnswaresPart2] = useState<any>({});
    const [answears_part_3, setAnswaresPart3] = useState<any>({});
    const [answears_all_part, setAnswaresAllPart] = useState({R: [], I: [], A: [], S: [], E: [], C: []});
    const parts_intro = [
        {
            number_of_question: 32,
            time: 32,
            description:
                'Baca setiap pernyataan! Jika kamu setuju, pilih “setuju”. Jika tidak, pilih “tidak setuju”. Tidak ada benar dan salah dalah menjawab',
        },
        {
            number_of_question: 32,
            time: 32,
            description:
                'Baca setiap pernyataan! Jika kamu setuju, pilih “setuju”. Jika tidak, pilih “tidak setuju”. Tidak ada benar dan salah dalah menjawab',
        },
        {
            number_of_question: 32,
            time: 32,
            description:
                'Baca setiap pernyataan! Jika kamu setuju, pilih “setuju”. Jika tidak, pilih “tidak setuju”. Tidak ada benar dan salah dalah menjawab',
        },
    ];
    const {
        mutate: SaveResult,
        isLoading: save_load,
        data: save_data,
    } = PsychologicalTestsQuery.AddResult({
        onSettled(data, error, variables, context) {
            Reactotron.log!(data, error, variables, context);
        },
    });

    const handle_change = (question: IRIASEC.Item | undefined, answer: any, add?: number) => {
        switch (active_part) {
            case 0: {
                if (active == questions[0][questions[0]?.length - 1].id && (add || 0) > 0) {
                    setActive(questions[1][0].id);
                    setActivePart(1);
                    setShowPart(1);
                    setModalPartIntro(true);
                } else if (active !== questions[0][0].id || (add || 0) > 0) {
                    setActive(active + (add || 0));
                }
                if (!!question) {
                    setAnswaresPart1({...answears_part_1, [question.id]: answer});
                }
                break;
            }
            case 1: {
                if (active == questions[1][questions[1]?.length - 1].id && (add || 0) > 0) {
                    setActive(questions[2][0].id);
                    setActivePart(2);
                    setShowPart(2);
                    setModalPartIntro(true);
                } else if (active !== questions[1][0].id || (add || 0) > 0) {
                    setActive(active + (add || 0));
                } else {
                    setActive(questions[0][questions[0].length - 1].id);
                    setActivePart(0);
                    setShowPart(0);
                }
                if (!!question) {
                    setAnswaresPart2({...answears_part_2, [question.id]: answer});
                }
                break;
            }
            case 2: {
                if (active == questions[2][questions[2]?.length - 1].id && (add || 0) > 0) {
                    setLoading(false);
                    setModalDone(true);
                } else if (active !== questions[2][0].id || (add || 0) > 0) {
                    setActive(active + (add || 0));
                } else {
                    setActive(questions[1][questions[1].length - 1].id);
                    setActivePart(1);
                    setShowPart(1);
                }
                if (!!question) {
                    question && setAnswaresPart3({...answears_part_3, [question.id]: answer});
                }
                break;
            }
            default:
                break;
        }
        if (!!question && !!answer) {
            const is_on_answears = answears_all_part[question.description].find(i => i == question.id);
            if (is_on_answears) {
                if (answer == 'false') {
                    const new_answears = answears_all_part[question.description].filter(i => i !== question.id);
                    setAnswaresAllPart({...answears_all_part, [question.description]: new_answears});
                }
            } else if (answer == 'true') {
                setAnswaresAllPart({
                    ...answears_all_part,
                    [question.description]: [...answears_all_part[question.description], question.id],
                });
            }
        }
    };

    const handel_finish = () => {
        const result_item = ['R', 'I', 'A', 'S', 'E', 'C'];
        const result: any = {
            R: answears_all_part.R.length,
            I: answears_all_part.I.length,
            A: answears_all_part.A.length,
            S: answears_all_part.S.length,
            E: answears_all_part.E.length,
            C: answears_all_part.C.length,
        };

        const result_key = Object.keys(result).sort(function (a, b) {
            return result[b] - result[a];
        });

        const result_best_3 = result_key.slice(0, 3);

        const result_value = [result[result_best_3[0]], result[result_best_3[1]], result[result_best_3[2]]];

        let res: any = [];

        result_value.map(i => {
            result_item.map(res_i => {
                if (result[res_i] == i && i != 0) {
                    if (!!!res.find((find_i: any) => find_i == res_i)) {
                        res = [...res, res_i];
                    }
                }
            });
        });
        if (res.length > 0) {
            setResultFinal(res);
            setResultFinalAll({
                assesment_id: 'riasec',
                user_token: 'example user token',
                realistic_score: result.R,
                investigative_score: result.I,
                artistic_score: result.A,
                social_score: result.S,
                enterprising_score: result.E,
                conventional_score: result.C,
                name: 'riasec',
            });
            SaveResult({
                assesment_id: 'riasec',
                user_token: 'example user token',
                realistic_score: result.R,
                investigative_score: result.I,
                artistic_score: result.A,
                social_score: result.S,
                enterprising_score: result.E,
                conventional_score: result.C,
                name: 'riasec',
            });
        }
    };

    return loading || save_load ? (
        <div className=" w-full flex flex-col p-4">
            <Lottie
                className="h-3/4 w-3/4 sm:h-1/2 sm:w-1/2 self-center"
                animationData={lottieAnimation.tedoo_naik_turun}
            />
            <text className=" self-center font-semibold text-xl">Tunggu Sejenak</text>
            <text className=" self-center font-normal text-gray-600 text-md mt-2">
                Kami sedang menganalisis jawabanmu
            </text>
        </div>
    ) : !!final_result ? (
        !!save_data ? (
            <div className=" flex flex-col m w-md h-screen p-6 pt-10">
                <div className=" flex justify-center mb-8">
                    {final_result.map(result_item => {
                        if (result_item == 'R') {
                            return <img src={R} alt="Teduh Logo" className={`w-1/6 -mx-3`} />;
                        }
                        if (result_item == 'I') {
                            return <img src={I} alt="Teduh Logo" className={`w-1/6 -mx-3`} />;
                        }
                        if (result_item == 'A') {
                            return <img src={A} alt="Teduh Logo" className={`w-1/6 -mx-3`} />;
                        }
                        if (result_item == 'S') {
                            return <img src={S} alt="Teduh Logo" className={`w-1/6 -mx-3`} />;
                        }
                        if (result_item == 'E') {
                            return <img src={E} alt="Teduh Logo" className={`w-1/6 -mx-3`} />;
                        }
                        if (result_item == 'C') {
                            return <img src={C} alt="Teduh Logo" className={`w-1/6 -mx-3`} />;
                        }
                    })}
                </div>
                <text className=" self-center text-gray-700 font-medium mb-2">Kode Minatmu :</text>
                <text className=" text-3xl font-semibold text-primary border w-fit px-4 py-2 rounded-md self-center">
                    {final_result.join(' ')}
                </text>
                <div className=" mt-8">
                    {final_result.map(result_item => {
                        const result: IRIASEC.Result_Item = RIASEC_result[result_item];
                        return (
                            <div className="pb-12">
                                <div className=" flex items-end mb-4">
                                    <div className=" flex border-2 border-primary justify-center align-middle items-center aspect-square h-12 ">
                                        <text className=" text-3xl text-primary font-medium ">
                                            {result.title.slice(0, 1)}
                                        </text>
                                    </div>
                                    <text className=" text-md ml-2 text-primary ">
                                        {result.title.substring(1).toUpperCase()}
                                    </text>
                                </div>
                                <text className=" font-normal">
                                    {result.description + '\n'}Berikut beberapa rekomendasi jurusan yang sesuai untukmu.
                                </text>
                                <div className=" mt-2">
                                    {result.description_poins.map(description_poin => {
                                        return (
                                            <div className=" flex items-center">
                                                <div className=" bg-primaryLightest rounded-full p-0.5">
                                                    <FiCheck className=" stroke-primary" />
                                                </div>
                                                <text className=" font-normal text-sm ml-1 my-1">
                                                    {description_poin}
                                                </text>
                                            </div>
                                        );
                                    })}
                                </div>

                                {/* <div className=" bg-primaryLightest p-4 sm:p-5 pl-3 rounded-md flex flex-row mt-4">
                                    <div className="w-0.5 my-4 bg-primary border-0 rounded mr-3 sm:mr-4" />
                                    <div>
                                        <text className=" font-semibold "> Related Pathway:</text>
                                        {result.pathway.map(pathway_point => {
                                            return (
                                                <div>
                                                    <text>{pathway_point}</text>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div> */}
                            </div>
                        );
                    })}
                </div>

                <Social with_aplicaiton_link />
            </div>
        ) : (
            <div className=" w-full flex flex-col p-4">
                <Lottie
                    className="h-3/4 w-3/4 sm:h-1/2 sm:w-1/2 self-center"
                    animationData={lottieAnimation.tedoo_panik}
                />
                <text className=" self-center font-semibold text-xl">Terjadi Kesalahan</text>
                <div className=" flex sm:flex-row flex-col mt-16">
                    <button className=" bg-white border border-danger rounded-md p-3 text-danger flex-1 sm:self-center">
                        Kembali
                    </button>
                    <div className=" p-3" />
                    <button
                        className=" bg-primary rounded-md p-3 text-white flex-1 sm:self-center"
                        onClick={() => SaveResult(final_result_all)}
                    >
                        Coba Lagi
                    </button>
                </div>
            </div>
        )
    ) : (
        <div className=" flex flex-col m w-full h-screen">
            <dialog open={modal_quit}>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none ">
                    <div className=" w-full max-w-xl flex flex-col bg-white rounded-md p-4 mx-2">
                        <text className=" font-semibold text-xl">Keluar dari Tes?</text>
                        <text className=" my-4">
                            Progres pekerjaanmu akan terbuang dan kamu harus mengulangi tes dari awal.
                        </text>
                        <div className=" flex">
                            <button
                                onClick={() => setModalQuit(false)}
                                className=" border border-primary rounded-md flex flex-1 p-3 text-primary justify-center"
                            >
                                Tidak Jadi
                            </button>
                            <div className="m-2" />
                            <button
                                onClick={() => navigate('/psychological_tests/riasec')}
                                className=" bg-danger text-white rounded-md flex flex-1 p-3 justify-center"
                            >
                                Keluar
                            </button>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black" />
            </dialog>

            <dialog open={modal_question} className="modal w-full max-w-6xl h-full z-30 top-0 ">
                <div className="mb-2 flex flex-col basis-1/3 px-4 bg-white">
                    <button onClick={() => setModalQuestion(false)} className=" self-start mt-2 -ml-2">
                        <FiX size={40} />
                    </button>
                    <div>
                        <h1 className=" font-medium mb-8 text-center text-xl">Peta Soal</h1>
                    </div>
                    <div className=" flex self-center p-3 pl-1.5 bg-secondary rounded-md">
                        <div className="w-0.5 ml-1.5 bg-primary border-0 rounded mr-3 sm:mr-4" />
                        <div className="flex ">
                            <div className=" flex items-center ">
                                <div className=" w-3 h-3 bg-green rounded-full mr-1.5" />
                                <text className=" text-sm">Terisi</text>
                            </div>
                            <div className=" flex items-center mx-4 ">
                                <div className=" w-3 h-3 bg-gray-400 rounded-full mr-1.5" />
                                <text className=" text-sm">Kosong</text>
                            </div>
                            <div className=" flex items-center ">
                                <div className=" w-3 h-3 bg-primary rounded-full mr-1.5" />
                                <text className=" text-sm">Sedang diisi</text>
                            </div>
                        </div>
                    </div>
                    {questions.map((part_item, part_index: number) => {
                        return (
                            <div key={part_index} className="flex flex-col ">
                                <button
                                    className=" mt-8 mb-4 flex flex-row justify-between"
                                    onClick={() => {
                                        setShowPart(part_index);
                                    }}
                                >
                                    <h1 className=" font-medium  text-center text-xl">Bagian {part_index + 1}</h1>
                                    <div className=" px-2 py-1 border rounded-md ml-2">
                                        <FiChevronDown />
                                    </div>
                                </button>
                                {show_part == part_index ? (
                                    <div className=" grid grid-cols-5 gap-3 md:grid-cols-6 px-3 border py-3 rounded-md">
                                        {part_item.map((item, index: number) => {
                                            const is_active = item.id == active;
                                            const is_answered = !!(
                                                part_index == 0
                                                    ? answears_part_1
                                                    : part_index == 1
                                                    ? answears_part_2
                                                    : answears_part_3
                                            )[item.id];
                                            return (
                                                <button
                                                    className={`
                                                        ${is_active ? 'bg-primary' : 'bg-white'}
                                                        ${
                                                            is_active
                                                                ? 'text-white'
                                                                : is_answered
                                                                ? 'text-green font-semibold'
                                                                : 'text-gray-500'
                                                        }
                                                        aspect-square rounded-full sm:hover:bg-primary sm:hover:text-white`}
                                                    onClick={() => {
                                                        setActive(item.id);
                                                        setActivePart(part_index);
                                                        setModalQuestion(false);
                                                    }}
                                                >
                                                    {index + 1}
                                                </button>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
                </div>
            </dialog>

            <dialog open={modal_done}>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none ">
                    <div className={`bg-white rounded-xl w-10/12 max-w-xl sm:w-1/2 h-fit flex flex-col p-4 sm:p-8`}>
                        <h1 className=" font-semibold text-center text-xl">Akhiri Tes ?</h1>
                        <Lottie className="h-1/2 w-1/2 self-center" animationData={lottieAnimation.tedoo_naik_turun} />
                        {/* <h1 className=" font-normal  text-center text-md">
                            Semua jawaban akan kami olah untuk menentukan bakat Anda.
                        </h1> */}
                        <h1 className=" font-normal  text-left text-sm mt-4">
                            Setelah mengakhiri tes, Anda tidak dapat mengubah jawaban yang telah Anda pilih sebelumnya.
                        </h1>
                        <div className=" flex flex-1 mt-8">
                            <button
                                onClick={() => setModalDone(false)}
                                className=" flex sm:flex-1  bg-white border border-danger justify-center rounded-md py-1.5 px-3 text-center text-danger"
                            >
                                Tidak Jadi
                            </button>
                            <div className="m-1" />
                            <button
                                onClick={() => handel_finish()}
                                className=" flex flex-1 bg-primary rounded-md py-1.5 px-3 justify-center text-white"
                            >
                                Akhiri Tes
                            </button>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black" />
            </dialog>

            <dialog open={modal_part_intro}>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none ">
                    <div className=" w-full max-w-xl flex flex-col items-center bg-white rounded-md pt-32 sm:pt-0">
                        <button
                            onClick={() => {
                                setModalPartIntro(false);
                            }}
                            className=" self-start m-2"
                        >
                            <FiX size={40} />
                        </button>
                        <div
                            className={`aspect-video max-w-md w-full flex flex-col justify-end bg-card-1 bg-no-repeat bg-cover relative p-8 mt-8`}
                        >
                            <img
                                src={R}
                                className=" aspect-square w-4/6 right-0 top-0 absolute -mt-24 -mr-8 sm:-mr-12 sm:-mt-32"
                            />
                            <text className=" font-normal text-gray-500">TES RIASEC</text>
                            <text className=" font-semibold text-left sm:text-2xl text-xl">
                                Ketahui Jalur Karier yang Cocok Untukmu
                            </text>
                        </div>

                        <div className=" flex flex-row mt-4">
                            <div className=" flex items-center ">
                                <div className=" bg-slate-400 p-1.5 rounded-full">
                                    <FiEdit size={16} className=" stroke-white" />
                                </div>
                                <text className=" text-slate-500 text-md ml-2">
                                    {parts_intro[active_part].number_of_question} soal
                                </text>
                            </div>
                            <div className=" flex items-center ml-4 ">
                                <div className=" bg-slate-400 p-1.5 rounded-full">
                                    <FiClock size={16} className=" stroke-white" />
                                </div>
                                <text className=" text-slate-500 text-md ml-2">
                                    {parts_intro[active_part].time} menit
                                </text>
                            </div>
                        </div>
                        <div className=" p-4 mt-4">
                            <text className=" font-semibold text-xl">Bagian {active_part + 1}</text>
                        </div>
                        <div className=" bg-primaryLightest p-4 sm:p-5 pl-3 rounded-md flex flex-row m-4">
                            <div className="w-0.5 bg-primary border-0 rounded mr-3 sm:mr-4" />
                            <text>{parts_intro[active_part].description}</text>
                        </div>
                        <div className=" mt-8 pb-32 sm:pb-4">
                            <Social with_aplicaiton_link={false} />
                        </div>

                        <div className=" w-full flex bg-white shadow-box sm:shadow-none items-center fixed sm:relative bottom-0 justify-center mt-8 p-4 rounded-md">
                            <button
                                onClick={() => setModalPartIntro(false)}
                                className=" w-full bg-primary p-3 text-white rounded-md text-center "
                            >
                                Mulai Tes
                            </button>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black" />
            </dialog>

            <div className=" flex-1 sm:flex">
                <div className="mb-2 hidden sm:flex flex-col basis-1/3 px-4 ">
                    <div>
                        <h1 className=" font-medium py-6 text-center text-xl">Peta Soal</h1>
                    </div>
                    <div className=" flex self-center p-3 pl-1.5 bg-secondary rounded-md">
                        <div className="w-0.5 ml-1.5 bg-primary border-0 rounded mr-3 sm:mr-4" />
                        <div className=" flex flex-wrap ">
                            <div className=" flex items-center mr-2 ">
                                <div className=" w-3 h-3 bg-green rounded-full mr-1.5" />
                                <text>Terisi</text>
                            </div>
                            <div className=" flex items-center mr-2">
                                <div className=" w-3 h-3 bg-gray-400 rounded-full mr-1.5" />
                                <text className=" text-sm">Kosong</text>
                            </div>
                            <div className=" flex items-center mr-2">
                                <div className=" w-3 h-3 bg-primary rounded-full mr-1.5" />
                                <text className=" text-sm">Sedang diisi</text>
                            </div>
                        </div>
                    </div>
                    {questions.map((part_item, part_index: number) => {
                        return (
                            <div key={part_index} className="flex flex-col ">
                                <button
                                    className=" mt-8 mb-4 flex flex-row justify-between"
                                    onClick={() => setShowPart(part_index)}
                                >
                                    <h1 className=" font-medium  text-center text-xl">Bagian {part_index + 1}</h1>
                                    <div className=" px-2 py-1 border rounded-md ml-2">
                                        <FiChevronDown />
                                    </div>
                                </button>
                                {show_part == part_index ? (
                                    <div className=" grid grid-cols-5 gap-3 md:grid-cols-6 px-3 border py-3 rounded-md">
                                        {part_item.map((item, index: number) => {
                                            const is_active = item.id == active;
                                            const is_answered = !!(
                                                part_index == 0
                                                    ? answears_part_1
                                                    : part_index == 1
                                                    ? answears_part_2
                                                    : answears_part_3
                                            )[item.id];

                                            return (
                                                <button
                                                    className={`
                                                        ${is_active ? 'bg-primary' : 'bg-white'}
                                                        ${
                                                            is_active
                                                                ? 'text-white'
                                                                : is_answered
                                                                ? 'text-green font-semibold'
                                                                : 'text-gray-500'
                                                        }
                                                        aspect-square rounded-full hover:bg-primary hover:text-white`}
                                                    onClick={() => {
                                                        setActive(item.id);
                                                        setActivePart(part_index);
                                                    }}
                                                >
                                                    {index + 1}
                                                </button>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
                </div>
                <div className=" hidden h-full w-px bg-gray-200 my-6 mx-2 sm:flex" />
                <div className=" flex flex-1 flex-col mx-4">
                    <button onClick={() => setModalQuit(true)} className=" absolute top-2 -ml-2">
                        <FiX size={40} />
                    </button>
                    <h1 className=" font-medium py-6 text-center text-xl">Bagian {active_part + 1}</h1>
                    <div className=" flex flex-row mt-10">
                        <div className=" flex align-bottom items-end ml-4 sm:ml-32">
                            <h1 className=" font-normal text-center text-2xl text-gray-500">
                                {questions[active_part].findIndex(i => i.id == active) + 1}
                            </h1>
                            <h1 className=" font-normal text-center text-sm text-gray-500">
                                {' '}
                                / {questions[active_part]?.length}
                            </h1>
                        </div>
                        <button
                            onClick={() => setModalQuestion(true)}
                            className=" px-2 py-1 border self-center rounded-md ml-2 sm:hidden"
                        >
                            <FiChevronDown />
                        </button>
                    </div>
                    <div className="bg-gray-200 rounded-full h-2 dark:bg-gray-300 mt-4 mx-4 sm:mx-32">
                        <div
                            className={` bg-primary h-2 rounded-full`}
                            style={{
                                width:
                                    (
                                        (Object.keys(
                                            active_part == 0
                                                ? answears_part_1
                                                : active_part == 1
                                                ? answears_part_2
                                                : answears_part_3,
                                        ).length /
                                            questions[active_part]?.length) *
                                        100
                                    ).toString() + '%',
                            }}
                        />
                    </div>
                    <YES_NO
                        key={(questions[active_part].find(i => i.id == active) || {id: 0}).id}
                        answears={{...answears_part_1, ...answears_part_2, ...answears_part_3}}
                        data={questions[active_part].find(i => i.id == active)}
                        onClick={handle_change}
                        yes={active_part == 0 ? 'Setuju' : 'Ya'}
                        no={active_part == 0 ? 'Tidak Setuju' : 'Tidak'}
                    />
                    <div className=" sm:flex w-full bg-white hidden mt-10 ">
                        <button
                            onClick={() => handle_change(undefined, undefined, -1)}
                            disabled={active == questions[0][0].id}
                            className=" bg-white text-white  mb-4 h-12 py-3 rounded-md border border-primary aspect-square flex justify-center disabled:bg-gray-200 disabled:border-gray-300"
                        >
                            <FiChevronLeft
                                className={`self-centersa ${
                                    active == questions[0][0].id ? 'stroke-gray-500' : 'stroke-primary'
                                }`}
                                size={21}
                            />
                        </button>
                        <button
                            disabled
                            onClick={() => setModalQuestion(true)}
                            className=" bg-white border text-white mx-2 mb-4s h-12 py-3 flex-1 rounded-md flex flex-row justify-center "
                        >
                            <h1 className=" font-normal text-center text-md text-gray-500">
                                {questions[active_part].findIndex(i => i.id == active) + 1}
                            </h1>
                            <h1 className=" font-normal text-center text-md text-gray-500">
                                /{questions[active_part]?.length}
                            </h1>
                        </button>

                        <button
                            onClick={() => handle_change(undefined, undefined, 1)}
                            className=" bg-primary text-white  mb-4 h-12 py-3 flex-1 rounded-md flex flex-row justify-center "
                        >
                            <h1 className=" mr-2">Lanjut</h1>
                            <FiChevronRight size={21} className=" self-center" />
                        </button>
                    </div>
                </div>
            </div>
            <div className=" flex fixed bottom-0 right-0 w-full bg-white shadow-box sm:hidden p-1 -pbs-4 ">
                <button
                    onClick={() => handle_change(undefined, undefined, -1)}
                    disabled={active == questions[0][0].id}
                    className=" bg-white text-white m-2 mb-4 h-12 py-3 rounded-md border border-primary aspect-square flex justify-center disabled:bg-gray-200 disabled:border-gray-400"
                >
                    <FiChevronLeft
                        className={`self-centersa ${
                            active == questions[0][0].id ? 'stroke-gray-500' : 'stroke-primary'
                        }`}
                        size={21}
                    />
                </button>
                <button
                    onClick={() => setModalQuestion(true)}
                    className=" bg-white border text-white my-2 mx-1.5 mb-4s h-12 py-3 flex-1 rounded-md flex flex-row justify-center "
                >
                    <h1 className=" font-normal text-center text-md text-gray-500">
                        {questions[active_part].findIndex(i => i.id == active) + 1}
                    </h1>
                    <h1 className=" font-normal text-center text-md text-gray-500">
                        /{questions[active_part]?.length}
                    </h1>
                    <FiChevronDown className=" self-center ml-1" color="gray" size={16} />
                </button>

                <button
                    onClick={() => handle_change(undefined, undefined, 1)}
                    className=" bg-primary text-white m-2 mb-4 h-12 py-3 flex-1 rounded-md flex flex-row justify-center "
                >
                    <h1 className=" mr-2">Lanjut</h1>
                    <FiChevronRight size={21} className=" self-center" />
                </button>
            </div>
        </div>
    );
};
