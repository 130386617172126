import {COMMIT_SHA, DEPLOYMENT_DATE, VERSION} from 'config/env';
import moment from 'moment';
import React from 'react';

const Footer = (): JSX.Element => {
    return (
        <footer className="min-h-full w-full flex flex-col sm:flex-row itmes-start justify-between bg-secondary p-14 leading-tight gap-7 pt-10">
            <div className="flex flex-col gap-5">
                <div className="flex justify-start items-center gap-3">
                    <img className="h-12 rounded-full" src={require('../../assets/images/teduhlogo.png')} alt="logo" />
                    <h1 className="text-teduh text-2xl font-medium text-main">Teduh</h1>
                </div>
                <div>
                    <strong>PT. Teduh Karya Asia</strong>
                    <p>Menara Cakrawala Lantai 12 Unit 5A, JL. MH Thamrin, Menteng Jakarta Pusat 10340</p>
                </div>
                <div>
                    <strong>Unduh Aplikasi Teduh</strong>
                    <div className="flex flex-row">
                        <button
                            onClick={() =>
                                window.open('https://apps.apple.com/id/app/teduh-konseling-meditasi/id1637005394')
                            }
                        >
                            <img className="md:h-14" src={require('../../assets/images/appstore.png')} alt="logo" />
                        </button>
                        <button
                            onClick={() => window.open('https://play.google.com/store/apps/details?id=id.teduhio.app')}
                        >
                            <img className="md:h-20" src={require('../../assets/images/googleplay.png')} alt="logo" />
                        </button>
                    </div>
                    <div className="flex flex-col mt-2">
                        <text className=" text-sm text-gray-500">
                            Last Update : {moment(DEPLOYMENT_DATE).format('dddd, Do MMMM YYYY HH:mm')}
                        </text>
                        <div>
                            <text className=" font-semibold">{VERSION}</text>
                            <text className=" text-sm text-gray-500"> {COMMIT_SHA}</text>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-5">
                <div className="flex flex-col gap-2">
                    <strong>Filter Teduh</strong>
                    <button className=" text-left" onClick={() => window.open('https://teduh.io/konseling')}>
                        Telekonseling
                    </button>
                    <button className=" text-left" onClick={() => window.open('https://teduh.io/self-helpTrek')}>
                        Pertolongan Pertama Psikologis
                    </button>
                    <button className=" text-left" onClick={() => window.open('https://teduh.io/mindfulness')}>
                        Trek Meditasi
                    </button>
                    <button className=" text-left" onClick={() => window.open('https://teduh.io/moodtracker')}>
                        Pemantau Mood
                    </button>
                </div>
                <div className="flex flex-col gap-2">
                    <strong>Teduh for Business</strong>
                    <button className=" text-left" onClick={() => window.open('https://teduh.io/for-business')}>
                        Layanan untuk Perusahaan
                    </button>
                    <button className=" text-left" onClick={() => window.open('https://teduh.io/pricing')}>
                        Harga & Demonstrasi
                    </button>
                </div>
            </div>
            <div className="flex flex-col gap-2">
                <strong>Kontak Kami</strong>
                <button className=" text-left" onClick={() => window.open('https://wa.me/6281326397289')}>
                    Customer Service
                </button>
                <button className=" text-left" onClick={() => window.open('https://wa.me/6285219498199')}>
                    Partnership
                </button>
            </div>
        </footer>
    );
};

export default Footer;
