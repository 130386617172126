import teduhForBusinessIllustration from 'assets/images/home/carousel/teduh-for-business-illustration.webp';

export const TeduhForBusiness = () => {
  return (
    <div className='h-full w-full flex items-center justify-center bg-teduh-for-business-carousel bg-no-repeat bg-cover rounded-5xl relative'>
      <div className='flex items-center justify-between px-10 h-full w-[95%]'>
        <div>
          <h1 className='font-semibold text-3xl md:text-5xl pb-4 text-primary'>Teduh for Business</h1>
          <p className='mb-5 md:w-2/3 w-full text-sm md:text-md'>Layanan Teduh di tempat kerja Anda dapat meningkatkan kesehatan mental, performa, dan produktivitas karyawan.</p>
          <button className='px-4 py-1.5 rounded-md font-medium text-white text-sm bg-primary'>Lihat Selengkapnya</button>
        </div>
      </div>
      <img src={teduhForBusinessIllustration} className='hidden md:block absolute w-[55vw] bottom-0 md:-right-14 -right-8' />
    </div>
  );
};