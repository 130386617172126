import {ButtonRiwayatSesiTransaksi} from 'components/riwayat/ButtonRiwayatSesiTransaksi';
import {RiwayatSesi} from 'components/riwayat/RiwayatSesi';
import {RiwayatTransaksi} from 'components/riwayat/RiwayatTransaksi';
import {Template} from 'components/template/Template';
import {CounselingQuery} from 'lib/react-query/query-hooks';

import {useEffect, useState} from 'react';
import {ChevronLeft} from 'react-feather';
import {Link, useLocation} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';

export const RiwayatPage = (): JSX.Element => {
    const [activeButton, setActiveButton] = useState<'riwayatSesi' | 'riwayatTransaksi'>('riwayatSesi');
    const [page, setPage] = useState(1);
    const [pageMax, setPageMax] = useState(0);
    const [error, setError] = useState(false);
    const [data, setData] = useState<any[]>([]);

    const isMobile = useMediaQuery({maxWidth: 768});

    const {isLoading} = CounselingQuery.Session(
        {
            onSettled(result: any) {
                if (result?.data.length == 0) {
                    setError(true);
                } else {
                    setPageMax(result?.pagination.totalPage || 0 - 1);
                    page == 1 ? setData(result?.data || []) : setData([...data, ...(result?.data || [])]);
                }
            },
        },
        {
            page,
            limit: 10,
        },
    );
    console.log(error);
    console.log(pageMax);
    console.log(isLoading);
    console.log(data);

    const handleButtonClick = (buttonType: 'riwayatSesi' | 'riwayatTransaksi') => {
        setActiveButton(buttonType);
    };
    console.log(activeButton);
    const location = useLocation();

    useEffect(() => {
        setPage(1);
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <Template>
            <div className="flex justify-center">
                <div className="flex mt-14 md:mt-24">
                    {isMobile ? null : (
                        <Link to="/">
                            <div className="flex justify-center items-center h-12 w-12 rounded cursor-pointer border-gray300 border mr-14 ">
                                <ChevronLeft size={24} />
                            </div>
                        </Link>
                    )}

                    <div>
                        <div className="flex justify-center items-center w-[330px] h-14 mb-8 md:mb-0">
                            <p className="font-semibold text-2xl">Riwayat</p>
                        </div>
                        <ButtonRiwayatSesiTransaksi activeButton={activeButton} onButtonClick={handleButtonClick} />

                        {activeButton === 'riwayatSesi' ? <RiwayatSesi data={data} /> : <RiwayatTransaksi />}
                    </div>
                </div>
            </div>
        </Template>
    );
};
