import {AfterResponseHook, BeforeRequestHook, BeforeRetryHook} from 'ky';
import {store} from 'app/store';
import {setDataUndefined} from 'app/slice/main';

export const authBeforeResponseHooks: BeforeRequestHook = async ({headers}) => {
    store.subscribe(store.getState);
    const {main} = store.getState();
    const token = main.token;
    headers.set('Authorization', 'Bearer ' + token);
};

export const authBeforeRetryeHooks: BeforeRetryHook = ({request}) => {
    store.subscribe(store.getState);
    const {main} = store.getState();
    const token = main.token;
    if (token) {
        request.headers.set('Authorization', 'Bearer ' + token);
    }
};

export const authAfterResponseHooks: AfterResponseHook = async (req, option, res) => {
    const statusCode = [401].includes(res.status);
    store.subscribe(store.getState);
    const {main} = store.getState();
    const token = main.token;
    if (statusCode) {
        store.dispatch(setDataUndefined());
        // store.dispatch(userAction.setTrial(true));
        if (token) {
            // Alert.alert('Sesi Berakhir', 'Sesi telah berakhir silahkan login kembali');
            console.log(token);
        }
    }
};
