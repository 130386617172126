import { useState } from "react";
import { FiChevronRight } from "react-icons/fi";

interface Props {
  title: string;
  description: string;
}

export const Accordion = ({title, description}: Props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="w-full">
      <button 
        className="flex justify-between shadow-card rounded-lg p-4 items-center select-none w-full"
        onClick={handleOpen}
      >
        <h4 className="font-semibold text-left w-[95%] text-sm md:text-md">{title}</h4>
        <FiChevronRight className={`text-2xl transition duration-300 ${open ? 'rotate-90' : ''}`} />
      </button>
      <div className={`accordion_wrapper my-3 px-3 ${open ? 'open' : ''}`}>
        <div className="w-full accordion_expandable">
          <p className="text-navbar-accent text-sm whitespace-pre-line">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};