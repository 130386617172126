interface TotalProps {
    total?: any;
}

export const Total: React.FC<TotalProps> = ({total}) => {
    const formattedTotal = total.toLocaleString('id-ID', {
        style: 'currency',
        currency: 'IDR',
    });

    return (
        <div>
            <div className="flex items-center justify-end py-5 ">
                <p className="text-lg text-dark font-semibold">Total {formattedTotal}</p>
            </div>
        </div>
    );
};
