// import {ProfilRiwayatSesi} from './ProfilRiwayatSesi';

import {PsychologTitle} from 'components/PsychologTitle';
import moment from 'moment';
import {FiCalendar, FiClock, FiPhone, FiVideo} from 'react-icons/fi';

interface riwayatProps {
    data?: any;
    indexProfil: number;
}

export const RiwayatTransaksiDetail: React.FC<riwayatProps> = ({data, indexProfil}) => {
    if (!data || indexProfil < 0 || indexProfil >= data.length) {
        return null;
    }

    const getTitleLabel = (title: string): string => {
        const titleMapping: Record<string, string> = {
            'Senior Psychologist': 'Psikolog Senior',
            Psychologist: 'Psikolog',
            'Career Coach': 'Career Coach',
            Counselor: 'Konselor',
        };

        return titleMapping[title] || title;
    };

    const formatDate = (dateString: string): string => {
        const date = moment(dateString);
        return date.format('D MMM YYYY, HH.mm');
    };

    const formatDate2 = (dateString: string): string => {
        const date = moment(dateString);
        return date.format('dddd, D MMMM YYYY');
    };

    const transaksi = data[indexProfil];
    return (
        <div className="md:w-[770px] md:ml-[54px] cursor-pointer">
            <div className="w-[326px] mb-6">
                <div className="flex justify-between">
                    <p className="text-dark text-sm">ID Transaksi</p>
                    <p className="text-navbar-accent text-sm">ABCD1234</p>
                </div>
                <div className="flex justify-between mb-1">
                    <p className="text-dark text-sm">ABC1234</p>
                    <p className="text-navbar-accent text-sm">{formatDate(transaksi.date)}</p>
                </div>
                <div className="flex justify-between mb-1">
                    <p className="text-dark text-sm">Payment</p>
                    <p className="text-navbar-accent text-sm">{transaksi.type}</p>
                </div>
                <div className="border border-searchbar rounded-lg shadow-box p-3">
                    <p className="text-dark">1 sesi konseling {transaksi.media_type} call</p>
                    <p className="text-dark">{getTitleLabel(transaksi.psychologist_profile?.title)}</p>
                    <div className="flex justify-end text-lg text-dark font-semibold">
                        <p>
                            {transaksi.price.price.toLocaleString('id-ID', {
                                style: 'currency',
                                currency: 'IDR',
                            })}
                        </p>
                    </div>
                </div>
            </div>
            <div
                style={{width: '326px'}}
                className="px-4 py-4 md:mr-8 mb-3 rounded-lg shadow-box border border-searchbar cursor-pointer"
            >
                <div className="flex">
                    <div className="rounded-full overflow-hidden mr-3" style={{width: '72px', height: '72px'}}>
                        <img
                            src={transaksi.psychologist_profile?.profile_picture}
                            className="w-full h-full object-cover"
                            alt=""
                        />
                    </div>
                    <div>
                        <p className="font-semibold text-dark">{transaksi.psychologist_profile?.name_with_title}</p>
                        <div className="flex gap-2">
                            <PsychologTitle title={transaksi.psychologist_profile?.title} />
                            {transaksi.psychologist_profile?.secondary_title && (
                                <PsychologTitle title={transaksi.psychologist_profile?.secondary_title} />
                            )}
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <div className="flex items-center mb-3">
                        <div className="bg-secondary rounded-full w-7 h-7 flex items-center justify-center ">
                            <FiCalendar className="text-primary text-xl" />
                        </div>
                        <p className="text-sm text-navbar-accent ml-2">{formatDate2(transaksi.session?.schedule)}</p>
                    </div>
                    <div className="flex items-center mb-3">
                        <div className="bg-secondary rounded-full w-7 h-7 flex items-center justify-center ">
                            <FiClock className="text-primary text-xl " />
                        </div>
                        <p className="text-sm text-navbar-accent ml-2">
                            {transaksi.session?.hour}.00-{transaksi.session?.hour + 1}.00
                        </p>
                    </div>
                    <div className="flex items-center ">
                        <div className="bg-secondary rounded-full w-7 h-7 flex items-center justify-center ">
                            {transaksi.media_type === 'video' ? (
                                <FiVideo className="text-primary text-xl" />
                            ) : (
                                <FiPhone className="text-primary text-xl" />
                            )}
                        </div>
                        <p className="text-sm text-navbar-accent ml-2">{transaksi.media_type} call</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
