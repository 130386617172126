export const lottieAnimation: {[x: string]: any} = {
    under_construction: require('./under_construction.json'),
    pwlot: require('./pwlot.json'),
    pinlot: require('./pinlot.json'),
    session_done: require('./session_done.json'),
    question: require('./question.json'),
    work: require('./work.json'),
    development: require('./development.json'),
    app_update: require('./app_update.json'),
    no_internet: require('./no_internet.json'),
    not_time: require('./not_time.json'),
    failure: require('./failure.json'),
    success: require('./success.json'),
    start_session: require('./start_session.json'),
    signin: require('./signin.json'),
    tedoo_panik: require('./tedoo_panik.json'),
    tedoo_naik_turun: require('./tedoo_naik_turun.json'),
    tedoo_lambai: require('./tedoo_lambai.json'),
    tedoo_baterai_penuh: require('./tedoo_baterai_penuh.json'),
    tedoo_dasi: require('./tedoo_dasi.json'),
};
