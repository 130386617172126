import {
    AddPayment,
    AddReview,
    AddScreening,
    AddSession,
    CancelSession,
    DetailPayment,
    DetailSession,
    Discount_Code,
    EndSession,
    MethodPayment,
    Payment,
    Price,
    Psychologist,
    Review,
    Room,
    Session,
    UpdatePayment,
} from './hooks/counseling';
import {AddFeedback, GetCountries, GetLanguage, GetReligion, UploadFile} from './hooks/main';
import {AddResult} from './hooks/psychological_tests';
import {
    AddSubscription,
    DeleteProfile,
    EditLanguage,
    EditProfile,
    EditReminder,
    Login,
    Logout,
    Notification,
    Profile,
    Reminder,
} from './hooks/user';

export const MainQuery = {
    UploadFile,
    GetCountries,
    GetLanguage,
    GetReligion,
    AddFeedback,
};

export const UserQuery = {
    Login,
    Logout,

    Profile,
    EditProfile,
    DeleteProfile,

    Reminder,
    EditReminder,

    AddSubscription,

    EditLanguage,

    Notification,
};

export const CounselingQuery = {
    Psychologist,
    Price,

    Payment,
    DetailPayment,
    MethodPayment,
    UpdatePayment,
    AddPayment,

    Session,
    DetailSession,
    AddSession,
    CancelSession,
    EndSession,

    Discount_Code,

    Review,
    AddReview,

    AddScreening,

    Room,
};

export const PsychologicalTestsQuery = {
    AddResult,
};
