import {FiPhone, FiVideo} from 'react-icons/fi';

interface Props {
    type: 'video' | 'voice';
    with_label?: boolean;
    className?: string;
}

export const CounsellingType = ({type, with_label = true, className}: Props) => {
    const setType = () => {
        if (type == 'video') {
            return {
                label: 'Video',
                icons: <FiVideo className="text-primary" />,
            };
        }
        if (type == 'voice') {
            return {
                label: 'Voice',
                icons: <FiPhone className="text-primary" />,
            };
        }
        return undefined;
    };

    const item:
        | {
              label: string;
              icons: any;
          }
        | undefined = setType();

    return item ? (
        <div
            className={className}
            style={{justifyContent: 'center', alignItems: 'center', display: 'inline-flex', flexDirection: 'row'}}
        >
            <div className="p-1.5 bg-sky-blue rounded-full">{item.icons}</div>
            {with_label ? (
                <div className="ml-1">
                    <h2 className="font-normal text-sm">{item.label}</h2>
                </div>
            ) : null}
        </div>
    ) : null;
};
