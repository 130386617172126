import {GenerateTitleEn} from 'helpers/GenerateTitle';
interface Props {
    data: string;
    setData: (data: string) => void;
}
const Layanan = ({data, setData}: Props): JSX.Element => {
    const title_list = ['Psikolog Senior', 'Psikolog', 'Konselor', 'Career Coach'];
    // ['Psikolog Senior', 'Psikolog', 'Konselor'];
    return (
        <div className="mt-8">
            <h1 className="text-md font-semibold mb-2">Layanan</h1>
            <div className="flex flex-row gap-1 flex-wrap">
                {title_list.map((item: any, index) => {
                    const active = data == GenerateTitleEn(item);
                    return (
                        <button
                            key={index}
                            onClick={() => setData(GenerateTitleEn(item))}
                            className={`${active ? 'bg-primary text-white ' : 'bg-gray-200 text-gray-800'}
                            font-regular bg-black text-xs py-1.5 px-3 mt-1 rounded-full hover:bg-primaryLightest hover:text-primary
                            `}
                        >
                            {item}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default Layanan;
