import {useAppSelector} from 'hooks/useAppSelector';
import {PanduanKonseling} from './PanduanKonseling';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {CounselingQuery} from 'lib/react-query/query-hooks';
import {setPrices} from 'app/slice/main';
import moment from 'moment';
import {SessionCard} from 'components/SessionCard';

export const Sessions = () => {
    const {user} = useAppSelector(s => s.main);
    const [new_Date] = useState(new Date());
    const dispatch = useDispatch();

    const company_free_remaining_quota =
        user?.employment_details?.company?.num_remaining_teleconsult_quota_session || 0;

    const company_free_quota = user?.employment_details?.company?.num_teleconsult_quota_session || 0;

    const company_discount_off = user?.employment_details?.company?.teleconsult_discount_off || 0;

    const company_max_telekonsul_free_quota =
        user?.employment_details?.company?.max_teleconsult_quota_used_by_a_user || 0;

    const used_free_quota = user?.employment_details?.num_teleconsult_quota_used || 0;

    const used_discount_quota = user?.employment_details?.num_teleconsult_discount_quota_used || 0;

    const card_free =
        company_max_telekonsul_free_quota - used_free_quota > 0 &&
        company_free_quota > 0 &&
        company_free_remaining_quota > 0;

    const card_discount = 1 - used_discount_quota > 0 && company_discount_off > 0;

    // console.log('asdasdasdasdas')
    // console.log(company_free_remaining_quota)
    // console.log(company_free_quota)
    // console.log(company_discount_off)
    // console.log(company_max_telekonsul_free_quota)
    // console.log(used_free_quota)
    // console.log(used_discount_quota)
    // console.log(user)


    const {isLoading: prices_load} = CounselingQuery.Price({
        onSuccess(data) {
            dispatch(setPrices(data.data));
        },
        enabled: !!user ? true : false,
    });

    const is_new_user = moment(new_Date) < moment(user?.createdAt).add('day', 7);

    const {data: trx_data, isLoading: trx_load} = CounselingQuery.Payment(
        {
            enabled: !!user ? true : false,
        },
        {pending: 'true'},
    );

    const {data: sessions_data, isLoading: session_load} = CounselingQuery.Session(
        {
            enabled: !!user ? true : false,
        },
        {
            sort: 'schedule',
            status: ['paid', 'paid-admin', 'ongoing'],
        },
    );

    const {data: sessions_data_completed, isLoading: session_completed_load} = CounselingQuery.Session(
        {
            enabled: !!user ? true : false,
        },
        {
            sort: 'schedule',
            schedule_lte: moment.utc(new_Date).toISOString(),
            schedule_gte: moment.utc(new_Date).add(-7, 'day').toISOString(),
            status: ['completed'],
        },
    );

    const {data: session_promo, isLoading: session_promo_load} = CounselingQuery.Session(
        {
            enabled: !!user ? true : false,
        },
        {
            promo_code: 'HTRP7EQUC5',
            status: ['paid', 'ongoing', 'cancelled', 'unpaid', 'completed'],
        },
    );

    const {data: session_data_cancelled, isLoading: session_cancelled_load} = CounselingQuery.Session(
        {
            enabled: !!user ? true : false,
        },
        {
            sort: 'schedule',
            status: ['cancelled'],
        },
    );

    return (
        <div className="py-8 mt-12">
            {user ? (
                trx_load ||
                session_cancelled_load ||
                session_load ||
                prices_load ||
                session_completed_load ||
                session_promo_load ? (
                    <div className="grid sm:grid-cols-3 px-4">
                        <div className=" col-span-1 flex justify-center mr-2">
                            <div className=" self-center mb-4">
                                <text className="text-dark text-xl sm:text-4xl font-semibold span-1">
                                    {'Sesi Mendatang'}
                                </text>
                                <div />
                                <text className="text-dark text-xl sm:text-4xl font-semibold span-1">{'& Promo'}</text>
                            </div>
                        </div>
                        <div className="col-span-2 flex flex-wrap">
                            <div className=" b-4 ml-2 mr-2 p-4 rounded-xl min-w-max w-2/3 max-w-sm h-56 shadow-card flex flex-col animate-pulse mb-2">
                                <div className="flex flex-row align-middle justify-center">
                                    <div className=" dark:bg-primaryLightest bg-secondary aspect-square w-24 rounded-full"></div>
                                    <div className="flex-1 ">
                                        <div className=" dark:bg-primaryLightest bg-secondary h-6 w-1/1 rounded-full"></div>
                                        <div className="flex flex-row mt-2">
                                            <div className=" dark:bg-primaryLightest bg-secondary mr-2 h-6 flex-1 rounded-full mt-2"></div>
                                            <div className=" dark:bg-primaryLightest bg-secondary ml-2 h-6 flex-1 rounded-full mt-2"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" dark:bg-primaryLightest bg-secondary  h-14 flex-1 rounded-md mt-8"></div>
                            </div>
                            <div className=" b-4 ml-2 mr-2 p-4 rounded-xl min-w-max w-2/3 max-w-sm h-56 shadow-card flex flex-col animate-pulse">
                                <div className="flex flex-row align-middle justify-center">
                                    <div className=" dark:bg-primaryLightest bg-secondary aspect-square w-24 rounded-full"></div>
                                    <div className="flex-1 ">
                                        <div className=" dark:bg-primaryLightest bg-secondary h-6 w-1/1 rounded-full"></div>
                                        <div className="flex flex-row mt-2">
                                            <div className=" dark:bg-primaryLightest bg-secondary mr-2 h-6 flex-1 rounded-full mt-2"></div>
                                            <div className=" dark:bg-primaryLightest bg-secondary ml-2 h-6 flex-1 rounded-full mt-2"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" dark:bg-primaryLightest bg-secondary  h-14 flex-1 rounded-md mt-8"></div>
                            </div>
                        </div>
                    </div>
                ) : (sessions_data?.data.length || 0) > 0 ||
                  (session_data_cancelled?.data.length || 0) > 0 ||
                  (trx_data?.data.length || 0) > 0 ||
                  (sessions_data_completed?.data.length || 0) > 0 ? (
                    <div className="grid sm:grid-cols-3 px-4">
                        <div className=" col-span-1 flex justify-center mb-2 sm:mb-0 sm:mr-2">
                            <div className=" self-center sm-4 flex md:flex-col">
                                <text className="text-dark text-xl sm:text-4xl font-semibold span-1">
                                    {'Sesi Mendatang'}
                                </text>
                                <div />
                                <text className="text-dark text-xl sm:text-4xl font-semibold span-1 ml-2 sm:ml-0">
                                    {'& Promo'}
                                </text>
                            </div>
                        </div>

                        <div className=" col-span-2 flex flex-wrap gap-4 w-full">
                            {[...(trx_data?.data || [])].map((trx_item, index) => {
                                return !!trx_item.psychologist_profile ? (
                                    <SessionCard
                                        key={index}
                                        data={{
                                            ...trx_item.session,
                                            psychologist: trx_item.psychologist_profile,
                                        }}
                                        trx={trx_item}
                                    />
                                ) : null;
                            })}
                            {[...(sessions_data?.data || [])].map((session_item, index) => {
                                return <SessionCard key={index} data={session_item} />;
                            })}

                            {[...(sessions_data_completed?.data || [])].map((session_item, index) => {
                                return <SessionCard key={index} data={session_item} />;
                            })}

                            {[...(session_data_cancelled?.data || [])].map((session_item, index) => {
                                return <SessionCard key={index} data={session_item} />;
                            })}

                            {card_discount ? <SessionCard key={'b2b_discount'} additional_type="b2b_discount" /> : null}

                            {card_free ? <SessionCard key={'b2b_free'} additional_type="b2b_free" /> : null}

                            {is_new_user && !((session_promo?.data.length || 0) > 0) ? (
                                <SessionCard key={'new_user'} additional_type="new_user" />
                            ) : null}
                        </div>
                    </div>
                ) : (
                    <PanduanKonseling />
                )
            ) : (
                <PanduanKonseling />
            )}
        </div>
    );
};
