import { translate } from "i18n";

interface Props {
    image: any;
    number: string;
    description: any;
    isWhite: boolean;
    isNumberFirst: boolean;
    position: string;
    imageStyle: string;
}

export const PercentageCard = ({image, number, description, isWhite, isNumberFirst, position, imageStyle}: Props) => {
    const bgClassName = `w-[140px] sm:w-[200px] rounded-xl h-[140px] sm:h-[200px] shadow-[0_0px_20px_0px_rgb(0,56,161,0.15)] flex xl:align-center xl:absolute ${isWhite ? 'bg-white' : 'bg-dark'} ${position}`;
    return (
      <div className={bgClassName}>
       <img src={image} alt="person" className={imageStyle}/>
       <div className={isNumberFirst ? 'px-[12px] sm:px-[24px]' : 'flex flex-col-reverse px-[12px] sm:px-[24px]'}>
        <p className={`font-semibold text-2xl sm:text-4xl ${isWhite ? 'text-primary' : 'text-white'}`}>{number}</p>
        <p className={`text-[14px] sm:text-base ${isWhite ? 'text-navbar-accent' : 'text-white'}`}>{translate(description)}</p>
       </div>
      </div>
    );
};
