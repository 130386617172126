import { ResourceCard } from "./ResourceCard";
import VectorRight from "../../assets/images/b2b/vector-right.svg";
import VectorLeft from "../../assets/images/b2b/vector-left.svg";
import { useState } from 'react';
import { translate } from "i18n";
import { Markup } from "interweave";
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export const CheckWastedResources = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const [cardRef, cardInView] = useInView({
    triggerOnce: true,
  });
  const [controlVal, setControlVal] = useState(360); // initial value
  const controlMin = 1; 
  const controlMax = 1000; 
  const controlThumbWidth = 10; 
  const range = controlMax - controlMin;

    const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        setControlVal(parseInt(e.target.value));
    };

    const getPosition = () => {
        const position = ((controlVal - controlMin) / range) * 100;
        const positionOffset = Math.round((controlThumbWidth * position) / 100) - controlThumbWidth / 2;
        return `calc(max(${position}% - ${positionOffset}px, 40px))`;
    };

  return (
    <div className='flex flex-col py-12 px-[7%]'>
      <motion.div
        ref={ref}
        initial={{ opacity: 0, x: -100 }}
        animate={inView ? { opacity: 1, x: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        <h1 className='font-semibold text-2xl sm:text-4xl md:text-5xl/normal pb-4 text-black'><Markup content={translate('b2b_res_waste_title')}/></h1>
      </motion.div>
      <hr className="w-[60px] h-[10px] mt-4 mb-12 bg-primary border-0 rounded"/>
      <motion.div
        ref={ref}
        initial={{ opacity: 0, x: 100 }}
        animate={inView ? { opacity: 1, x: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        <p className='text-navbar-accent lg:w-[40%]  text-[14px] md:text-base'>{translate('b2b_res_waste_desc')}</p>
      </motion.div>
      
      <div className="mt-8 mb-16">
          <label typeof="small-range" className="flex mb-2 text-[10px] sm:text-sm font-medium text-navbar-accent justify-between">
          <p><span className="text-dark">1</span> {translate('b2b_res_waste_employee')}</p>
          <p><span className="text-dark">1000</span>  {translate('b2b_res_waste_employees')}</p>
          </label>
        <div className="relative">
          <input
            type="range"
            min={controlMin}
            max={controlMax}
            value={controlVal}
            onChange={handleInputChange}
            className='w-full'
            data-thumbwidth={controlThumbWidth}
          />
          <output style={{ position: 'absolute', left: getPosition(), transform: 'translateX(-50%)'}} className="top-[30px] sm:top-[50px] "><span className='text-[18px] sm:text-[36px] font-medium leading-[0px]'>{controlVal}</span> <span className='text-[12px] sm:text-[20px] text-navbar-accent leading-[0px]'>karyawan</span></output>
        </div>
       </div>
    
      <div className="flex gap-[20px] lg:gap-[120px] justify-center mb-8 sm:mb-16 flex-col lg:flex-row">
        <ResourceCard number={Math.ceil(controlVal * 0.4)} label={translate('b2b_res_waste_employee')} description={translate('b2b_res_waste_employee_desc')}/>
        <ResourceCard number={'8'} label={translate('b2b_res_waste_employees')} description={translate('b2b_res_waste_hours_desc')}/>
        <ResourceCard number={Math.ceil(controlVal * 0.25 * 5727914).toLocaleString()} label='Rupiah' description={translate('b2b_res_waste_rupiah')}/>
      </div>

  
      <motion.div 
        ref={cardRef}
        initial={{ opacity: 0, x: 100 }}
        animate={cardInView ? { opacity: 1, x: 0 } : {}}
        transition={{ duration: 1 }}
        className='bg-dark w-[90%] m-auto max-w-[830px] py-[32px] px-[32px] lg:px-[64px] rounded-[24px] relative min-h-[154px] flex items-center'>
        <img src={VectorLeft} className="absolute top-0 left-0 rounded-tl-[24px] w-[160px]"/>
        <p className="text-center text-white">
          <Markup content={translate('b2b_res_waste_card')}/>
        </p>  
        <img src={VectorRight} className="absolute bottom-0 right-0 rounded-br-[24px]"/>        
      </motion.div>
    </div>
  );
};
