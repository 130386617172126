import {Template} from 'components/template/Template';
import {EmployeeWellness} from 'components/b2b/EmployeeWellness';
import {TrustedBy} from 'components/b2b/TrustedBy';
import {Company} from 'components/b2b/Company';
import {OurService} from 'components/b2b/OurService';
import {Download} from 'components/b2b/Download';
import {HowWeCouldHelp} from 'components/b2b/HowWeCouldHelp';
import {CustomerReview} from 'components/b2b/CustomerReview';
import {Resources} from 'components/b2b/Resources';
import {TransformWorkplace} from 'components/b2b/TransformWorkplace';
import {CheckWastedResources} from 'components/b2b/CheckWastedResources';
import { ModalPopUp } from 'components/b2b/ModalPopUp';
import { useState } from 'react';

export const B2BHome = (): JSX.Element => {
    const [isModalOpen, setModalOpen] = useState(true);
  
    const closeModal = () => {
      setModalOpen(false);
    };
    
    return (
        <Template>
            <ModalPopUp isPopUpOpen={isModalOpen} onClose={closeModal}/>
            <EmployeeWellness />
            <TrustedBy />
            <hr className="w-72 h-0.5 mx-auto my-16 bg-primary border-0 rounded" />
            <Company />
            <hr className="w-72 h-0.5 mx-auto my-16 bg-primary border-0 rounded" />
            <CheckWastedResources />
            <OurService />
            <HowWeCouldHelp />
            <hr className="w-72 h-0.5 mx-auto my-16 bg-primary border-0 rounded" />
            <CustomerReview />
            <hr className="w-72 h-0.5 mx-auto my-16 bg-primary border-0 rounded" />
            <Download />
            <Resources />
            <TransformWorkplace />
        </Template>
    );
};
