import konselingIllustration from 'assets/images/home/carousel/konseling-illustration.png';
import {Link} from 'react-router-dom';

export const Konseling = () => {
    return (
        <div className="h-full w-full flex items-center justify-center bg-konseling-carousel bg-no-repeat bg-cover rounded-5xl relative">
            <div className="flex items-center justify-between px-10 h-full w-[95%]">
                <div>
                    <h1 className="font-semibold text-4xl md:text-6xl pb-4 text-transparent bg-clip-text bg-gradient-custom from-start-carousel-one to-end-carousel-one">
                        Konseling
                    </h1>
                    <p className="mb-5 text-sm md:text-md">Ceritakan masalahmu dan cari soluisnya bersama kami</p>
                    <Link to="/practitioners">
                        <button className="px-4 py-1.5 rounded-md font-medium text-white text-sm bg-gradient-custom from-start-carousel-one to-end-carousel-one">
                            Jadwalkan Sesi Konseling
                        </button>
                    </Link>
                </div>
                <img src={konselingIllustration} className="hidden md:block w-[32vw] absolute -bottom-4 right-24" />
            </div>
        </div>
    );
};
