interface ButtonRiwayatSesiTransaksiProps {
    activeButton: 'riwayatSesi' | 'riwayatTransaksi';
    onButtonClick: (buttonType: 'riwayatSesi' | 'riwayatTransaksi') => void;
}

export const ButtonRiwayatSesiTransaksi: React.FC<ButtonRiwayatSesiTransaksiProps> = ({
    activeButton,
    onButtonClick,
}) => {
    return (
        <div className="flex mb-6" style={{width: '330px'}}>
            <div
                className={`flex justify-center rounded-lg w-40 cursor-pointer mr-2 ${
                    activeButton === 'riwayatSesi' ? 'bg-primary' : 'bg-searchbar'
                }`}
                onClick={() => onButtonClick('riwayatSesi')}
            >
                <p className={`text-sm p-2 ${activeButton === 'riwayatSesi' ? 'text-white' : 'text-navbar-accent'}`}>
                    Riwayat Sesi
                </p>
            </div>
            <div
                className={`flex justify-center rounded-lg cursor-pointer w-40 ${
                    activeButton === 'riwayatTransaksi' ? 'bg-primary' : 'bg-searchbar'
                }`}
                onClick={() => onButtonClick('riwayatTransaksi')}
            >
                <p
                    className={`text-sm p-2 rounded-lg ${
                        activeButton === 'riwayatTransaksi' ? 'text-white' : 'text-navbar-accent'
                    }`}
                >
                    Riwayat Transaksi
                </p>
            </div>
        </div>
    );
};
