import {FiSunrise} from 'react-icons/fi';

export const SesiMendatang = (): JSX.Element => {
    return (
        <div className="flex flex-col items-center justify-center bg-yellow-accent rounded-lg mt-3 p-3">
            <p className="text-base text-dark">Masuk ruang konseling dengan klik </p>
            <div className="flex items-center">
                <div className="flex items-center mr-1 rounded-md p-2 border border-dark">
                    <FiSunrise className="mr-1" />
                    <p className="text-dark text-sm">Mendatang</p>
                </div>
                <p className="text-dark">di bagian atas layar</p>
            </div>
        </div>
    );
};
