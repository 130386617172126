import {MutationFunction, QueryFunction} from '@tanstack/react-query';
import {ErrorHandler} from 'helpers/ErrorHandler';
import {API} from 'lib/ky';
import {IMain, IUser} from 'model/api';

// res _ req
const Login: MutationFunction<IUser.Profile.Success_Login, IUser.Profile.Login> = async (data: IUser.Profile.Login) => {
    try {
        const resp: IUser.Profile.Success_Login = await API.post('auth/login', {json: data}).json();
        return resp;
    } catch (error) {
        ErrorHandler(error);
        throw error;
    }
};

const Logout: MutationFunction<any, any> = async () => {
    try {
        const resp: any = await API.get('auth/logout').json();
        return resp;
    } catch (error) {
        ErrorHandler(error);
        throw error;
    }
};

const EditProfile: MutationFunction<IUser.Profile.Response, IUser.Profile.Item> = async (data: IUser.Profile.Item) => {
    try {
        const resp: IUser.Profile.Response = await API.put('auth/profile', {json: data}).json();
        return resp;
    } catch (error) {
        ErrorHandler(error);
        throw error;
    }
};

const Profile: QueryFunction<IUser.Profile.Response> = async () => {
    try {
        const resp: IUser.Profile.Response = await API.get('auth/profile').json();
        return resp;
    } catch (error) {
        ErrorHandler(error);
        throw error;
    }
};

const DeleteProfile: MutationFunction<any, any> = async () => {
    try {
        const resp: any = await API.delete('deletemyaccount').json();
        return resp.data;
    } catch (error) {
        ErrorHandler(error);
        throw error;
    }
};

const Reminder: QueryFunction<IUser.Reminder.Response> = async () => {
    try {
        const resp: IUser.Reminder.Response = await API.get('reminder').json();
        return resp;
    } catch (error) {
        ErrorHandler(error);
        throw error;
    }
};

const EditReminder: MutationFunction<IUser.Reminder.Success_Edit, IUser.Reminder.Add_Or_Edit> = async (
    data: IUser.Reminder.Add_Or_Edit,
) => {
    try {
        const resp: IUser.Reminder.Success_Edit = data.id
            ? await API.put('reminder/' + data.id, {json: data}).json()
            : await API.post('reminder', {json: data}).json();
        return resp;
    } catch (error) {
        ErrorHandler(error);
        throw error;
    }
};

const AddSubscription: MutationFunction<IUser.Subscription.Success_Add, IUser.Subscription.Add> = async (
    data: IUser.Subscription.Add,
) => {
    try {
        const resp: IUser.Subscription.Success_Add = await API.post('add_subscription', {json: data}).json();
        return resp;
    } catch (error) {
        ErrorHandler(error);
        throw error;
    }
};

const EditLanguage: MutationFunction<IMain.Languages.Success_Edit, IMain.Languages.Edit> = async (
    data: IMain.Languages.Edit,
) => {
    try {
        const resp: IMain.Languages.Success_Edit = await API.put('user/lang', {json: data}).json();
        return resp;
    } catch (error) {
        ErrorHandler(error);
        throw error;
    }
};

const Notification: QueryFunction<IUser.Notifications.Response> = async () => {
    try {
        const resp: IUser.Notifications.Response = await API.get('notification_list').json();
        return resp;
    } catch (error) {
        ErrorHandler(error);
        throw error;
    }
};

export const UserService = {
    Login,
    Logout,

    Profile,
    EditProfile,
    DeleteProfile,

    Reminder,
    EditReminder,

    AddSubscription,

    EditLanguage,

    Notification,
};
