import Filter from 'components/PractitionersList/Filter';
import PractitionersList from 'components/PractitionersList/PractitionersList';
import {Template} from 'components/template/Template';
import {GenerateTitleEn} from 'helpers/GenerateTitle';
import {CounselingQuery} from 'lib/react-query/query-hooks';
import {ICounseling} from 'model/api';
import moment from 'moment';
import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const PractitionersListPage = (): JSX.Element => {
    const location = useLocation();
    const date = new Date();
    const [APIFilters, setAPIFilters] = useState<ICounseling.Filters>({
        name: '',
        topic: [],
        title: location.state ? GenerateTitleEn(location.state.title) : '',
        secondary_title: '',
        schedule_gte: moment(date).format('YYYY-MM-DD') + 'T00:00:00Z',
        schedule_lte: moment(date).format('YYYY-MM-DD') + 'T23:59:00Z',
        page: 1,
        limit: 10,
    });
    const {data, isLoading} = CounselingQuery.Psychologist(undefined, {
        ...APIFilters,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Template>
            <div className="flex flex-col justify-between items-center pt-28 pb-7 px-8">
                <div className="min-h-full flex flex-col md:flex-row justify-center">
                    <Filter setFilter={setAPIFilters} filters={APIFilters} />
                    <PractitionersList practitioners={data?.data} isLoading={isLoading} />
                </div>
            </div>
        </Template>
    );
};
