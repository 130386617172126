interface Props {
    file: string;
}

export const DokumenSertifikat = ({file}: Props): JSX.Element => {
    return (
        <div>
            <p className="text-[16px] md:text-lg font-semibold">Dokumen & Sertifikat</p>
            <a href={file} target="_blank" rel="noreferrer" className="text-sm font-bold mt-2 text-primary">Lihat lebih banyak</a>
        </div>
    );
};
