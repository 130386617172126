import {Template} from 'components/template/Template';
import checklistcourse from 'assets/images/checklistcourse.svg';

export const PembayaranSuccesPage = (): JSX.Element => {
    return (
        <Template>
            <div className="flex justify-center h-screen items-center ">
                <div className="mt-24">
                    <img src={checklistcourse} alt="" />
                </div>
            </div>
        </Template>
    );
};
