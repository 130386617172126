import Illustration from "assets/images/home/teduh-app-1.svg";
import { Store } from "components/common/Store";

export const TeduhAppInstall = () => {
  return (
    <div className="flex justify-center items-center md:px-32 md:py-20 px-6 gap-3 md:gap-0">
      <div className="w-1/2">
        <img src={Illustration} alt="Illustration" className="mx-auto" />
      </div>
      <div className="flex flex-col md:items-center gap-1 w-1/2">
        <h3 className="md:font-medium text-sm md:text-md">Nikmati Semua fitur di Aplikasi</h3>
        <h1 className="text-3xl md:text-6xl font-semibold">Teduh</h1>
        <Store />
      </div>
    </div>
  );
};