import {PsychologTitle} from 'components/PsychologTitle';
import {useAppSelector} from 'hooks/useAppSelector';
import {ICounseling} from 'model/api';
import {Star} from 'react-feather';
import {useNavigate} from 'react-router-dom';

interface Props {
    practitioner: ICounseling.Psychologist.Item;
    setOpenModal: (open: boolean) => void;
    setSelectedPractitioner: (practitioner: ICounseling.Psychologist.Item) => void;
}

type Day = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

const days: Day[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
const days_id = ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu'];

const ProfileCard = ({practitioner, setOpenModal, setSelectedPractitioner}: Props): JSX.Element => {
    const {user} = useAppSelector(s => s.main);
    const navigate = useNavigate();
    const handleSelectPractitioner = (open: boolean, practitionerData: ICounseling.Psychologist.Item) => {
        if (!!!user) {
            navigate('/signin');
        } else {
            setOpenModal(open);
            setSelectedPractitioner(practitionerData);
        }
    };

    const getAvailable = () => {
        const availableDays: any = [];
        const indexs: number[] = [];
        days?.map((item, index) => {
            if (practitioner?.availableDays[item]?.length > 0) {
                availableDays.push(days_id[index]);
                indexs.push(index);
            }
        });
        if (indexs.length > 0) {
            if (indexs.length == 1) {
                return days_id[indexs[0] || 0] || ''.toString();
            } else if (indexs[0] + (indexs.length - 1) == indexs[indexs.length - 1]) {
                return (
                    days_id[indexs[0]] + ''.toString() + ' - ' + days_id[indexs[indexs.length - 1] || 0] ||
                    ''.toString()
                );
            } else {
                return availableDays.join(', ');
            }
        }
        return 'Tidak Ada Jadwal';
    };

    const availableDays = getAvailable();
    return (
        <>
            <button
                className="h-fit flex flex-row shadow-box rounded-md px-4 py-8 gap-8 min-w-[327px] w-[90%] sm:w-[24rem] lg:w-[36rem] relative hover:bg-secondary border"
                onClick={() => handleSelectPractitioner(true, practitioner)}
            >
                <div className="flex flex-col justify-center items-center gap-2 w-fit">
                    <div className="w-max">
                        <img
                            className="h-[72px] w-[72px] sm:h-28 sm:w-28 rounded-full mb-8 sm:mb-0"
                            src={
                                practitioner.profile_picture
                                    ? practitioner.profile_picture
                                    : require('../../assets/images/Profile_avatar_placeholder_large.png')
                            }
                            alt="profile image"
                        />
                    </div>
                </div>
                <div className="w-full h-full mb-4">
                    <div className="flex flex-col justify-center gap-2 w-full h-full pb-2">
                        <div className="gap-2 hidden sm:flex">
                            <PsychologTitle title={practitioner.title}/>          
                            {practitioner.secondary_title && <PsychologTitle title={practitioner.secondary_title} />}
                        </div>
                        <h1 className="font-semibold text-left line-clamp-1">{practitioner.name_with_title}</h1>
                        {practitioner.keyword.length > 0 && (
                            <div className="flex flex-wrap gap-2">
                                {practitioner.keyword.map(keyword => (
                                    <p 
                                        key={keyword}
                                        className="text-xs text-gray-500 border-gray-500 border rounded-full px-3 py-1 w-fit"
                                    >
                                        {keyword} 
                                    </p>
                                ))}
                            </div>
                        )}
                        {availableDays.length > 0 && (
                            <p className="text-sm text-gray-500 items-end text-left">Jadwal: {availableDays}</p>
                        )}
                    </div>
                    <div className="absolute bottom-4 left-4 pt-4 sm:hidden">
                        <PsychologTitle title={practitioner.title} />
                    </div>
                    <a
                        className="absolute bottom-4 text-semibold right-4 text-sm text-blue-500 cursor-pointer hidden sm:inline"
                        onClick={() => handleSelectPractitioner(true, practitioner)}
                    >
                        Lihat Profil
                    </a>
                    {!!practitioner.averageRating && (
                        <div className="flex items-center gap-1 -ml-1.5 absolute bottom-4 right-4 sm:left-14 pt-4">
                            <Star color="#FFD66D" size={16} fill="#FFD66D" />
                            <p className="text-sm font-regular">{practitioner.averageRating}</p>
                        </div>
                    )}
                </div>
            </button>
        </>
    );
};

export default ProfileCard;
