interface Props {
    number: any;
    label: string;
    description: string;
}

export const ResourceCard = ({number, label, description}: Props) => {
    return (
        <div className='w-[320px]'>
          <p className='font-semibold text-[18px] sm:text-[40px]'>
            {number}
          </p>
          <p className='font-semibold text-[#5542FF] text-[16px] sm:text-2xl'>
            {label}
          </p>
          <p className='text-navbar-accent text-[14px] sm:text-base'>
            {description}
          </p>
        </div>
    );
};
