import {ChevronLeft} from 'react-feather';
import {ProgressBar} from './ProgressBar';
import {Upload} from 'react-feather';
import { ICounseling } from 'model/api';
import { ChangeEvent, useState } from 'react';

interface Props {
    data: ICounseling.Screening.Add;
    setData: (v: ICounseling.Screening.Add) => void;
    setStep: (v: string) => void;
    handleSubmit: () => void;
}

const SecondScreening = ({data, setData, setStep, handleSubmit}: Props): JSX.Element => {
    const [fileName, setFileName] = useState('');

    const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setData({...data, files: file.name});
            setFileName(file.name);
        }
    };

    return (
        <div className="flex flex-row justify-start items-start min-w-full p-20 gap-8">
            <div className="flex justify-center items-center h-12 w-12 rounded cursor-pointer border-gray300 border" onClick={() => setStep('first')}>
                <ChevronLeft size={24} />
            </div>
            <div className="flex flex-col gap-3 w-[30rem]">
                <p className="text-2xl font-semibold self-center pb-3">Deskripsi Masalah</p>
                <ProgressBar width="w-50%" />
                <p>Kamu boleh menulis hanya yang kamu ingin tulis</p>
                <p className="pt-7">Deskripsi masalah yang dihadapi (wajib)</p>
                <p className="text-gray-500">
                    Contoh: Saya akhir-akhir ini cemas karena menghadapi tugas yang rumit, ditambah ada masalah lain
                    dengan orang terdekat
                </p>
                <div className="flex flex-row place-items-center border-2 h-32 rounded-md gap-3 px-3 py-1.5">
                    <textarea
                        className="h-full w-full outline-none text-dark text-sm resize-none"
                        id="search"
                        placeholder="Kamu boleh menulis dengan detail"
                        rows={4}
                        cols={40}
                        onChange={(e) => setData({...data, problem_description: e.target.value})}
                        value={data.problem_description}
                    />
                </div>
                <p className="text-danger text-sm">Maksimal 10.000 karakter dengan spasi</p>

                <p className="pt-7">Hasil Konseling yang Diharapkan (opsional)</p>
                <p className="text-gray-500">
                    Contoh: Saya harap saya dapat menemukan cara untuk menghadapi kecemasan dan masalah tersebut  sehingga tugas selesai dengan baik
                </p>
                <div className="flex flex-row place-items-center border-2 h-32 rounded-md gap-3 px-3 py-1.5">
                    <textarea
                        className="h-full w-full outline-none text-dark text-sm resize-none"
                        id="search"
                        placeholder="Kamu boleh menulis dengan detail"
                        rows={4}
                        cols={40}
                        onChange={(e) => setData({...data, expectation: e.target.value})}
                        value={data.expectation}
                    />
                </div>
                <p className="text-danger text-sm">Maksimal 10.000 karakter dengan spasi</p>

                <p className="pt-7">Catatan untuk Psikolog/Konselor (opsional)</p>
                <p className="text-gray-500">
                    Contoh: Saya ingin sesi konseling ini saya ingin mencari solusi sendiri dibantu psikolog, bukan dicarikan solusi
                </p>
                <div className="flex flex-row place-items-center border-2 h-32 rounded-md gap-3 px-3 py-1.5">
                    <textarea
                        className="h-full w-full outline-none text-dark text-sm resize-none"
                        id="search"
                        placeholder="Kamu boleh menulis dengan detail"
                        rows={4}
                        cols={40}
                        onChange={(e) => setData({...data, notes_for_psychologist: e.target.value})}
                        value={data.notes_for_psychologist}
                    />
                </div>
                <p className="text-danger text-sm">Maksimal 10.000 karakter dengan spasi</p>

                <p className="pt-7">Unggah dokumen tambahan (opsional)</p>
                <p className="text-gray-500">
                    Contoh: Surat rujukan, hasil tes, atau lainnya yang dapat membantu proses konseling (*.pdf/*.jpg)
                </p>
                <label
                    className="flex flex-row justify-between items-center border-2 h-11 rounded-md gap-3 px-3 cursor-pointer"
                    htmlFor="upload_file"
                >
                    <input
                        className="h-full w-full outline-none text-dark text-sm resize-none"
                        id="upload_file"
                        type="file"
                        hidden
                        onChange={(e) => handleFile(e)}
                    />
                    <p className="text-gray-500 text-sm">{fileName ? fileName : 'Unggah di sini'}</p>
                    <Upload />
                </label>
                <p className="text-danger text-sm">Maksimal 10MB</p>
                <button className="bg-primary text-white h-11 align-center justify-center rounded-lg mt-10" onClick={handleSubmit}>
                    Kirim
                </button>
            </div>
        </div>
    );
};

export default SecondScreening;
