export const colors = {
    primary: '#377BFF',
    primaryLight: '#86B8FF',
    primaryLightest: '#D7EAFF',
    background: '#FEFEFE',
    secbackground: '#EFF5FF',
    primary_logo: '#93CCEA',
    // other
    white: '#FFFFFF',
    text: '#272F40',
    grey: '#b1b1b1',
    greyText: '#4c6356',
    greyDark: '#333333',
    realGrey: '#848484',
    greyLight: '#EAEAEA',
    greyLighter: '#F4F4F4',
    black: '#000000',
    nav: '#697BA1',
    kurang: '#FC6D1D',
    // status
    warning: '#ECC542',
    warningLight: '#FDF8D0',
    success: '#39DA00',
    successLight: '#C6FB96',
    info: '#0462A5',
    infoLight: '#95DEF6',
    danger: '#CB3F52',
    dangerLight: '#F8DED6',
};
