import { Line } from "./Line";
import { ServiceCard } from "./ServiceCard";
import iconOne from '../../assets/images/b2b/1.svg';
import iconTwo from '../../assets/images/b2b/2.svg';
import iconThree from '../../assets/images/b2b/3.svg';
import iconFour from '../../assets/images/b2b/4.svg';
import { translate } from "i18n";
import { Markup } from "interweave";
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const serviceData = [
  {
    image: iconOne,
    title: 'b2b_help_1_title',
    description: 'b2b_help_1_desc',
    style: 'xl:absolute xl:-bottom-[45px] xl:left-[70px] 2xl:-bottom-[50px] 2xl:left-48 w-80'
  },
  {
    image: iconTwo,
    title: 'b2b_help_2_title',
    description: 'b2b_help_2_desc',
    style: 'xl:absolute xl:top-[80px] xl:left-[400px] 2xl:top-[80px] 2xl:left-[550px] w-80 xl:flex xl:flex-col-reverse'
  },
  {
    image: iconThree,
    title: 'b2b_help_3_title',
    description: 'b2b_help_3_desc',
    style: 'xl:absolute xl:-bottom-[52px] xl:left-[690px] 2xl:-bottom-[52px] 2xl:left-[840px] w-80'
  },
  {
    image: iconFour,
    title: 'b2b_help_4_title',
    description: 'b2b_help_4_desc',
    style: 'xl:absolute xl:-top-[56px] xl:left-[950px] 2xl:-top-[56px] 2xl:left-[1100px] w-80 xl:flex xl:flex-col-reverse'
  },
];

export const HowWeCouldHelp = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

    return (
      <div className='flex flex-col py-10 xl:py-24'>
        <motion.div
          ref={ref}
          initial={{ opacity: 0, x: 100 }}
          animate={inView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <h1 className='font-semibold text-2xl sm:text-4xl md:text-5xl/normal pb-4 text-black px-[7%]'><Markup content={translate('b2b_help_title')}/></h1>
        </motion.div>
        <motion.div
          ref={ref}
          initial={{ opacity: 0, x: 100 }}
          animate={inView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1.3 }}
        >
          <hr className="w-[60px] h-[10px] mx-[7%] mt-4 mb-12 bg-primary border-0 rounded"/>
        </motion.div>
        <motion.div 
          ref={ref}
          initial={{ opacity: 0, x: -100 }}
          animate={inView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1.2 }}
          className='xl:relative xl:h-[420px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols items-center mx-[7%] xl:mx-0'>
           <Line />
           {serviceData.map((data) => <ServiceCard image={data.image} title={data.title} description={data.description} className={data.style}/>)}
        </motion.div>
      </div>
    );
};