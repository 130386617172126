import {MutationFunction} from '@tanstack/react-query';
import {ErrorHandler} from 'helpers/ErrorHandler';
import {API} from 'lib/ky';
import {IMain} from 'model/api';

const AddResult: MutationFunction<any, any> = async (data: any) => {
    try {
        const resp: IMain.Feedback.Success_Add = await API.post('assesment', {json: data}).json();
        return resp;
    } catch (error) {
        ErrorHandler(error);
        throw error;
    }
};

export const PsychologicalTestsService = {
    AddResult,
};
