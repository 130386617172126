import {initReactI18next, useTranslation} from 'react-i18next';

import i18n from 'i18next';

import en from './locales/en.json';
import id from './locales/id.json';

export const resources = {en: {translation: en}, ind: {translation: id}} as const;

i18n.use(initReactI18next).init({
    resources,
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    lng: 'ind',
    interpolation: {
        defaultVariables: {value: ''},
    },
});

export const translate = (key: keyof typeof en) => {
    const {t} = useTranslation();
    return t(key);
};

export default i18n;
