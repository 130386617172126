import {useEffect, useRef, useState} from 'react';
import Cloud from 'assets/images/cloudbingung.png';
import {Link} from 'react-router-dom';
import {SessionCard} from 'components/SessionCard';
import {CounselingQuery} from 'lib/react-query/query-hooks';
import {useAppSelector} from 'hooks/useAppSelector';
import {FiSunrise} from 'react-icons/fi';

interface Props {
    label: string;
}

export const SesiMendatangLink = ({label}: Props) => {
    const [open, setOpen] = useState<boolean>(false);
    const {user} = useAppSelector(s => s.main);

    const ref = useRef<any>(null);

    // const {data: trx_data, isLoading} = CounselingQuery.Payment(
    //     {
    //         enabled: !!user ? true : false,
    //     },
    //     {pending: 'true'},
    // );

    const {data: sessions_data, isLoading} = CounselingQuery.Session(
        {
            enabled: !!user ? true : false,
        },
        {
            sort: 'schedule',
            status: ['paid', 'paid-admin', 'ongoing'],
        },
    );

    const handleOpen = () => {
        setOpen(!open);
    };

    console.log(isLoading);
    console.log(sessions_data);

    useEffect(() => {
        const handleClickOutsite = (e: any) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutsite);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsite);
        };
    }, [ref]);
    return (
        <div className="relative flex md:justify-center items-center">
            <button onClick={handleOpen} className="cursor-pointer select-none">
                <div className="flex justify-center items-center">
                    <div className="mr-2">
                        <FiSunrise size={24} />
                    </div>
                    {label}
                </div>
            </button>
            {open && (
                <div>
                    {!sessions_data?.data || sessions_data.data.length === 0 ? (
                        <div className="w-72 bg-white border border-gray-300 p-5 -right-[6em] rounded-lg shadow-md absolute top-7 flex flex-col justify-center items-center ">
                            <img src={Cloud} alt="" className="mb-6" />
                            <p className="text-base font-semibold text-black mb-7">Tidak ada sesi mendatang</p>
                            <Link to="/practitioners">
                                <button className="px-4 py-1.5 rounded-md font-medium text-white text-sm bg-gradient-custom from-start-carousel-one to-end-carousel-one">
                                    Jadwalkan Sesi Konseling
                                </button>
                            </Link>
                        </div>
                    ) : (
                        [...(sessions_data.data || [])].map((session_item, index) => (
                            <div className="bg-white md:-right-[12em] -right-[3em] rounded-lg  absolute md:top-12 top-10 flex flex-col justify-center items-center z-50 md:w-[390px]">
                                <SessionCard key={index} data={session_item} />
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    );
};
