import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

// Images
import teduhLogo from "assets/images/teduh-logo.png";
import installLogo from "assets/images/install-logo.png";

type LinkType = {
  label: string;
  path: string;
}

export const SigninNavBar = () => {    
  const [links, setLinks] = useState<LinkType[]>([]);

  useEffect(() => {
    setLinks([
      {
        label: 'Blog',
        path: '/'
      },
      {
        label: 'Teduh for Business',
        path: '/'
      }
    ]);
  }, []);

  return (
    <nav className="w-screen flex justify-between px-10 h-16 items-center fixed bg-white z-10 border-b border-searchbar">
      <div className="left flex gap-10">
        <Link to='/'>
          <div className="logo text-main font-medium text-xl flex items-center gap-3 select-none">
            <img src={teduhLogo} alt="Teduh Logo" className="w-10" />
            <h1>Teduh</h1>
          </div>
        </Link>
        <div className="links flex justify-around gap-5 items-center text-navbar-accent text-sm">
          {links.map((link) => (
            <Link to={link.path}>
              {link.label}
            </Link>
          ))}
        </div>
      </div>
      <img src={installLogo} className="h-9" />
    </nav>
  );
};