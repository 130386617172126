import {FiChevronLeft} from 'react-icons/fi';

interface Props {
    setOpen: (v: boolean) => void;
}

export const BackButton = ({setOpen}: Props) => {
    return (
        <button
            className="border border-searchbar p-1 flex justify-center items-center absolute rounded-md left-8 cursor-pointer select-none"
            onClick={() => setOpen(false)}
        >
            <FiChevronLeft className="text-xl" strokeWidth={2} />
        </button>
    );
};