import {ICounseling} from 'model/api';
import {Button} from './common/Button';
import moment from 'moment';
import {PsychologTitle} from './PsychologTitle';
import {CounsellingType} from './CounsellingType';
import {DateTime} from './DateTime';
import {FiUser} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';
import Discount from 'assets/images/illustrations/discount.svg';
// import {useAppSelector} from 'hooks/useAppSelector';

interface Props {
    data?: ICounseling.Session.Item;
    trx?: ICounseling.Payment.Item;
    additional_type?: 'b2b_discount' | 'b2b_free' | 'new_user';
}

export const SessionCard = ({data, trx, additional_type}: Props) => {
    const navigate = useNavigate();
    // const {user} = useAppSelector(s => s.main);

    // const company_max_telekonsul_free_quota =
    //     user?.employment_details?.company?.max_teleconsult_quota_used_by_a_user || 0;
    // const used_free_quota = user?.employment_details?.num_teleconsult_quota_used || 0;
    // const used_discount_quota = user?.employment_details?.num_teleconsult_discount_quota_used || 0;

    interface ItemI {
        label: string;
        status: 'cancelled' | 'payment' | 'start' | 'screening' | 'done' | 'b2b_discount' | 'b2b_free' | 'new_user';
        onClick: () => void;
    }
    const setCard = () => {
        if (!!data) {
            if (!!trx) {
                return {
                    status: 'payment',
                    label: 'Lakukan Pembayaran',
                    onClick: () => {
                        window.open(trx.url);
                    },
                } as ItemI;
            }

            if (data.status == 'cancelled') {
                return {
                    status: 'cancelled',
                    label: 'Tentukan Sesi Baru',
                    onClick: () => {},
                } as ItemI;
            }

            if (data.status == 'paid') {
                if (
                    data.screening.length > 0 ||
                    (moment.utc(new Date()) > moment(data.schedule).add(-10, 'minute') &&
                        moment.utc(new Date()) < moment(data.schedule).add(1, 'hour'))
                ) {
                    return {
                        status: 'start',
                        label: 'Mulai Sesi',
                        onClick: () => {
                            window.open(data.psychologist.gmeet_link);
                        },
                    } as ItemI;
                } else {
                    return {
                        status: 'screening',
                        label: 'Isi Kuesioner Screening',
                        onClick: () => {
                            navigate(`/screening/${data.id}`);
                        },
                    } as ItemI;
                }
            }

            if (moment.utc(new Date()) > moment(data.schedule).add(1, 'hour') || data.status == 'completed') {
                return {
                    status: 'done',
                    label: 'Lihat Catatan Psikolog',
                    onClick: () => {},
                } as ItemI;
            }
        } else {
            if (additional_type == 'b2b_discount') {
                return {
                    status: 'b2b_discount',
                    label: 'Tentukan Sesi',
                    onClick: () => {
                        navigate(`/company/discount`);
                    },
                } as ItemI;
            }

            if (additional_type == 'b2b_free') {
                return {
                    status: 'b2b_free',
                    label: 'Tentukan Sesi',
                    onClick: () => {
                        navigate(`/company/free`);
                    },
                } as ItemI;
            }

            // if (additional_type == 'new_user') {
            //     return {
            //         status: 'new_user',
            //         label: 'Tentukan Sesi',
            //         onClick: () => {},
            //     } as ItemI;
            // }
        }

        return undefined;
    };

    console.log(data);

    const Item: ItemI | undefined = setCard();
    return Item ? (
        additional_type ? (
            <div className="bg-secondary p-4 rounded-xl min-w-max w-full sm:w-2/3 max-w-sm h-56 shadow-card flex flex-col">
                <div className="flex flex-col basis-full align-middle">
                    <text>Kamu mendapatkan penawaran</text>
                    <div className="flex mt-2">
                        <img src={Discount} alt="WhatsApp" />

                        <text className="font-semibold text-5xl">1</text>
                        <div className=" flex flex-col ml-2">
                            <text className="font-semibold">Sesi Konseling</text>
                            <text className="font-semibold">
                                {additional_type == 'b2b_discount' ? 'Diskon 15%' : 'Gratis'}
                            </text>
                        </div>
                    </div>
                </div>
                <div className="mt-1 basis-1">
                    <div className="flex">
                        <CounsellingType type="voice" />
                        {additional_type == 'b2b_free' ? (
                            <div className=" px-3 py-1.5 bg-sky-blue rounded-full ml-2">Penawaran Terbatas</div>
                        ) : null}
                    </div>
                    <Button
                        className="bg-primary text-white py-3 px-4 rounded-md font-normal w-full mt-2"
                        label={Item.label}
                        onClick={Item.onClick}
                    />
                </div>
            </div>
        ) : (
            <div className="bg-secondary p-4 rounded-xl w-full sm:w-2/3 max-w-sm h-56 shadow-card flex flex-col">
                <div className="flex flex-row basis-full align-middle">
                    {!!data?.psychologist?.profile_picture ? (
                        <img
                            className=" aspect-square h-20 rounded-full"
                            src={data?.psychologist?.profile_picture || ''}
                        />
                    ) : (
                        <div className=" rounded-full border-2 w-20 h-20 flex justify-center">
                            <FiUser className="aspect-square h-12 w-12 self-center " color="gray" />
                        </div>
                    )}
                    <div className="ml-4">
                        <text className="text-xl font-semibold mb-3 line-clamp-1">
                            {data?.psychologist?.name_with_title || ''}
                        </text>
                        <div className="flex flex-row">
                            <PsychologTitle title={data?.psychologist?.title || 'Career Coach'} />
                            <CounsellingType type="voice" className="ml-4" />
                        </div>
                    </div>
                </div>
                <div className="mt-1 basis-1">
                    {Item.status == 'payment' ? (
                        <div>
                            <h1 className="text-danger">
                                Deadline pembayaran: {moment(trx?.expired_date).format('DD MMMM, hh:mm')}
                            </h1>
                        </div>
                    ) : null}
                    {Item.status == 'screening' || Item.status == 'start' ? (
                        <DateTime value={moment.utc(data?.schedule)} />
                    ) : null}
                    {Item.status == 'done' ? (
                        <div className="flex flex-row">
                            <h1 className="text-success">Sesi selesai.</h1>
                            <h1 className="text-primary font-semibold ml-1" onClick={() => console.log('aa')}>
                                Lihat Detail
                            </h1>
                        </div>
                    ) : null}
                    {Item.status == 'cancelled' ? (
                        <div className="flex flex-row">
                            <h1 className="text-danger">Sesi dibatalkan.</h1>
                            <h1 className="text-primary font-semibold ml-1" onClick={() => console.log('aa')}>
                                Lihat Detail
                            </h1>
                        </div>
                    ) : null}
                    <Button
                        className="bg-primary text-white py-3 px-4 rounded-md font-normal w-full mt-2 "
                        label={Item.label}
                        onClick={Item.onClick}
                    />
                </div>
            </div>
        )
    ) : null;
};
