import {ChevronLeft} from 'react-feather';
import { ProgressBar } from './ProgressBar';
import { ICounseling } from 'model/api';

interface Props {
    data: ICounseling.Screening.Add;
    setData: (v: ICounseling.Screening.Add) => void;
    setStep: (v: string) => void;
}

const FirstScreening = ({data, setData, setStep}: Props): JSX.Element => {
    return (
        <div className="flex flex-row justify-start items-start w-[92%] mx-auto py-10 md:p-20 gap-4 md:gap-8">
            <div className="flex flex-col gap-3">
                <div className="flex justify-between items-center">
                    <div className="flex justify-center items-center h-12 w-12 rounded cursor-pointer border-gray300 border w-[15%]">
                        <ChevronLeft size={24} />
                    </div>
                    <p className="text-2xl font-semibold pb-3 w-[85%] text-center">Biodata</p>
                </div>
                <ProgressBar width="w-[50%]" />
                <p className="text-[14px] md:text-base">Kamu boleh menulis hanya yang kamu ingin tulis</p>
                <div className="my-5 flex flex-row items-center">
                    <input type="checkbox" className="h-5" />
                    <span className="ml-2 text-[14px] md:text-base text-dark">Sembunyikan identitas saya saat konseling</span>
                </div>
                <p className="text-[14px] md:text-base">Nama lengkap (opsional)</p>
                <div className="flex flex-row place-items-center border-2 h-11 md:w-[20rem] rounded-md gap-3 px-3">
                    <input
                        className="h-full w-full outline-none text-dark text-sm"
                        type="text"
                        id="namalengkap"
                        placeholder="Tulis di sini"
                        onChange={(e) => setData({...data, fullname: e.target.value})}
                        value={data.fullname}
                    />
                </div>
                <p className="text-[14px] md:text-base">Nama panggilan (opsional)</p>
                <div className="flex flex-row place-items-center border-2 h-11 w-[20rem] rounded-md gap-3 px-3">
                    <input
                        className="h-full w-full outline-none text-dark text-sm"
                        type="text"
                        id="search"
                        placeholder="Tulis di sini"
                        onChange={(e) => setData({...data, nickname: e.target.value})}
                        value={data.nickname}
                    />
                </div>
                <p className="text-[14px] md:text-base">Tempat lahir (opsional)</p>
                <div className="flex flex-row place-items-center border-2 h-11 w-[20rem] rounded-md gap-3 px-3">
                    <input
                        className="h-full w-full outline-none text-dark text-sm"
                        type="text"
                        id="search"
                        placeholder="Tulis di sini"
                        onChange={(e) => setData({...data, birthplace: e.target.value})}
                        value={data.birthplace}
                    />
                </div>
                <p className="text-[14px] md:text-base">Tanggal lahir (opsional)</p>
                <div className="flex flex-row place-items-center border-2 h-11 w-[20rem] rounded-md gap-3 px-3 relative">
                    <input
                        className="h-full w-full outline-none text-dark text-sm"
                        type="date"
                        id="search"
                        placeholder="Tulis di sini"
                        onChange={(e) => setData({...data, birthdate: e.target.value})}
                        value={data.birthdate}
                    />
                </div>
                <p className="text-[14px] md:text-base">Gender (opsional)</p>
                <div className="flex flex-row place-items-center border-2 h-11 w-[20rem] rounded-md gap-3 px-3">
                    <select
                        className="h-full w-full outline-none text-dark text-sm"
                        id="Gender"
                        placeholder="Pilih salah satu"
                        onChange={(e) => setData({...data, gender: e.target.value})}
                        value={data.gender}
                    >
                        <option value='' selected disabled>Pilih Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="other">other</option>
                    </select>
                </div>
                <p className="text-[14px] md:text-base">Agama (opsional)</p>
                <div className="flex flex-row place-items-center border-2 h-11 w-[20rem] rounded-md gap-3 px-3">
                    <select
                        className="h-full w-full outline-none text-dark text-sm"
                        id="Agama"
                        placeholder="Pilih salah satu"
                        onChange={(e) => setData({...data, religion: e.target.value})}
                        value={data.religion}
                    >
                        <option value='' selected disabled>Pilih Agama</option>
                        <option value="Islam">Islam</option>
                        <option value="Christianity">Christianity</option>
                        <option value="Hinduism">Hinduism</option>
                        <option value="Buddhism">Buddhism</option>
                        <option value="Confucianism">Confucianism</option>
                        <option value="other">other</option>
                    </select>
                </div>
                <p className="text-[14px] md:text-base">Email (opsional)</p>
                <div className="flex flex-row place-items-center border-2 h-11 w-[20rem] rounded-md gap-3 px-3">
                    <input
                        className="h-full w-full outline-none text-dark text-sm"
                        type="text"
                        id="search"
                        placeholder="Tulis di sini"
                        onChange={(e) => setData({...data, email: e.target.value})}
                        value={data.email}
                    />
                </div>
                <p className="text-[14px] md:text-base">Alamat rumah (opsional)</p>
                <div className="flex flex-row place-items-center border-2 h-11 w-[20rem] rounded-md gap-3 px-3">
                    <input
                        className="h-full w-full outline-none text-dark text-sm"
                        type="text"
                        id="search"
                        placeholder="Tulis di sini"
                        onChange={(e) => setData({...data, address: e.target.value})}
                        value={data.address}
                    />
                </div>
                <button className="bg-primary text-white h-11 w-[20rem] align-center justify-center rounded-lg mt-10" onClick={() => setStep('second')}>
                    Selanjutnya
                </button>
            </div>
        </div>
    );
};

export default FirstScreening;
