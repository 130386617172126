interface Props {
    children: any;
    openModal: Boolean;
    close: () => void;
}

export const ResponsiveModal = ({children, openModal, close}: Props) => {

    return (
        <>
            <div
                className="hidden md:flex mr-10 flex flex-col flex-1"
            >
                  {children}
            </div>
            {openModal && 
            <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none md:hidden"
                onClick={close}
            >
                <div
                    className={`bg-white rounded-xl w-[80%] max-w-[48em] max-h-[35em] flex flex-col`}
                    onClick={e => e.stopPropagation()}
                >
                    {children}
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black md:hidden"></div>
            </>
            }
        </>
    );
};
