import { translate } from "i18n";

interface Props {
    image: any;
    description: any;
    link: string;
    css: string;
}

export const ChartCard = ({image, description, link, css}: Props) => {
    const bgClassName = `w-[160px] sm:w-[300px] rounded-xl border-[1px] flex flex-col items-center bg-white justify-center text-center px-[12px] py-[24px] gap-[24px] ${css}`;
    return (
      <div className={bgClassName}>
       <img src={image} alt="person" width='120px'/>
        <p className='text-navbar-accent px-[1px] sm:px-[24px] text-[14px] sm:text-base'>{description}</p>
        <button onClick={() => window.open(link)}>
            <p className='text-primary font-bold text-[12px] sm:text-base'>{translate('read_more_button')}</p>
        </button>
      </div>
    );
};
