import {FiInstagram, FiLinkedin, FiMessageCircle} from 'react-icons/fi';

interface Props {
    with_aplicaiton_link: boolean;
}

export const Social = ({with_aplicaiton_link = true}: Props): JSX.Element => {
    return (
        <div>
            <div className=" flex items-center justify-center ">
                <button className=" p-2 rounded-md border" onClick={() => window.open('https://instagram.com/teduh')}>
                    <FiInstagram size={24} className=" stroke-slate-600 " />
                </button>
                <button
                    className=" p-2 rounded-md border mx-4"
                    onClick={() => window.open('https://linkedin.com/in/teduh')}
                >
                    <FiLinkedin size={24} className=" stroke-slate-600 " />
                </button>
                <button className=" p-2 rounded-md border" onClick={() => window.open('wa.me/6285219498199')}>
                    <FiMessageCircle size={24} className=" stroke-slate-600 " />
                </button>
            </div>
            {with_aplicaiton_link ? (
                <div className="flex flex-row justify-center mt-8">
                    <button
                        onClick={() =>
                            window.open('https://apps.apple.com/id/app/teduh-konseling-meditasi/id1637005394')
                        }
                    >
                        <img className="h-14" src={require('../../assets/images/appstore.png')} alt="logo" />
                    </button>
                    <button onClick={() => window.open('https://play.google.com/store/apps/details?id=id.teduhio.app')}>
                        <img className="h-20" src={require('../../assets/images/googleplay.png')} alt="logo" />
                    </button>
                </div>
            ) : null}
        </div>
    );
};
