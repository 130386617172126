interface Props {
    width: string;
}

export const ProgressBar = ({width}: Props) => {
    return (
        <div className="w-full bg-sky-blue rounded-full h-2.5">
            <div className={`bg-primary h-2.5 rounded-full ${width} duration-150`}></div>
        </div>
    );
};
