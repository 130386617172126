import {PsychologTitle} from 'components/PsychologTitle';
import moment from 'moment';
import 'moment/locale/id';
import {FiCalendar, FiClock, FiPhone, FiVideo} from 'react-icons/fi';

interface SesiProps {
    selectedTime: string;
    selectedDate: string;
    selectedMedia?: string;
    practitioner?: any;
}

export const Sesi: React.FC<SesiProps> = ({selectedTime, selectedDate, selectedMedia, practitioner}) => {
    const imageSource = practitioner.profile_picture
        ? practitioner.profile_picture
        : 'https://pdtxar.com/wp-content/uploads/2019/04/person-placeholder.jpg';

    moment.locale('id');
    const formattedDate = moment(selectedDate).format('dddd, D MMMM YYYY');

    const startTime = moment(selectedTime, 'HH.mm');
    const endTime = startTime.clone().add(1, 'hour');
    const formattedTime = `${startTime.format('HH.mm')} - ${endTime.format('HH.mm')}`;

    return (
        <div className="px-4 py-4 rounded-lg shadow-box  border-searchbar">
            <p className="text-sm mb-3 text-dark">Pastikan sesi yang kamu isi benar:</p>
            <div className="flex">
                <div className="rounded-full overflow-hidden mr-3" style={{width: '72px', height: '72px'}}>
                    <img src={imageSource} className="w-full h-full object-cover" alt="" />
                </div>
                <div>
                    <p className="font-semibold text-dark mb-[5px]">{practitioner.name_with_title}</p>
                    <div className="flex gap-2">
                        <PsychologTitle title={practitioner.title} />
                        {practitioner.secondary_title && <PsychologTitle title={practitioner.secondary_title} />}
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <div className="flex items-center mb-3">
                    <div className="bg-secondary rounded-full w-7 h-7 flex items-center justify-center ">
                        <FiCalendar className="text-primary text-xl" />
                    </div>
                    <p className="text-sm text-navbar-accent ml-2">{formattedDate}</p>
                </div>

                <div className="flex items-center mb-3">
                    <div className="bg-secondary rounded-full w-7 h-7 flex items-center justify-center ">
                        <FiClock className="text-primary text-xl " />
                    </div>
                    <p className="text-sm text-navbar-accent ml-2">{formattedTime}</p>
                </div>
                <div className="flex items-center mb-3">
                    <div className="bg-secondary rounded-full w-7 h-7 flex items-center justify-center ">
                        {selectedMedia === 'video' ? (
                            <FiVideo className="text-primary text-xl" />
                        ) : (
                            <FiPhone className="text-primary text-xl" />
                        )}
                    </div>
                    <p className="text-sm text-navbar-accent ml-2">{selectedMedia} call</p>
                </div>
            </div>
            <p className="text-sm text-dark">
                Kami akan mengingatkanmu 24 jam, 4 jam, dan 10 menit sebelum sesi dimulai
            </p>
        </div>
    );
};
