import {SigninPage} from 'moduls/account/screens/SigninPage';
import {HomePage} from 'moduls/home/screens/HomePage';
import {LayananDanHargaPage} from 'moduls/home/screens/LayananDanHargaPage';
import {PractitionersListPage} from 'moduls/account/screens/PractitionersListPage';
import {KonfirmasiPembayaran} from 'moduls/account/screens/KonfirmasiPembayaran';
import {RiwayatPage} from 'moduls/account/screens/RiwayatPage';
import {ScreeningPage} from 'moduls/account/screens/ScreeningPage';
import {PembayaranSuccesPage} from 'moduls/account/screens/PembayaranSuccesPage';
import HomePsychologicalTests from 'moduls/psychological_tests/HomePsychologicalTests';
import Test from 'moduls/psychological_tests/Test';
import {B2BHome} from 'moduls/b2b/screens/B2BHome';
import {CompanyPage} from 'moduls/home/screens/CompanyPage';

interface PageRouting {
    title: string;
    path: string;
    component: () => JSX.Element;
}

export const Routing: PageRouting[] = [
    {
        title: 'SignIn Page',
        path: '/signin',
        component: SigninPage,
    },
    {
        title: 'Practitioners List page',
        path: '/practitioners',
        component: PractitionersListPage,
    },
    {
        title: 'Konfirmasi Pembayaran',
        path: '/confirm-payment',
        component: KonfirmasiPembayaran,
    },
    {
        title: 'Bandingkan Layanan dan Harga',
        path: '/layanan',
        component: LayananDanHargaPage,
    },
    {
        title: 'Riwayat Page',
        path: '/history',
        component: RiwayatPage,
    },
    {
        title: 'Screening',
        path: '/screening/:sessionId',
        component: ScreeningPage,
    },
    {
        title: 'Payment Succes Page',
        path: '/payment/success/:sessionId',
        component: PembayaranSuccesPage,
    },
    {
        title: 'Home Test',
        path: '/psychological_tests',
        component: HomePsychologicalTests,
    },
    {
        title: 'Home Test',
        path: '/psychological_tests/:test_type',
        component: Test,
    },
    {
        title: 'Home Test',
        path: '/psychological_tests/:test_type/:token',
        component: Test,
    },
    {
        title: 'Teduh for Business',
        path: '/business',
        component: B2BHome,
    },
    {
        title: 'Tawaran Dari Tempat Kerjamu',
        path: '/company/:discount_type',
        component: CompanyPage,
    },
    {
        title: 'Practitioners List',
        path: '/company/:discount_type/practitioners',
        component: PractitionersListPage,
    },
    {
        title: 'Konfirmasi Pembayaran',
        path: '/company/:discount_type/confirm-payment',
        component: KonfirmasiPembayaran,
    },
    {
        title: 'Home Page',
        path: '/',
        component: HomePage,
    },
];
