import {useEffect, useRef, useState} from 'react';
import {FiBook} from 'react-icons/fi';

interface Props {
    label: string;
}

const Drawer = () => {
    return (
        <div
            className="absolute bg-white border rounded-lg w-[12em] shadow-box right-0 md:-right-[5em] 
        top-[3em]"
        >
            <div className="border-b pb-2">
                <div className="p-2 pb-0 flex flex-col gap-3">
                    <p className="text-tertiary text-xs">Blog</p>
                    <a
                        className="cursor-pointer select-none"
                        href="https://id.teduh.io/blog"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Blog
                    </a>
                    <a
                        className="cursor-pointer select-none"
                        href="https://id.teduh.io/about-us"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Tentang Kami
                    </a>
                </div>
            </div>
            <div className="border-b pb-2">
                <div className="p-2 pb-0 flex flex-col gap-3">
                    <p className="text-tertiary text-xs">For Business</p>
                    <a
                        className="cursor-pointer select-none"
                        href="https://id.teduh.io/for-business"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Teduh For Business
                    </a>
                    <a
                        className="cursor-pointer select-none"
                        href="https://id.teduh.io/pricing"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Pricing
                    </a>
                </div>
            </div>
            <div>
                <div className="p-2 flex flex-col gap-3">
                    <p className="text-tertiary text-xs">Fitur</p>
                    <a
                        className="cursor-pointer select-none"
                        href="https://id.teduh.io/konseling"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Konseling
                    </a>
                    <a
                        className="cursor-pointer select-none"
                        href="https://id.teduh.io/self-help"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Self-Help
                    </a>
                    <a
                        className="cursor-pointer select-none"
                        href="https://id.teduh.io/mindfulness"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Meditasi
                    </a>
                    <a
                        className="cursor-pointer select-none"
                        href="https://id.teduh.io/moodtracker"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Mood Tracker
                    </a>
                </div>
            </div>
        </div>
    );
};

export const NavLinkWithDrawer = ({label}: Props) => {
    const [open, setOpen] = useState<boolean>(false);

    const ref = useRef<any>(null);

    const handleOpen = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const handleClickOutsite = (e: any) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutsite);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsite);
        };
    }, [ref]);

    return (
        <div className="relative flex md:justify-center items-center" ref={ref}>
            <button onClick={handleOpen} className="cursor-pointer select-none">
                {' '}
                <div className="flex justify-center items-center">
                    <div className="mr-2">
                        <FiBook size={24} />
                    </div>
                    {label}
                </div>
            </button>
            {open && <Drawer />}
        </div>
    );
};
