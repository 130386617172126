import {ICounseling} from 'model/api';
import {UseMQOptions, UseRQOptions, useMQ, useRQ} from '../custom-hooks';
import {CounselingService} from '../service/counseling';
import {CounselingQueryKey} from '../query-key';

export function Psychologist(options?: UseRQOptions<ICounseling.Psychologist.Response>, filter?: ICounseling.Filters) {
    return useRQ([CounselingQueryKey.PSYCHOLOGIST, filter], CounselingService.Psychologist, options);
}

export function Price(options?: UseRQOptions<ICounseling.Payment.Price.Response>) {
    return useRQ([CounselingQueryKey.PRICE], CounselingService.Price, options);
}

export function Payment(options?: UseRQOptions<ICounseling.Payment.Response>, filter?: ICounseling.Filters) {
    return useRQ([CounselingQueryKey.PAYMENT, filter], CounselingService.Payment, options);
}

export function DetailPayment(options?: UseRQOptions<{data: ICounseling.Payment.Item}>, filter?: {id: string}) {
    return useRQ([CounselingQueryKey.DETAIL_PAYMENT, filter], CounselingService.DetailPayment, options);
}

export function MethodPayment(options?: UseRQOptions<ICounseling.Payment.Method.Response>, filter?: {cost_id: string}) {
    return useRQ([CounselingQueryKey.METHOD_PAYMENT, filter], CounselingService.MethodPayment, options);
}

export function AddPayment(options?: UseMQOptions<ICounseling.Payment.Success_Add, ICounseling.Payment.Add>) {
    return useMQ([CounselingQueryKey.ADD_PAYMENT], CounselingService.AddPayment, options);
}

export function UpdatePayment(options?: UseMQOptions<ICounseling.Payment.Success_Add, ICounseling.Payment.Add>) {
    return useMQ([CounselingQueryKey.UPDATE_PAYMENT], CounselingService.UpdatePayment, options);
}

export function Session(options?: UseRQOptions<ICounseling.Session.Response>, filter?: ICounseling.Filters) {
    return useRQ([CounselingQueryKey.SESSION, filter], CounselingService.Session, options);
}

export function DetailSession(options?: UseRQOptions<{data: ICounseling.Session.Item}>, filter?: {id: string}) {
    return useRQ([CounselingQueryKey.DETAIL_SESSION, filter], CounselingService.DetailSession, options);
}

export function AddSession(options?: UseMQOptions<ICounseling.Session.Success_Add, ICounseling.Session.Add>) {
    return useMQ([CounselingQueryKey.ADD_SESSION], CounselingService.AddSession, options);
}

export function CancelSession(options?: UseMQOptions<ICounseling.Session.Success_Cancel, ICounseling.Session.Cancel>) {
    return useMQ([CounselingQueryKey.CANCEL_SESSION], CounselingService.CancelSession, options);
}

export function EndSession(options?: UseMQOptions<any, {id: string}>) {
    return useMQ([CounselingQueryKey.END_SESSION], CounselingService.EndSession, options);
}

export function Discount_Code(
    options?: UseMQOptions<ICounseling.Discount_Code.Response, {code: string; price: number}>,
) {
    return useMQ([CounselingQueryKey.DISCOUNT_CODE], CounselingService.Discount_Code, options);
}

export function Review(options?: UseRQOptions<ICounseling.Review.Response>, filter?: ICounseling.Filters) {
    return useRQ([CounselingQueryKey.REVIEW, filter], CounselingService.Review, options);
}

export function AddReview(options?: UseMQOptions<ICounseling.Review.Success_Add, ICounseling.Review.Add>) {
    return useMQ([CounselingQueryKey.ADD_REVIEW], CounselingService.AddReview, options);
}

export function AddScreening(options?: UseMQOptions<ICounseling.Screening.Success_Add, ICounseling.Screening.Add>) {
    return useMQ([CounselingQueryKey.ADD_SCREENING], CounselingService.AddScreening, options);
}

export function Room(options?: UseMQOptions<{token: string}, {session_id: string; user_id: string}>) {
    return useMQ([CounselingQueryKey.ROOM], CounselingService.Room, options);
}
