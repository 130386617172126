import { Link } from 'react-router-dom';
import AppMockUp from 'assets/images/b2b/app-mockup.svg';
import AppStore from 'assets/images/b2b/app-store.svg';
import PlayStore from 'assets/images/b2b/play-store.svg';
import { translate } from 'i18n';
import { Markup } from "interweave";
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export const Download = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <div className='flex items-center justify-center p-3 flex-col md:flex-row'>
        <motion.div
          ref={ref}
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : {}}
          transition={{ duration: 2 }}
          className='w-30'
        >
          <img src={AppMockUp} className='px-[3%]'/>
        </motion.div>
        <div className='mx-[4%] px-[3%] content-between'>
          <motion.div
            ref={ref}
            initial={{ opacity: 0, x: 50 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1 }}
          >
            <h1 className='font-semibold text-2xl/normal sm:text-5xl text-transparent bg-clip-text bg-gradient-custom from-start-carousel-one to-end-carousel-one'>{translate('b2b_download_title')}</h1>
          </motion.div>
          <motion.div
            ref={ref}
            initial={{ opacity: 0, x: -50 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.2 }}
          >
          <p className='sm:mb-5 font-semibold text-black text-2xl sm:text-4xl'><Markup content={translate('b2b_download_desc')} /></p>
          </motion.div>
          <div className='flex mt-8 sm:mt-20'>
            <Link to="https://play.google.com/store/apps/details?id=id.teduhio.app">
                <img src={AppStore} alt="App Store" className='h-12'/>
            </Link>
            <Link to="https://apps.apple.com/id/app/teduh-konseling-meditasi/id1637005394">
                <img src={PlayStore} alt="Play Store" className='h-12'/>
            </Link>
          </div>
        </div>
    </div>
  );
};