import pemantauMoodIllustration from 'assets/images/home/carousel/pemantau-mood-mockup.png';

export const PemantauMood = () => {
  return (
    <div className='h-full w-full flex items-center justify-center bg-pemantau-mood-carousel bg-no-repeat bg-cover rounded-5xl relative'>
      <div className='flex items-center justify-between px-10 h-full w-[95%]'>
        <div>
          <h1 className='font-semibold text-3xl md:text-5xl pb-4 text-[#7B61FF]'>Pemantau Mood</h1>
          <p className='mb-5 text-sm md:text-md'>Pantau dan catat suasana hati harianmu agar lebih sehat mental.</p>
          <button className='px-4 py-1.5 rounded-md font-medium text-white text-sm bg-[#7B61FF]'>Lihat Selengkapnya</button>
        </div>
      </div>
      <img src={pemantauMoodIllustration} className='hidden md:block absolute w-[30vw] 2xl:w-[27vw] right-28 -bottom-[12em]' />
    </div>
  );
};