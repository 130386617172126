interface Props {
    title: 'Senior Psychologist' | 'Psychologist' | 'Counselor' | 'Career Coach';
    className?: string;
}

export const PsychologTitle = ({title, className}: Props) => {
    const setTitle = () => {
        if (title == 'Senior Psychologist') {
            return {
                label: 'Psikolog Senior',
                bg_color: '#39DA00',
            };
        }
        if (title == 'Psychologist') {
            return {
                label: 'Psikolog',
                bg_color: '#00B3FA',
            };
        }
        if (title == 'Career Coach') {
            return {
                label: 'Career Coach',
                bg_color: '#FF6E6E',
            };
        }
        if (title == 'Counselor') {
            return {
                label: 'Konselor',
                bg_color: '#FFA338',
            };
        }
        return undefined;
    };

    const item:
        | {
              label: string;
              bg_color: string;
          }
        | undefined = setTitle();

    return item ? (
        <div
            className={'py-1.5 px-3 rounded-full w-auto align-baseline' + className}
            style={{backgroundColor: item.bg_color}}
        >
            <h1 className="text-white text-xs line-clamp-1">{item.label}</h1>
        </div>
    ) : null;
};
