import R from 'assets/images/R.png';
import S from 'assets/images/S.png';
import tedoo_gitar from 'assets/images/tedoo_gitar.png';
import {Link, useParams} from 'react-router-dom';
import {RIASECTest} from './RIASECTest';
import {DISCTest} from './DISCTest';
import {RMIBTest} from './RMIBTest';
import {FiCheck, FiX} from 'react-icons/fi';

function Test() {
    const {test_type, token} = useParams();

    const setItem = () => {
        if (test_type == 'riasec') {
            return {
                name: 'TES RIASEC',
                title: 'Ketahui Jalur Karier yang Cocok Untukmu',
                description:
                    'Tes ini cocok untukmu yang sedang mengalami kebingungan dalam menentukan arah jurusan atau karier yang cocok. Melalui tes ini, kamu akan mendapatkan rekomendasi. ',
                description_list: [
                    'Tes terbagi dalam tiga bagian. Lamu bisa beristirahat sejenak di akhir setiap bagian',
                    'Kamu diharapkan menyelesaikan dalam 1 sesi karena progres pengerjaan akan hilang jika kamu keluar dari laman tes',
                ],
                component: <RIASECTest />,
                card_bg: 'bg-card-1',
                card_ilu: R,
                number_of_question: 100,
                time: 45,
            } as any;
        }

        if (test_type == 'disc') {
            return {
                name: 'TES KEPRIBADIAN',
                title: 'Kenali Dirimu dan Kepribadianmu',
                description:
                    'Tes ini cocok untukmu yang sedang menggali kepribadian untuk memahami pola kerjamu sehingga kamu dapat memaksimalkan performamu. ',
                description_list: [
                    'Tes terbagi dalam tiga bagian. Lamu bisa beristirahat sejenak di akhir setiap bagian',
                    'Kamu diharapkan menyelesaikan dalam 1 sesi karena progres pengerjaan akan hilang jika kamu keluar dari laman tes',
                ],
                component: <DISCTest />,
                card_bg: 'bg-card-2',
                card_ilu: S,
                number_of_question: 100,
                time: 45,
            } as any;
        }

        if (test_type == 'rmib') {
            return {
                name: 'TES RMIB',
                title: 'Kenali Dirimu dan Kepribadianmu',
                description:
                    'Tes ini cocok untukmu yang sedang mengalami kebingungan dalam menentukan arah jurusan atau karier yang cocok. Melalui tes ini, kamu akan mendapatkan rekomendasi. ',
                description_list: [
                    'Tes terbagi dalam tiga bagian. Lamu bisa beristirahat sejenak di akhir setiap bagian',
                    'Kamu diharapkan menyelesaikan dalam 1 sesi karena progres pengerjaan akan hilang jika kamu keluar dari laman tes',
                ],
                component: <RMIBTest />,
                card_bg: 'bg-card-3',
                card_ilu: tedoo_gitar,
                number_of_question: 100,
                time: 45,
            } as any;
        }

        return undefined;
    };

    const test_item: any | undefined = setItem();
    return (
        <div className="flex flex-col items-center h-screen">
            {!!token ? (
                <div className=" w-full max-w-6xl flex flex-col items-center pt-8">{test_item.component}</div>
            ) : (
                <div className=" w-full max-w-xl flex flex-col items-center">
                    <Link to={'/psychological_tests'} className=" self-start m-2">
                        <FiX size={40} />
                    </Link>
                    <div
                        className={`aspect-video max-w-md w-full flex flex-col justify-end ${test_item.card_bg} bg-no-repeat bg-cover relative p-8 mt-8`}
                    >
                        <img
                            src={test_item.card_ilu}
                            className=" aspect-square w-4/6 right-0 top-0 absolute -mt-24 -mr-8 sm:-mr-12 sm:-mt-32"
                        />
                        <text className=" font-normal text-gray-500">{test_item.name}</text>
                        <text className=" font-semibold text-left sm:text-2xl text-xl">{test_item.title}</text>
                    </div>
                    <div className=" p-4 mt-4">
                        <text className=" font-normal text-md sm:text-md">{test_item.description}</text>
                        <div className=" mt-8">
                            {test_item.description_list.map((i: string) => {
                                return (
                                    <div className=" flex items-start my-2">
                                        <div className=" bg-primaryLightest rounded-full self-start p-1 mr-2">
                                            <FiCheck className=" stroke-primary" size={16} />
                                        </div>
                                        <text className=" font-normal text-md text-gray-500">{i}.</text>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className=" w-full flex bg-white shadow-box sm:shadow-none items-center fixed sm:relative bottom-0 justify-center mt-8 p-4 rounded-md">
                        <Link to={'./1234'} className=" w-full bg-primary p-3 text-white rounded-md text-center ">
                            Mulai Tes
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Test;
