import InteractiveSeminarIcon from '../../assets/images/b2b/icon-service-1-seminar.svg';
import GroupCounselingIcon from '../../assets/images/b2b/icon-service-2-group-counseling.svg';
import OnlineCounselingIcon from '../../assets/images/b2b/icon-service-3-online-counseling.svg';
import OnSitePsychologistIcon from '../../assets/images/b2b/icon-service-4-psychologist.svg';
import CompanyDiagnosisIcon from '../../assets/images/b2b/icon-service-5-diagnosis.svg';
import PremiumAppIcon from '../../assets/images/b2b/icon-service-6-premium-app.svg';
import { ServiceCard } from './ServiceCard';
import { translate } from 'i18n';
import { Markup } from 'interweave';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ourServiceData = [
  {
    image: InteractiveSeminarIcon,
    title: 'Interactive Seminar',
    description: 'b2b_is_desc',
    duration: 1,
  },
  {
    image: GroupCounselingIcon,
    title: 'Group Counseling',
    description: 'b2b_gc_desc',
    duration: 1.2,
  },
  {
    image: OnlineCounselingIcon,
    title: 'Online Counseling',
    description: 'b2b_oc_desc',
    duration: 1.4,
  },
  {
    image: OnSitePsychologistIcon,
    title: 'On-Site Psychologist',
    description: 'b2b_osp_desc',
    duration: 1.6,
  },
  {
    image: CompanyDiagnosisIcon,
    title: 'Company Diagnosis',
    description: 'b2b_cd_desc',
    duration: 1.8,
  },
  {
    image: PremiumAppIcon,
    title: 'Premium App Access',
    description: 'b2b_paa_desc',
    duration: 2,
  },
];

export const OurService = () => {
  const [ref, inView] = useInView({
    triggerOnce: false,
  });

  const [cardRef, cardInView] = useInView({
    triggerOnce: false,
  });

  return (
    <div className='flex flex-col md:flex-row justify-center bg-blueLight py-10 xl:py-20 px-[7%]'>
        <div className='md:w-[40%]'>
            <h1 className='font-semibold text-2xl sm:text-4xl md:text-5xl/normal pb-4 text-black'><Markup content={translate('b2b_oc_title')}/></h1>
            <hr className="w-[60px] h-[10px] mt-4 mb-12 bg-primary border-0 rounded"/>
            <motion.div 
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={inView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 1 }}
              >
            <button onClick={() => window.open('https://wa.me/6285219498199')} className='px-5 py-3 rounded-md font-medium text-white text-sm bg-gradient-custom from-start-carousel-one to-end-carousel-one hidden md:inline'>{translate('contact_us_button')}</button>
            <button onClick={() => window.open('https://go.teduh.io/proposalB2BTeduh')} className='px-5 py-3 rounded-md font-semibold text-primary text-sm text-bold hidden md:inline'>{translate('see_how_it_works_button')}</button>
            </motion.div>
        </div>
        <div 
          className='md:w-[60%]'>
          <div className="grid grid-cols-1 sm:grid-cols-2">
            {ourServiceData.map((data) => 
              <motion.div 
                ref={cardRef}
                initial={{ opacity: 0, x: 100 }}
                animate={cardInView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: data.duration }}
              >
                <ServiceCard image={data.image} title={data.title} description={data.description} className='p-6'/>
              </motion.div>
            )}
          </div>
        </div>
        <div className='flex flex-col items-center mt-8'>
          <button onClick={() => window.open('https://wa.me/6285219498199')} className='px-5 py-3 rounded-md font-medium text-white text-sm bg-gradient-custom from-start-carousel-one to-end-carousel-one md:hidden'>{translate('contact_us_button')}</button>
          <button onClick={() => window.open('https://go.teduh.io/proposalB2BTeduh')} className='px-5 py-3 rounded-md font-semibold text-primary text-sm text-bold md:hidden'>{translate('see_how_it_works_button')}</button>
        </div>
    </div>
  );
};