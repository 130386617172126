import { ReviewCard } from "components/review/Card";
import Marquee from "react-fast-marquee";

// Images
import image1 from "assets/images/home/reviews-image/anthony-tran-3Xkms-gMvZg-unsplash.jpg";
import image2 from "assets/images/home/reviews-image/dale-alejandro-RYrMPoxzaZ0-unsplash.jpg";
import image3 from "assets/images/home/reviews-image/darshan-patel-QJEVpydulGs-unsplash.jpg";
import image4 from "assets/images/home/reviews-image/dev-asangbam-otSpc4dELRs-unsplash.jpg";
import image5 from "assets/images/home/reviews-image/ilyuza-mingazova-nouRUMB6lEc-unsplash.jpg";
import image6 from "assets/images/home/reviews-image/jernej-graj-GY5gWDimEyg-unsplash.jpg";
import image7 from "assets/images/home/reviews-image/lau-keith-dcZsxUAAJXs-unsplash.jpg";
import image8 from "assets/images/home/reviews-image/mason-wilkes-TMgQMXoglsM-unsplash.jpg";


const reviewsTop = [
  {
    image: image1,
    content: 'Psikolog ramah, tenang, humble, saran dan solusi yang diberikan juga sangat membantu. Terima kasih, Kak!',
    author: 'ZA, 23 tahun',
  },
  {
    image: image2,
    content: 'Psikolognya mendengar keluhan dengan baik. Semoga kesehatan mental saya bisa segera membaik',
    author: 'AMT, 25 tahun',
  },
  {
    image: image3,
    content: 'Akhirnya setelah bolak-balik mikir konseling nggak, akhirnya jadi dan worth-it, thanks!',
    author: 'RPD, 31 tahun',
  },
  {
    image: image4,
    content: 'Terima kasih karena telah membantuku paham perubahan yang ga aku pahami, Kak Psikolog!',
    author: 'FY, 25 tahun',
  },
];

const reviewsBottom = [
  {
    image: image5,
    content: 'So helpful! Suka bgt sama fitur self help-nya. Anytime feeling down, Teduh app come to the rescue.',
    author: 'SM, 24 tahun',
  },
  {
    image: image6,
    content: 'Membantu saya untuk curhat disaat belum ada yg dipercaya untuk mendengarkan cerita saya ',
    author: 'FT',
  },
  {
    image: image7,
    content: 'Apps yang pas untuk seseorang yang membutuhkan  konselor dan psikolog, fitur-fitur nya keren juga 🙏🏻',
    author: 'DA',
  },
  {
    image: image8,
    content: 'Tampilannya app lucu dan kalau butuh bantuan instan tinggal buka self-help-nya',
    author: 'RPD, 31 tahun',
  },
];

export const Reviews = () => {
  return (
    <div className="bg-review-background py-20 pb-40 bg-cover">
      <h1 className="w-2/3 mx-auto md:mx-0 md:w-full text-center font-semibold text-xl md:text-3xl mb-10" >Apa yang mereka katakan tentang Teduh</h1>
      <Marquee pauseOnHover className="mb-10" autoFill={true}>
        {reviewsTop.map((review, index) => (
          <ReviewCard key={index} {...review} />
        ))}
      </Marquee>
      <Marquee pauseOnHover direction="right" autoFill={true}>
        {reviewsBottom.map((review, index) => (
          <ReviewCard key={index} {...review} />
        ))}
      </Marquee>
    </div>
  );
};