import {DateObject} from '../BookingSessionModal';

interface Props {
    dateObject: DateObject;
    selectedDate: string;
    availableDays: Record<string, string[]>;
    selectDate: (dateObject: DateObject) => void;
}

export const DateComponent = ({dateObject, selectedDate, availableDays, selectDate}: Props) => {
    return (
        <button
            className={`rounded-full mr-[4px] w-fit p-1 border-2 border-searchbar flex flex-col items-center pt-2 pb-1 px-1 gap-1 select-none ${
                dateObject.isoDate === selectedDate
                    ? 'bg-primary text-white'
                    : availableDays[dateObject.dayFull].length === 0
                    ? 'bg-searchbar text-tertiary pointer-events-none'
                    : ''
            } `}
            onClick={() => selectDate(dateObject)}
        >
            <p className="text-[14px] md:text-base">{dateObject.day}</p>
            <div
                className={`w-[32px] md:w-11 h-[32px] md:h-11 rounded-full p-1 flex justify-center items-center ${
                    dateObject.isoDate === selectedDate ? 'bg-white text-black' : ''
                }`}
            >
                <p className="font-semibold text-lg md:text-xl">{dateObject.date}</p>
            </div>
        </button>
    );
};
