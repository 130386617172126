interface Props {
    description: string;
}

export const ProfilSingkat = ({description}: Props): JSX.Element => {
    return (
        <div>
            <p className="font-semibold mb-1 text-[16px] md:text-lg">Profil Singkat</p>
            <p className="text-[14px] text-navbar-accent leading-6">
                {description}
            </p>
            {/* <p className="text-sm font-bold mt-1 text-primary cursor-pointer">Lihat lebih banyak</p> */}
        </div>
    );
};
