interface Props {
    data: string[] | undefined;
    setData: (data: string[] | undefined) => void;
}

const FilterTags = ({data, setData}: Props): JSX.Element => {
    const topics = [
        'Stres & Cemas',
        'Insecurity',
        'Keluarga',
        'Hubungan/Pernikahan',
        'Akademik',
        'Tempat Kerja',
        'Anak',
        'Kebutuhan Khusus',
        'Seksualitas',
        'Karier',
        'Hubungan Sosial',
        'Trauma',
        'Adiksi',
        'Depresi',
        'Kesepian',
        'Mengelola Emosi',
        'Masalah Tidur',
        'Perkembangan',
        'Pikiran Bunuh Diri',
        'Self - Harm',
        'Masalah Kepribadian',
        'Pola Makan',
        'Masalah Adaptasi',
        'Berduka',
        'Minat & Bakat',
    ];

    const setActive = (item: string, active: boolean) => {
        if (active) {
            const new_data = data?.filter(i => i !== item);
            setData(new_data || undefined);
        } else {
            setData([...(data || []), item]);
        }
    };
    return (
        <div>
            <h1 className="text-md font-semibold mt-4 mb-2">Topik konseling umum</h1>

            <div className="flex flex-row gap-1 mt flex-wrap">
                {topics.map((item, index) => {
                    const active = !!data?.find(data_item => data_item == item);
                    return (
                        <button
                            key={index}
                            onClick={() => setActive(item, active)}
                            className={`${active ? 'bg-primary text-white ' : 'bg-gray-200 text-gray-800'}
                            font-regular bg-black text-xs py-1.5 px-3 mt-1 rounded-full hover:bg-primaryLightest hover:text-primary
                            `}
                        >
                            {item}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default FilterTags;
