import TeduhAppIllustration from "assets/images/home/teduh-app-2.png";

export const AboutTeduh = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center gap-10 md:gap-20 w-full px-[1.5em] md:p-[5em] items-center">
      <img src={TeduhAppIllustration} alt='Teduh App' className="w-[70%] md:w-[40%]" />
      <div className="flex flex-col items-center w-full md:w-1/3">
        <div className="p-6 bg-secondary rounded-xl gap-2 md:gap-0">
          <h3 className="font-semibold text-xl md:text-2xl text-dark mb-2">Temukan yang Cocok</h3>
          <p className="text-navbar-accent text-sm md:text-md">Pilih Praktisi Kesehatan Mental yang paling cocok untukmu. Pilih jadwal dan media komunikasi yang paling kamu suka</p>
        </div>
        <div className="p-6">
          <h3 className="font-semibold text-xl md:text-2xl text-dark mb-1 md:mb-2">Terjangkau</h3>
          <p className="text-navbar-accent text-sm md:text-md">Tersedia berbagai layanan dengan variasi harga. Kamu tinggal memilih yang sesuai dengan budgetmu.</p>
        </div>
        <div className="p-6">
          <h3 className="font-semibold text-xl md:text-2xl text-dark mb-1 md:mb-2">Terpercaya</h3>
          <p className="text-navbar-accent text-sm md:text-md">Praktisi kami memiliki sertifikasi, seperti izin praktik dari Himpunan Psikologi Indonesia. Privasimu sepenuhnya terlindungi.</p>
        </div>
      </div>
    </div>
  );
};