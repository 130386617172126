import {Routing} from './routing';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

export const MainNavigator = () => {
    return (
        <BrowserRouter>
            <Routes>
                {Routing.map(route => {
                    const Component = route.component;
                    return <Route key={route.path} path={route.path} element={<Component />} />;
                })}
            </Routes>
            <Toaster position='top-center' />
        </BrowserRouter>
    );
};
