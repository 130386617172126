import company from 'assets/images/b2b/companies.svg';
import { translate } from 'i18n';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export const TrustedBy = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <div className='w-full py-16 flex flex-col items-center justify-center'>
      <h1 className="text-navbar-accent mb-6 md:mb-16 text-base md:text-4xl lg:font-medium ">{translate('b2b_tb_title')}</h1>
      <motion.div
        ref={ref}
        initial={{ opacity: 0, x: 0 }}
        animate={inView ? { opacity: 1, x: 0 } : {}}
        transition={{ duration: 2 }}
        className='w-[80%]'
      >
        <img src={company} className='mx-auto'/>
      </motion.div>
    </div>
  );
};