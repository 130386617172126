import { Accordion } from "components/common/Accordion";
import illustration from "assets/images/home/faq-illustration.png";
import { useState } from "react";

const faqData = [
  {
    title: "Bisakah saya konseling di perangkat lain?",
    description: "Bisa. Saat ruangan konseling sudah bisa dibuka, tap ikon rantai dan akan muncul tautan ruang konseling. Salin tautan untuk konseling ke perangkat lain yang kamu inginkan.",
  },
  {
    title: "Apa perbedaan antara layanan Senior Psikolog, Psikolog, Konselor, dan Career Coach?",
    description: 'Perbedaan mereka terletak pada bidang spesialisasi, pengalaman, dan harga. Kamu bisa melihat selengkapnya dengan mengetuk tulisan "bandingkan layanan & harga" berwarna biru di bawah keempat kotak opsi layanan atau tap di sini.',
  },
  {
    title: "Bagaimana cara menemukan praktisi yang cocok dengan masalahku?",
    description: 'Kamu dapat mencari praktisi yang tepat dengan cara mengetikkan topik konselingmu di bar pencarian. Teduh akan langsung menampilkan daftar praktisi yang mendalami bidang tersebut. Kamu juga dapat menggunakan fitur filter untuk memfilter praktisi berdasarkan topik populer dan jadwal yang sesuai dengan waktumu.',
  },
  {
    title: "Apa yang akan aku dapatkan dari sesi konseling di Teduh?",
    description: 'Kamu dapat menceritakan masalahmu dan praktisi kesehatan mental Teduh akan mencarikan solusinya bersama kamu. Perlu diingat, tidak semua masalah dapat selesai dalam sekali konseling, ya. Namun, kami berusaha memastikan kamu mengantongi strategi yang dapat kamu terapkan untuk menghadapi masalahmu sejak sesi pertamamu. Praktisi kami juga akan mengirimkan pesan pascasesi kepada kamu.',
  },
  {
    title: "Apakah aku bisa membatalkan sesi yang sudah kubayar jika tiba-tiba berhalangan hadir?",
    description: 'Bisa. Tinggal tap kartu "sesi mendatang" yang muncul di atas setelah kamu merencanakan sesi (atau lewat ikon histori jam di pojok kanan atas dan pilih sesi yang akan dibatalkan). Kamu akan dibawa ke halaman berisi detail sesimu. Lalu tap tombol dengan tiga titik di pojok kanan bawah […] dan pilih "Batalkan Sesi". Maksimal pembatalan adalah 12 jam sebelum sesi, ya. \n\nNamun, kami mengembalikan uangmu bukan dalam wujud tunai, melainkan dalam wujud satu token konseling yang dapat kamu gunakan untuk konseling di lain waktu, bahkan dengan praktisi lain dengan harga yang sama/lebih murah. Setelah melakukan pembatalan, akan muncul kartu bertuliskan "Sesi dengan [nama praktisi] dibatalkan. Tentukan sesi baru?" di bagian teratas halaman ini. Kamu dapat menentukan sesi barumu kapanpun dengan mengetuk kartu tersebut tanpa perlu membayar lagi.',
  },
  {
    title: 'Bagaimana jika setelah menentukan sesi aku berubah pikiran ingin mengganti waktu konseling atau mengganti praktisi Teduh untuk sesiku?',
    description: 'Kamu bisa, dengan cara membatalkan sesi terlebih dahulu. Tinggal tap kartu "sesi mendatang" yang muncul di atas setelah kamu merencanakan sesi (atau lewat ikon histori jam di pojok kanan atas dan pilih sesi yang akan dibatalkan). Kamu akan dibawa ke halaman berisi detail sesimu. Lalu tap tombol dengan tiga titik di pojok kanan bawah […] dan pilih "Batalkan Sesi". Setelah membatalkan sesi, akan muncul kartu "Sesi dengan [nama praktisi] dibatalkan. Tentukan sesi baru?" di bagian teratas halaman ini. Kamu dapat menentukan sesi barumu dengan mengetuk kartu tersebut tanpa perlu membayar lagi.',
  },
  {
    title: 'Apakah yang terjadi jika di tengah sesi konseling internetku terputus?',
    description: 'Kamu bisa masuk kembali ke ruang video call-mu. Jika praktisi yang menanganimu memiliki waktu, ia dapat memperpanjang sesi untuk mengganti waktumu yang hilang.',
  },
  {
    title: 'Bagaimana jika aku ada pertanyaan lain yang tidak tercantum di sini?',
    description: 'Kamu bisa menanyakannya ke kami lewat WhatsApp Customer Service di sini.'
  }
];

export const FAQ = () => {
  const [showMore, setShowMore] = useState<boolean>(false);

  const firstArray = faqData.slice(0, 4);
  const restArray = faqData.slice(4);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="md:py-20 py-14 w-full flex flex-col md:flex-row px-5 md:px-16 justify-between">
      <div className="md:w-1/2 px-6 md:px-0">
        <img src={illustration} alt="illustration" className="md:h-[35em] md:mx-auto md:pt-4 mb-12 md:mb-0" />
      </div>
      <div className="md:w-1/2">
        <h1 className="font-semibold text-xl md:text-3xl mb-8">Pertanyaan yang Sering Diajukan</h1>
        {firstArray.map((data) => (
          <Accordion {...data} />
        ))}
        {showMore && (
          restArray.map((data) => (
            <Accordion {...data} />
          ))
        )}
        <p className="font-semibold text-primary text-sm cursor-pointer text-right" onClick={handleShowMore}>{!showMore && 'Lihat Pertanyaan Lain'}</p>
      </div>
    </div>
  );
};