// import {Profil} from 'components/PopupPractitionerProfile/Profil';
import {ProfilSingkat} from 'components/profil/ProfilSingkat';
import {KataKunci} from 'components/profil/KataKunci';
import {DokumenSertifikat} from 'components/profil/DokumenSertifikat';
import {SlotKosong} from 'components/profil/SlotKosong';
// import {Ulasan} from 'components/PopupPractitionerProfile/Ulasan';
// import {BookingSesi} from 'components/PopupPractitionerProfile/BookingSesi';
import {Modal} from 'components/common/Modal';
import {PsikologProfileHeader} from 'components/profil/PsikologProfileHeader';
import {ICounseling} from 'model/api';
import { BackButton } from 'components/common/BackButton';

interface Props {
    practitioner: ICounseling.Psychologist.Item;
    setOpen: (open: boolean) => void;
    setOpenBookingModal: (open: boolean) => void;
}

export const PractitionProfileModal = ({practitioner, setOpen, setOpenBookingModal}: Props) => {
    const handleClose = () => {
        setOpen(false);
    };

    const openBookingModal = () => {
        setOpen(false);
        setOpenBookingModal(true);
    };

    return (
        <Modal close={handleClose}>
            <div className="relative p-4 shadow-card flex items-center justify-center">
                <BackButton setOpen={setOpen} />
                <h3 className="font-semibold">Profil</h3>
            </div>
            <div className="p-6 flex flex-col gap-7 overflow-y-auto h-inherit">
                <PsikologProfileHeader practitioner={practitioner} />
                <ProfilSingkat description={practitioner.description} />
                <KataKunci keywords={practitioner.keyword} />
                <DokumenSertifikat file={practitioner.license} />
                <SlotKosong />
            </div>
            <div className="p-3 shadow-card flex items-center justify-end">
                <button
                    className="bg-primary select-none text-white px-10 md:px-20 py-2 rounded-md mr-6"
                    onClick={openBookingModal}
                >
                    Booking Sesi
                </button>
            </div>
        </Modal>
    );
};
