import React from 'react';
import Chip from 'components/common/Chip';

interface Props {
    keywords: string[];
}

export const KataKunci = ({keywords}: Props): JSX.Element => {
    return (
        <div>
            <p className="text-[16px] md:text-lg font-semibold">Kata Kunci</p>
            <div className="flex flex-row mt-2 flex-wrap gap-3">
                {keywords.map((keyword) => (
                    <Chip
                        label={keyword.toLowerCase()}
                        textColor="text-navbar-accent"
                        border="border-navbar-accent border"
                        textSize='text-sm'
                        key={keyword}
                    />
                ))}
            </div>
        </div>
    );
};
