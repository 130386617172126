import {useState} from 'react';
import {FiChevronRight} from 'react-icons/fi';

const faqData = [
    {
        title: 'Bisakah saya konseling di perangkat lain?',
        description:
            'Bisa. Saat ruangan konseling sudah bisa dibuka, tap ikon rantai dan akan muncul tautan ruang konseling. Salin tautan untuk konseling ke perangkat lain yang kamu inginkan.',
    },
    {
        title: 'Apa perbedaan antara layanan Senior Psikolog, Psikolog, Konselor, dan Career Coach?',
        description:
            'Perbedaan mereka terletak pada bidang spesialisasi, pengalaman, dan harga. Kamu bisa melihat selengkapnya dengan mengetuk tulisan "bandingkan layanan & harga" berwarna biru di bawah keempat kotak opsi layanan atau tap di sini.',
    },
    {
        title: 'Bagaimana cara menemukan praktisi yang cocok dengan masalahku?',
        description:
            'Kamu dapat mencari praktisi yang tepat dengan cara mengetikkan topik konselingmu di bar pencarian. Teduh akan langsung menampilkan daftar praktisi yang mendalami bidang tersebut. Kamu juga dapat menggunakan fitur filter untuk memfilter praktisi berdasarkan topik populer dan jadwal yang sesuai dengan waktumu.',
    },
    {
        title: 'Apa yang akan aku dapatkan dari sesi konseling di Teduh?',
        description:
            'Kamu dapat menceritakan masalahmu dan praktisi kesehatan mental Teduh akan mencarikan solusinya bersama kamu. Perlu diingat, tidak semua masalah dapat selesai dalam sekali konseling, ya. Namun, kami berusaha memastikan kamu mengantongi strategi yang dapat kamu terapkan untuk menghadapi masalahmu sejak sesi pertamamu. Praktisi kami juga akan mengirimkan pesan pascasesi kepada kamu.',
    },
    {
        title: 'Apakah aku bisa membatalkan sesi yang sudah kubayar jika tiba-tiba berhalangan hadir?',
        description:
            'Bisa. Tinggal tap kartu "sesi mendatang" yang muncul di atas setelah kamu merencanakan sesi (atau lewat ikon histori jam di pojok kanan atas dan pilih sesi yang akan dibatalkan). Kamu akan dibawa ke halaman berisi detail sesimu. Lalu tap tombol dengan tiga titik di pojok kanan bawah […] dan pilih "Batalkan Sesi". Maksimal pembatalan adalah 12 jam sebelum sesi, ya. \n\nNamun, kami mengembalikan uangmu bukan dalam wujud tunai, melainkan dalam wujud satu token konseling yang dapat kamu gunakan untuk konseling di lain waktu, bahkan dengan praktisi lain dengan harga yang sama/lebih murah. Setelah melakukan pembatalan, akan muncul kartu bertuliskan "Sesi dengan [nama praktisi] dibatalkan. Tentukan sesi baru?" di bagian teratas halaman ini. Kamu dapat menentukan sesi barumu kapanpun dengan mengetuk kartu tersebut tanpa perlu membayar lagi.',
    },
    {
        title: 'Bagaimana jika setelah menentukan sesi aku berubah pikiran ingin mengganti waktu konseling atau mengganti praktisi Teduh untuk sesiku?',
        description:
            'Kamu bisa, dengan cara membatalkan sesi terlebih dahulu. Tinggal tap kartu "sesi mendatang" yang muncul di atas setelah kamu merencanakan sesi (atau lewat ikon histori jam di pojok kanan atas dan pilih sesi yang akan dibatalkan). Kamu akan dibawa ke halaman berisi detail sesimu. Lalu tap tombol dengan tiga titik di pojok kanan bawah […] dan pilih "Batalkan Sesi". Setelah membatalkan sesi, akan muncul kartu "Sesi dengan [nama praktisi] dibatalkan. Tentukan sesi baru?" di bagian teratas halaman ini. Kamu dapat menentukan sesi barumu dengan mengetuk kartu tersebut tanpa perlu membayar lagi.',
    },
    {
        title: 'Apakah yang terjadi jika di tengah sesi konseling internetku terputus?',
        description:
            'Kamu bisa masuk kembali ke ruang video call-mu. Jika praktisi yang menanganimu memiliki waktu, ia dapat memperpanjang sesi untuk mengganti waktumu yang hilang.',
    },
    {
        title: 'Bagaimana jika aku ada pertanyaan lain yang tidak tercantum di sini?',
        description: 'Kamu bisa menanyakannya ke kami lewat WhatsApp Customer Service di sini.',
    },
];

const FaqAccordion = () => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const toggleAccordion = (index: number) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <div className="space-y-2 ">
            {faqData.map((faq, index) => (
                <div key={index} className="border rounded-lg shadow-box p-3 ">
                    <button
                        onClick={() => toggleAccordion(index)}
                        className="flex justify-center items-center  text-left"
                    >
                        <p className="font-semibold">{faq.title}</p>
                    </button>
                    <div
                        className={`mt-2 transition-all duration-300 ease-in-out ${
                            activeIndex === index ? 'max-h-96 overflow-y-auto' : 'max-h-0 overflow-hidden'
                        }`}
                    >
                        <p>{faq.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export const SyaratKetentuan = (): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const [isOpen2, setIsOpen2] = useState(false);

    const toggleAccordion2 = () => {
        setIsOpen2(!isOpen2);
    };
    return (
        <div className="mt-5">
            <p className="font-semibold text-lg text-dark">Syarat dan Ketentuan</p>
            <div
                className="flex items-center h-12 rounded-lg shadow-box justify-between px-3 cursor-pointer"
                onClick={toggleAccordion}
            >
                <p className="text-base text-dark  border-searchbar">Syarat dan Ketentuan</p>
                <FiChevronRight
                    className={`text-dark w-5 h-5 transition-transform duration-300 transform ${
                        isOpen ? 'rotate-90' : 'rotate-0'
                    }`}
                />
            </div>

            <div
                className={`overflow-hidden transition-all ${
                    isOpen ? 'max-h-screen duration-700' : 'max-h-0 duration-300'
                }`}
            >
                <div className="p-2">
                    <div className="flex">
                        <p className="text-sm text-dark mr-2">1.</p>
                        <p className="text-sm text-dark">
                            Konseling di Teduh menggunakan sistem token. Artinya, pengguna membeli paket jumlah sesi
                            konseling terlebih dahulu dan setelah itu dibebaskan menggunakan jatah sesi yang telah
                            dibeli kapanpun.
                        </p>
                    </div>
                    <div className="flex">
                        <p className="text-sm text-dark mr-1">2.</p>
                        <p className="text-sm text-dark">
                            Pengguna Teduh dapat memilih psikolog/konselor yang sama ataupun berbeda-beda tiap sesi.
                        </p>
                    </div>
                    <div className="flex">
                        <p className="text-sm text-dark mr-1">3.</p>
                        <p className="text-sm text-dark">Satu sesi memiliki durasi 45 hingga 60 menit</p>
                    </div>
                </div>
            </div>
            <p className="font-semibold text-lg text-dark mt-3">Pertanyaan yang Sering Diajukan</p>

            <div
                className="flex items-center h-12 border-searchbar  rounded-lg shadow-box justify-between px-3 mb-2 cursor-pointer"
                onClick={toggleAccordion2}
            >
                <p className="text-base text-dark ">Pertanyaan yang Sering Diajukan</p>
                <FiChevronRight
                    className={`text-dark w-5 h-5 transition-transform duration-300 transform ${
                        isOpen2 ? 'rotate-90' : 'rotate-0'
                    }`}
                />
            </div>
            <div
                className={`overflow-hidden transition-all ${
                    isOpen2 ? 'max-h-screen w-[500px] overflow-visible duration-300' : 'max-h-0 duration-300'
                } mb-[500px]`}
            >
                <FaqAccordion />
            </div>
        </div>
    );
};
