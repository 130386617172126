import moment from "moment";
import { PractitionerCard } from "./practitioner/Card";
import { CounselingQuery } from "lib/react-query/query-hooks";
import useEmblaCarousel, {EmblaOptionsType} from 'embla-carousel-react';
import { Link } from "react-router-dom";
import { useState } from "react";
import { ICounseling } from "model/api";
import { PractitionProfileModal } from "components/profil/PractitionerProfileModal";
import { BookingSessionModal } from "components/session/BookingSessionModal";

interface Props {
  options?: EmblaOptionsType;
}

export const PractitionerList = ({options}: Props) => {
  const [emblaRef] = useEmblaCarousel(options);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openBookingModal, setOpenBookingModal] = useState<boolean>(false);
  const [selectedPractitioner, setSelectedPractitioner] = useState<ICounseling.Psychologist.Item>();

  const currentDateFilters = {
    schedule_gte: moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00Z',
    schedule_lte: moment(new Date()).format('YYYY-MM-DD') + 'T23:59:00Z',
    page: 1,
    limit: 10,
  };
  const {data, isLoading} = CounselingQuery.Psychologist(undefined, {
      ...currentDateFilters,
  });

  return (
    <>
      <div className="flex flex-col items-center justify-center px-10 py-20">
        <h1 className="font-semibold text-4xl mb-3">Butuh Konseling Segera?</h1>
        <p>Psikolog atau konselor yang bersedia membantumu hari ini 
          <Link to='/practitioners'>
            <a className="text-primary cursor-pointer"> Lihat Semua</a>
          </Link>
        </p>
        <div className="my-10 mt-20 w-full">
          <div className="embla" id="practitioner_list">
            <div className="embla__viewport" ref={emblaRef}>
              <div className="embla__container">
                {!isLoading && data?.data.map((practitioner, index) => (
                  <div className="embla__slide" key={index}>
                    <PractitionerCard practitioner={practitioner} setOpenModal={setOpenModal} setSelectedPractitioner={setSelectedPractitioner} />
                  </div>  
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModal && selectedPractitioner && (
          <PractitionProfileModal
              setOpen={setOpenModal}
              setOpenBookingModal={setOpenBookingModal}
              practitioner={selectedPractitioner}
          />
      )}
      {openBookingModal && selectedPractitioner && (
          <BookingSessionModal
              setOpen={setOpenBookingModal}
              setOpenProfile={setOpenModal}
              practitioner={selectedPractitioner}
          />
      )}
    </>
  );
};