import {Header} from 'components/konfirmasiPembayaran/Header';
import {Sesi} from 'components/konfirmasiPembayaran/Sesi';
import {SyaratKetentuanBox} from 'components/konfirmasiPembayaran/SyaratKetentuanBox';
import {SyaratKetentuan} from 'components/konfirmasiPembayaran/SyaratKetentuan';
import {HargaSesi} from 'components/konfirmasiPembayaran/HargaSesi';
// import {Promo} from 'components/konfirmasiPembayaran/Promo';
import {Total} from 'components/konfirmasiPembayaran/Total';
import {ButtonMetodePembayaran} from 'components/konfirmasiPembayaran/ButtonMetodePembayaran';
import {SesiMendatang} from 'components/konfirmasiPembayaran/SesiMendatang';
import {useEffect, useState} from 'react';
import {Template} from 'components/template/Template';
import {useLocation} from 'react-router-dom';
import {CounselingQuery} from 'lib/react-query/query-hooks';
import {useMediaQuery} from 'react-responsive';

export const KonfirmasiPembayaran = (): JSX.Element => {
    const location = useLocation();
    const [isBothChecked, setIsBothChecked] = useState(false);

    const {practitioner, selectedTime, selectedDate, selectedMedia} = location.state || {};

    const {data} = CounselingQuery.MethodPayment({}, {cost_id: selectedMedia.id});

    const handleBothCheckedChange = (isChecked: boolean) => {
        setIsBothChecked(isChecked);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const isMobile = useMediaQuery({maxWidth: 768});

    return (
        <Template>
            <div className="flex justify-center">
                <div>
                    <Header />
                    <div className="flex  flex-col lg:flex-row mx-8 gap-10">
                        <div className="overflow-visible flex-1 flex-col">
                            {' '}
                            {isMobile ? <HargaSesi practitioner={practitioner} selectedMedia={selectedMedia} /> : null}
                            <Sesi
                                practitioner={practitioner}
                                selectedTime={selectedTime}
                                selectedDate={selectedDate}
                                selectedMedia={selectedMedia.media_type}
                            />
                            {/* <Promo /> */}
                            {isMobile ? <Total total={selectedMedia.price} /> : null}
                            <SyaratKetentuanBox onBothCheckedChange={handleBothCheckedChange} />
                            {isMobile ? (
                                <ButtonMetodePembayaran
                                    payment={data}
                                    bothChecked={isBothChecked}
                                    practitioner={practitioner}
                                    selectedDate={selectedDate}
                                    selectedTime={selectedTime}
                                    selectedMedia={selectedMedia}
                                />
                            ) : null}
                            {isMobile ? <SesiMendatang /> : null}
                            <SyaratKetentuan />
                        </div>
                        <div className=" flex-1 flex-col">
                            {isMobile ? null : (
                                <div>
                                    {' '}
                                    <HargaSesi practitioner={practitioner} selectedMedia={selectedMedia} />{' '}
                                    <Total total={selectedMedia.price} />{' '}
                                </div>
                            )}
                            {/* <Promo /> */}

                            {isMobile ? null : (
                                <div>
                                    <ButtonMetodePembayaran
                                        payment={data}
                                        bothChecked={isBothChecked}
                                        practitioner={practitioner}
                                        selectedDate={selectedDate}
                                        selectedTime={selectedTime}
                                        selectedMedia={selectedMedia}
                                    />
                                    <SesiMendatang />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Template>
    );
};
