import React, {useState} from 'react';
import {PopupPilihMetode} from './PopupPilihMetode';
import {CounselingQuery, UserQuery} from 'lib/react-query/query-hooks';
import moment from 'moment';
import {useNavigate, useParams} from 'react-router-dom';
import {Button} from 'components/common/Button';
import Reactotron from 'reactotron-react-js';
import Spinner from 'assets/images/ZKZx.gif';

interface Props {
    bothChecked: boolean;
    payment?: any;
    selectedDate: any;
    selectedMedia: any;
    practitioner?: any;
    selectedTime: any;
}

export const ButtonMetodePembayaran: React.FC<Props> = ({
    payment,
    bothChecked,
    selectedDate,
    selectedMedia,
    practitioner,
    selectedTime,
}) => {
    const {discount_type}: any = useParams();
    const navigate = useNavigate();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [lakukanpembayaran, setLakukanPembayaran] = useState(false);
    const [session, setSession] = useState<any | null>(null);
    const [methodPayment, setMethodPayment] = useState<any | null>(null);

    const {data} = UserQuery.Profile({});
    const {mutate: createSession} = CounselingQuery.AddSession({
        onSuccess(res, session_data) {
            if (session_data.usecompanytoken) {
                navigate('/');
                Reactotron.log!(res, session_data);
            } else {
                setSession(res.data);
            }
        },
        onSettled(seesion_data, error, variables, context) {
            Reactotron.log!(seesion_data, error, variables, context);
        },
    });
    const {
        mutate: getVA,
        data: VA,
        isLoading: va_load,
        isError: em1,
    } = CounselingQuery.AddPayment({
        onSuccess(res) {
            const vaUrl = res.data.url;
            if (vaUrl) {
                window.open(vaUrl, '_self'); // Open the URL in a new tab
            }
            // navigate('/payment-success');
        },
    });

    const generateVa = () => {
        //  !!!url &&
        //      !!!trx_id &&

        const getVAData = {
            session_id: session?.id,
            cost_id: selectedMedia?._id,
            bankCode: methodPayment?.type == 'virtual-account' ? methodPayment?.value : undefined,
            eWalletCode: methodPayment?.type == 'e-wallet' ? methodPayment?.value : undefined,
            qris: methodPayment?.type == 'qris' ? 'qris' : undefined,
            card: methodPayment?.type == 'card' ? 'card' : undefined,
            type: methodPayment?.type,
            success_url: window.location.origin + '/payment/success/' + session?._id,
            failure_url: 'https://www.teduh.io/failure/' + session?._id,
        };

        getVA(getVAData);
        //  !!trx_id &&
        //      getNewVA({
        //          session_id: session?._id,
        //          cost_id: type?._id,
        //          bankCode: method?.type == 'virtual-account' ? method?.value : undefined,
        //          eWalletCode: method?.type == 'e-wallet' ? method?.value : undefined,
        //          qris: method?.type == 'qris' ? 'qris' : undefined,
        //          card: method?.type == 'card' ? 'card' : undefined,
        //          type: method?.type,
        //          success_url: 'https://www.teduh.io/success/' + session._id,
        //          failure_url: 'https://www.teduh.io/failure/' + session._id,
        //          trx_id,
        //      });
    };
    console.log('LOADINF VA', va_load);
    console.log(em1);
    console.log('SUCCES RESPONSE', VA);
    // console.log(data?.data.name);

    const on_create_session = (method: any) => {
        const formattedSelectedDate =
            moment(selectedDate).format('YYYY-MM-DD') + 'T' + moment(selectedTime, 'HH:mm').format('HH:mm:ss') + 'Z';

        // Convert the formatted date-time string to a Date object
        const scheduleDate = new Date(formattedSelectedDate);

        const sessionData = {
            schedule: scheduleDate,
            psychologist: practitioner._id,
            type: selectedMedia.media_type,
            client_name: !!data?.data.name ? data?.data.name : 'Anonim',
            method,
            usecompanytoken: discount_type,
        };
        setMethodPayment(method);
        createSession(sessionData);
    };

    const SpinnerComponent = () => (
        <div className="h-8 w-8">
            <img src={Spinner} alt="Loading" />
        </div>
    );

    const handleClick = () => {
        if (lakukanpembayaran) {
            generateVa();

            console.log('Performing lakukanpembayaran action...');
        } else if (bothChecked) {
            setIsPopupOpen(true);
        }
    };

    const handleGantiMetodePembayaranClick = () => {
        if (bothChecked) {
            setIsPopupOpen(true);
        }
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    return (
        <div>
            {discount_type == 'free' ? (
                <Button
                    className="bg-primary text-white py-3 px-4 rounded-md font-normal w-full mt-6"
                    label={'Booking Sesi'}
                    onClick={() => on_create_session(undefined)}
                />
            ) : (
                <div>
                    <div
                        className={`flex items-center justify-center rounded-lg mt-6 md:mt-3 p-3 cursor-pointer ${
                            bothChecked ? 'bg-primary cursor-pointer' : 'bg-searchbar cursor-not-allowed'
                        }`}
                        onClick={handleClick}
                    >
                        {va_load ? (
                            <SpinnerComponent /> // Render the Spinner component when isLoading is true
                        ) : (
                            <p className={`text-base ${bothChecked ? 'text-white' : 'text-darkgray'}`}>
                                {lakukanpembayaran ? 'Lanjutkan Pembayaran' : 'Pilih Metode Pembayaran'}
                            </p>
                        )}
                    </div>
                    {lakukanpembayaran && (
                        <div
                            className="flex items-center justify-center rounded-lg mt-3 p-3 cursor-pointer border border-primary"
                            onClick={handleGantiMetodePembayaranClick}
                        >
                            <p className="text-base text-primary">Ganti Metode Pembayaran</p>
                        </div>
                    )}
                </div>
            )}

            <PopupPilihMetode
                data={payment}
                isOpen={isPopupOpen}
                onClose={handleClosePopup}
                setLakukanPembayaran={setLakukanPembayaran}
                on_create_session={on_create_session}
            />
        </div>
    );
};
