import {useEffect, useState} from 'react';
import checklist from 'assets/images/checklist.svg';

interface Props {
    onBothCheckedChange: (isChecked: boolean) => void;
}
export const SyaratKetentuanBox: React.FC<Props> = ({onBothCheckedChange}) => {
    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);

    useEffect(() => {
        onBothCheckedChange(isChecked1 && isChecked2);
    }, [isChecked1, isChecked2, onBothCheckedChange]);

    const handleCheckbox1Change = () => {
        setIsChecked1(!isChecked1);
    };

    const handleCheckbox2Change = () => {
        setIsChecked2(!isChecked2);
    };

    return (
        <div className="md:mt-5">
            <p className="text-lg font-semibold text-dark">Centang Apabila Setuju:</p>
            <div className="mt-3">
                <label className="flex items-center cursor-pointer">
                    <input
                        type="checkbox"
                        checked={isChecked1}
                        onChange={handleCheckbox1Change}
                        className="hidden h-7 w-7"
                    />
                    <div className={`checkbox-custom w-5 h-5 ${isChecked1 ? '' : 'border-2 border-blue-500 rounded'}`}>
                        {isChecked1 && <img src={checklist} alt="" className="w-5 h-5" />}
                    </div>
                    <span className="ml-2 text-sm text-dark">Saya telah menyetujui Syarat & Ketentuan</span>
                </label>
            </div>
            <div className="mt-3">
                <label className="flex items-start cursor-pointer">
                    <div>
                        <input
                            type="checkbox"
                            checked={isChecked2}
                            onChange={handleCheckbox2Change}
                            className="hidden mr-2"
                        />
                    </div>

                    <div
                        className={`checkbox-custom mr-2 w-5 h-5 ${
                            isChecked2 ? '' : 'border-2 border-blue-500 rounded'
                        }`}
                    >
                        {isChecked2 && <img src={checklist} alt="" className="w-5 h-5" />}
                    </div>
                    <div className="flex flex-col">
                        <span className=" text-sm text-dark">Saya berusia lebih dari 18 tahun atau</span>
                        <span className=" text-sm text-dark">mendapat persetujuan orang tua apabila</span>
                        <span className=" text-sm text-dark">berusia kurang dari 18 tahun</span>
                    </div>
                </label>
            </div>
        </div>
    );
};
