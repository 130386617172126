import {Button} from 'components/common/Button';
import {Template} from 'components/template/Template';
import {FiAlertTriangle, FiShield} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';
import Company from 'assets/images/illustrations/Company.svg';

export const CompanyPage = () => {
    const navigate = useNavigate();
    const how_to = [
        {
            des: 'Klik “Tentukan Sesi”, lalu pilih Psikolog atau Konselor yang tersedia dalam daftar',
        },
        {
            des: 'Tentukan waktu konseling dengan media yang tersedia',
        },
        {
            des: 'Booking Sesi',
        },
    ];

    const description = [
        {
            icon: <FiShield size={32} />,
            title: 'Teduh menjaga rahasiamu',
            des: 'Hanya Praktisi Kesehatan Mental yang tahu ceritamu. Tempat kerjamu bahkan Teduh tidak akan tahu.',
        },
        {
            icon: <FiAlertTriangle size={32} />,
            title: 'Penawaran Terbatas',
            des: 'Penawaran ini terbatas dan hanya berlaku selama tempat kerjamu berlangganan layanan ini.',
        },
    ];

    return (
        <Template>
            <div className=" flex justify-center my-16">
                <text className=" font-semibold text-2xl  ">Teduh for Business</text>
            </div>
            <div className="content grid p-8 grid-cols-2 gap-4">
                <div className='col-span-1'>
                    <div className="flex justify-center">
                        <img src={Company} className=" -mt-12 mb-8" />
                    </div>
                    <text className=" font-normal text-md text-gray-600">
                        Institusimu memberimu manfaat konseling di Teduh. Selamat menikmati!
                    </text>
                    <div className=" my-4">
                        <text className=" font-semibold text-xl">Bagaimana cara kerjanya?</text>
                    </div>
                    {how_to.map((item, index) => {
                        return (
                            <div className="flex " key={index}>
                                <text className="font-normal text-md text-gray-600">{index + 1}. </text>
                                <text className="font-normal text-md text-gray-600">{item.des}</text>
                            </div>
                        );
                    })}
                    <Button
                        className="bg-primary text-white py-3 px-4 rounded-md font-normal w-full mt-6"
                        label={'Tentukan Sesi'}
                        onClick={() => navigate(`./practitioners`)}
                    />
                </div>
                <div className='col-span-1'>
                    {description.map((item, index) => {
                        return (
                            <div key={index} className=" flex flex-row mb-4">
                                {item.icon}
                                <div className="felx flex-col ml-2">
                                    <text className=" font-semibold text-xl">{item.title}</text>
                                    <div />
                                    <text className="font-normal text-md text-gray-600">{item.des}</text>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Template>
    );
};
