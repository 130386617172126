export const formatRp = (x: number | string, rupiah = true): string => {
  let number: number;
  if (typeof x == 'string') {
      number = parseInt(x);
  } else {
      number = x;
  }
  const fixedNumber = Math.round(number);
  const price = fixedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const data = rupiah ? 'Rp. ' + price : price;
  return data;
};