interface Props {
    label: string;
    onClick?: () => void;
    className: string;
}

export const Button = ({onClick, label, className}: Props) => {
    return (
        <button onClick={onClick} className={className}>
            {label}
        </button>
    );
};
