import Cloud from 'assets/images/cloud.png';

export const IconRiwayat = (): JSX.Element => {
    return (
        <div className="w-[770px] ml-[54px] h-[600px] flex flex-col items-center justify-center">
            <img src={Cloud} alt="" className="object-none mb-20" />
            <p className="text-2xl font-semibold">Klik salah satu untuk melihat detail riwayat</p>
        </div>
    );
};
