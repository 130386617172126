import selfHelpIllustration from 'assets/images/home/carousel/self-help-mockup.png';

export const SelfHelp = () => {
  return (
    <div className='h-full w-full flex items-center justify-center bg-self-help-carousel bg-no-repeat bg-cover rounded-5xl relative overflow-hidden'>
      <div className='flex items-center justify-between px-10 h-full w-[95%]'>
        <div>
          <h1 className='font-semibold text-4xl md:text-5xl pb-4 text-[#FC6D1D]'>Self-Help</h1>
          <p className='mb-5 w-full md:w-2/3 text-sm md:text-md'>Dengarkan dan praktikkan tips dari psikolog sesuai masalah yang kamu hadapi secara on-demand.</p>
          <button className='px-4 py-1.5 rounded-md font-medium text-white text-sm bg-[#FC6D1D]'>Lihat Selengkapnya</button>
        </div>
      </div>
      <img src={selfHelpIllustration} className='hidden md:block h-[50vw] 2xl:h-[40vw] absolute right-24 -bottom-[12em]' />
    </div>
  );
};