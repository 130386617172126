import meditasiIllustration from 'assets/images/home/carousel/meditasi-mockup.png';

export const Meditasi = () => {
  return (
    <div className='h-full w-full flex items-center justify-center bg-meditasi-carousel bg-no-repeat bg-cover rounded-5xl relative'>
      <div className='flex items-center justify-between px-10 h-full w-[95%]'>
        <div>
          <h1 className='font-semibold text-4xl md:text-5xl pb-4 text-[#E83166]'>Meditasi</h1>
          <p className='mb-5 w-full md:w-3/4 text-sm md:text-md'>Santai dan rileks dengan trek meditasi Teduh. Pilih trek yang sesuai kebutuhanmu.</p>
          <button className='px-4 py-1.5 rounded-md font-medium text-white text-sm bg-[#E83166]'>Lihat Selengkapnya</button>
        </div>
      </div>
      <img src={meditasiIllustration} className='hidden md:block w-[40vw] 2xl:w-[35vw] absolute -bottom-[11em] right-20' />
    </div>
  );
};