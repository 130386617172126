import {ICounseling} from 'model/api';
import {InformationCardExperience, InformationCardRating, InformationCardSpectialization} from './InformationCard';
import {PsychologTitle} from 'components/PsychologTitle';

interface Props {
    practitioner: ICounseling.Psychologist.Item;
}

export const PsikologProfileHeader = ({practitioner}: Props) => {
    return (
        <div className="flex gap-7 flex-col">
            <div className="flex gap-7">
                <div className="w-max">
                    <img
                        className="w-16 h-16 md:h-32 md:w-32 rounded-full"
                        src={
                            practitioner.profile_picture
                                ? practitioner.profile_picture
                                : require('../../assets/images/Profile_avatar_placeholder_large.png')
                        }
                        alt="pp"
                    />
                </div>
                <div className="flex flex-col gap-3">
                    <div className="flex flex-col-reverse md:flex-col gap-1 md:gap-3">
                        <div className="flex gap-2">
                            <PsychologTitle title={practitioner.title} />
                            {practitioner.secondary_title && <PsychologTitle title={practitioner.secondary_title} />}
                        </div>
                        <h1 className="font-semibold text-[18px] md:text-2xl">{practitioner.name_with_title}</h1>
                    </div>
                    <div className="mt-4 flex gap-6 hidden md:flex">
                        {practitioner.specialization && (
                            <InformationCardSpectialization specialization={practitioner.specialization} />
                        )}
                        {practitioner.experience && <InformationCardExperience experience={practitioner.experience} />}
                        {practitioner.rating && <InformationCardRating rating={practitioner.rating} />}
                    </div>
                </div>
            </div>
            <div className="mt-4 flex gap-6 md:hidden">
                {practitioner.specialization && (
                    <InformationCardSpectialization specialization={practitioner.specialization} />
                )}
                {practitioner.experience && <InformationCardExperience experience={practitioner.experience} />}
                {practitioner.rating && <InformationCardRating rating={practitioner.rating} />}
            </div>
        </div>
    );
};
