export const SlotKosong = (): JSX.Element => {
    return (
        <div>
            <p className="text-[16px] md:text-lg font-semibold">Slot kosong terdekat</p>
            <p className="text-sm mt-2 text-navbar-accent">Jumat, 22 Maret 2022; 09:00 </p>
            <p className="text-sm mt-2 text-navbar-accent">Jumat, 22 Maret 2022; 14:00</p>
            <p className="text-sm mt-2 text-navbar-accent">Senin, 25 Maret 2022; 10:00</p>
            <p className="text-sm mt-2 text-navbar-accent">Senin, 25 Maret 2022; 18:00 </p>
            <p className="text-sm mt-2 text-navbar-accent">Senin, 26 Maret 2022; 08.00</p>
        </div>
    );
};
