import {useState} from 'react';
import {FiArrowDown, FiMove, FiX} from 'react-icons/fi';
import ReactDragListView from 'react-drag-listview';
import Reactotron from 'reactotron-react-js';

interface Props {
    data?: any;
    setAnswears?: (data: any, answer: any) => void;
}

export const DRAG_DROP = ({data, setAnswears}: Props): JSX.Element => {
    const [chosen_item, setChosenItem] = useState<any>([]);
    const [choice_item, setChoiceItem] = useState<any>([
        {
            id: 1,
            title: 'a',
        },
        {
            id: 2,
            title: 'b',
        },
        {
            id: 3,
            title: 'c',
        },
        {
            id: 4,
            title: 'd',
        },
    ]);

    const handel_move_item = (item: any, action: 'move' | 'del') => {
        if (action === 'move') {
            const new_choice_item = choice_item.filter((i: any) => i.id !== item.id);
            setChoiceItem(new_choice_item);
            setChosenItem([...chosen_item, item]);
        }
        if (action === 'del') {
            const new_chosen_item = chosen_item.filter((i: any) => i.id !== item.id);
            const new_choice_item_sort = [...choice_item, item].sort((i1: any, i2: any) => {
                return i1.id - i2.id;
            });
            setChosenItem(new_chosen_item);
            setChoiceItem(new_choice_item_sort);
        }
        console.log(data, setAnswears);
    };

    const setIndex = async (data_item: any, from: number, to: number) => {
        const item = data_item.splice(from, 1)[0];
        data_item.splice(to, 0, item);
        setChosenItem([...data_item]);
        Reactotron.log!(data_item);
    };

    return (
        <div className=" flex flex-col justify-center items-center w-full">
            <div className="my-8">
                <text className=" font-medium text-md text-center">
                    Kampanye di wisma direncanakan terdiri atas beberapa acara, antara lain: a,b,c,d,e, urutkan tugas
                    tugas yang Anda pilih
                </text>
            </div>
            <div className=" sm:flex flex-col w-full">
                <div className=" p-4 border rounded-md mb-8">
                    {choice_item.map((i: any) => {
                        return (
                            <button
                                onClick={() => handel_move_item(i, 'move')}
                                className={`bg-white h-12 shadow-sm rounded-md flex flex-row items-center w-full border sm:hover:border-primary sm:hover:bg-primaryLightest text-sm px-4 my-2`}
                            >
                                <text className=" flex-1 text-left mr-2">{i.title}</text>
                                <FiArrowDown className=" stroke-primary ml-2" />
                            </button>
                        );
                    })}
                </div>
                <div className=" p-4 border rounded-md">
                    <ReactDragListView
                        handleSelector="a"
                        nodeSelector="li"
                        onDragEnd={(from, to) => setIndex(chosen_item, from, to)}
                    >
                        {chosen_item.map((i: any) => {
                            return (
                                <li key={i.id} className=" flex flex-row">
                                    <button
                                        onClick={() => handel_move_item(i, 'del')}
                                        className={`h-12 shadow-sm rounded-md flex flex-row items-center w-full border sm:hover:border-danger sm:hover:bg-dangerLight sm:hover:text-danger text-sm px-4 bg-white my-2`}
                                    >
                                        <text className=" flex-1 text-left mr-2">{i.title}</text>
                                        <FiX className=" stroke-danger ml-2 " />
                                    </button>
                                    <a className=" h-12 aspect-square border ml-2 my-2 rounded-md flex justify-center items-center bg-white">
                                        <FiMove className=" stroke-gray-600 " />
                                    </a>
                                </li>
                            );
                        })}
                    </ReactDragListView>
                </div>
            </div>
        </div>
    );
};
