import Footer from 'components/common/Footer';
import {NavigationBar} from 'components/common/NavigationBar';
import {WhatsAppAskButton} from 'components/common/WhatsAppAskButton';
interface Props {
    children: any;
}

export const Template = ({children}: Props) => {
    return (
        <div className="w-full">
            <NavigationBar status={localStorage.getItem('access_token') ? 'post-sign-in' : 'pre-sign-in'} />
            <div className=" flex w-full justify-center bg-white">
                <div className="content overflow-x-hidden max-w-1080p bg-white">{children}</div>
            </div>
            <Footer />
            <WhatsAppAskButton location="fixed bottom-4 right-4" />
        </div>
    );
};
