export const CatatanScreening = (): JSX.Element => {
    return (
        <div className="md:ml-[54px] w-[326px]">
            <h1 className="text-base font-semibold text-dark mb-3">Catatan dari Psikolog/Konselor</h1>
            <p className="text-navbar-accent text-sm font-normal mb-3">
                Kesehatan mental memiliki pengaruh besar terhadap produktivitas karyawan di tempat kerja. Adanya
                dukungan mental di tempat kerja dapat meningkatkan performa karyawan. Kesehatan mental memiliki pengaruh
                besar terhadap produktivitas karyawan di tempat kerja. Adanya dukungan mental di tempat kerja dapat
                meningkatkan performa.
            </p>
            <p className="text-base font-semibold text-dark mb-3">Screening 15 Maret 2022</p>
            <p className="text-sm text-navbar-accent mb-2">Nama Panggilan</p>
            <p className="text-sm text-dark mb-3">Nama</p>
            <p className="text-sm text-navbar-accent mb-2">Tempat Lahir</p>
            <p className="text-sm text-dark mb-3">Jogjakarta</p>
            <p className="text-sm text-navbar-accent mb-2">Tanggal Lahir</p>
            <p className="text-sm text-dark mb-3">22 September 1999 (22 tahun)</p>
            <p className="text-sm text-navbar-accent mb-2">Gender</p>
            <p className="text-sm text-dark mb-3">Laki laki</p>
            <p className="text-sm text-navbar-accent mb-2">Agama</p>
            <p className="text-sm text-dark mb-3">Lain-lain</p>
            <p className="text-sm text-navbar-accent mb-2">Lain-lain</p>
            <p className="text-sm text-dark mb-3">08XXXXXXXXXX</p>
            <p className="text-sm text-navbar-accent mb-2">Alamat Email</p>
            <p className="text-sm text-dark mb-3">alamatemail@email.com</p>
            <p className="text-sm text-navbar-accent mb-2">Alamat Rumah</p>
            <p className="text-sm text-dark mb-3">Alamat Rumah</p>
            <p className="text-sm text-navbar-accent mb-2">Ringkasan Masalah yang Dihadapi</p>
            <p className="text-sm text-dark mb-3">Lorem ipsum dolor sit amet</p>
            <p className="text-sm text-navbar-accent mb-2">Hasil yang Diharapkan dari Sesi Konseling</p>
            <p className="text-sm text-dark mb-3">Lorem ipsum dolor sit amet</p>
            <p className="text-sm text-navbar-accent mb-2">Catatan untuk Psikolog/Konselor</p>
            <p className="text-sm text-dark mb-3">Lorem ipsum dolor sit amet</p>
            <p className="text-sm text-navbar-accent mb-2">Dokumen tambahan</p>
            <p className="text-sm text-primary mb-3">Tautan dokumen</p>
        </div>
    );
};
