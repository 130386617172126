import { formatRp } from "helpers/FormatPrice";
import { ICounseling } from "model/api";
import { FiVideo } from "react-icons/fi";
import { FiPhone } from "react-icons/fi";

interface Props {
  data: ICounseling.Payment.Price.Item;
  selectedMedia: ICounseling.Payment.Price.Item | undefined;
  selectMedia: (media: ICounseling.Payment.Price.Item) => void;
}

export const MediaCard = ({data, selectedMedia, selectMedia}: Props) => {
  return (
    <div className={`border border-searchbar rounded-lg px-6 md:px-12 py-2 md:py-3 flex flex-row md:flex-col items-center gap-4 w-fit cursor-pointer select-none ${selectedMedia?.id === data.id && 'bg-primary text-white'} `} onClick={() => selectMedia(data)}>
      <div className="flex flex-row md:flex-col items-center gap-2 md:gap-0">
        <div className="bg-secondary p-2 rounded-full w-fit">
          {data.media_type === 'video' ? (
            <FiVideo className="text-primary text-lg" strokeWidth={2.5} />
          ) : (
            <FiPhone className="text-primary text-lg" strokeWidth={2.5} />
          )}
        </div>
        <p>{data.media_type === 'video' ? 'Video Call' : 'Voice Call'}</p>
      </div>
      <p className="font-semibold">{formatRp(data.price)}/sesi</p>
    </div>
  );
};