// import {RIASECTest} from './RIASECTest';
// import {RMIBTest} from './RMIBTest';
// import {DISCTest} from './DISCTest';
import R from 'assets/images/R.png';
import S from 'assets/images/S.png';
import tedoo_gitar from 'assets/images/tedoo_gitar.png';
import {Link} from 'react-router-dom';

function HomePsychologicalTests() {
    return (
        <div className="flex flex-col items-center pb-8 px-4">
            <div className=" max-w-6xl flex flex-col items-center">
                <div className=" w-full sm:mb-16 mt-8">
                    <text className=" font-semibold text-2xl sm:text-4xl text-left">Tes Psikologis Teduh</text>
                </div>

                <div className=" sm:flex">
                    <Link
                        to={'./riasec'}
                        className=" aspect-video max-w-md w-full flex flex-col justify-end  bg-card-1 bg-no-repeat bg-cover relative p-8 mt-16"
                    >
                        <img
                            src={R}
                            className=" aspect-square w-60 right-0 top-0 absolute -mt-24 -mr-8 sm:-mr-12 sm:-mt-32"
                        />
                        <text className=" font-normal text-gray-500">TES RIASEC</text>
                        <text className=" font-semibold text-left sm:text-2xl text-xl">
                            Ketahui Jalur Karier yang Cocok Untukmu
                        </text>
                    </Link>

                    <Link
                        to={'./disc'}
                        className=" aspect-video max-w-md w-full flex flex-col justify-end  bg-card-2 bg-no-repeat bg-cover relative p-8 mt-16 sm:mx-8"
                    >
                        <img
                            src={S}
                            className=" aspect-square w-60 right-0 top-0 absolute -mt-24 -mr-8 sm:-mr-12 sm:-mt-32"
                        />
                        <text className=" font-normal text-gray-500">TES DISC</text>
                        <text className=" font-semibold text-left sm:text-2xl text-xl">
                            Kenali Dirimu dan Kepribadianmu
                        </text>
                    </Link>

                    <Link
                        to={'./rmib'}
                        className=" aspect-video max-w-md w-full flex flex-col justify-end  bg-card-3 bg-no-repeat bg-cover relative p-8 mt-16"
                    >
                        <img
                            src={tedoo_gitar}
                            className=" aspect-square w-60 right-0 top-0 absolute -mt-24 -mr-8 sm:-mr-12 sm:-mt-32"
                        />
                        <text className=" font-normal text-gray-500">TES KESEHATAN MENTAL</text>
                        <text className=" font-semibold text-left sm:text-2xl text-xl">Kenali Minat dan Bakatmu</text>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default HomePsychologicalTests;
