import {IMain, IUser} from 'model/api';
import {UserService} from '../service/user';
import {UseMQOptions, UseRQOptions, useMQ, useRQ} from '../custom-hooks';
import {UserQueryKey} from '../query-key';

export function Login(options?: UseMQOptions<IUser.Profile.Success_Login, IUser.Profile.Login>) {
    return useMQ([UserQueryKey.LOGIN], UserService.Login, options);
}

export function Logout(options?: UseMQOptions<any, any>) {
    return useMQ([UserQueryKey.LOGOUT], UserService.Logout, options);
}

export function Profile(options?: UseRQOptions<IUser.Profile.Response>) {
    return useRQ([UserQueryKey.PROFILE], UserService.Profile, options);
}

export function EditProfile(options?: UseMQOptions<IUser.Profile.Response, IUser.Profile.Item>) {
    return useMQ([UserQueryKey.EDIT_PROFILE], UserService.EditProfile, options);
}

export function DeleteProfile(options?: UseMQOptions<any, any>) {
    return useMQ([UserQueryKey.DELETE_PROFILE], UserService.DeleteProfile, options);
}

export function Reminder(options?: UseRQOptions<IUser.Reminder.Response>) {
    return useRQ([UserQueryKey.REMINDER], UserService.Reminder, options);
}

export function EditReminder(options?: UseMQOptions<IUser.Reminder.Success_Edit, IUser.Reminder.Add_Or_Edit>) {
    return useMQ([UserQueryKey.EDIT_REMINDER], UserService.EditReminder, options);
}

export function AddSubscription(options?: UseMQOptions<IUser.Subscription.Success_Add, IUser.Subscription.Add>) {
    return useMQ([UserQueryKey.ADD_SUBSCRIPTION], UserService.AddSubscription, options);
}

export function EditLanguage(options?: UseMQOptions<IMain.Languages.Success_Edit, IMain.Languages.Edit>) {
    return useMQ([UserQueryKey.EDIT_LANGUAGE], UserService.EditLanguage, options);
}

export function Notification(options?: UseRQOptions<IUser.Notifications.Response>) {
    return useRQ([UserQueryKey.NOTIFICATION], UserService.Notification, options);
}
