import { Action } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import main from './slice/main';

const reducers = combineReducers({
  main,
});

const rootReducer = (state: any, action: Action) => {
  if (action.type === 'auth/logout') {
    state = undefined;
  }

  return reducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export type RootState = ReturnType<typeof reducers>;
export type AppDispatch = typeof store.dispatch;