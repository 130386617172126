import {createSlice} from '@reduxjs/toolkit';
import {ICounseling, IUser} from 'model/api';

const getTokenFromLocalStorage = localStorage.getItem('access_token');

const slice = createSlice({
    name: 'main',
    initialState: {
        user: undefined as IUser.Profile.Item | undefined,
        user_auth: undefined as any,
        token: getTokenFromLocalStorage ? getTokenFromLocalStorage : '',
        errorData: {},
        service_prices: undefined as ICounseling.Payment.Price.Item[] | undefined,
    },
    reducers: {
        setUser: (state, action) => {
            const oldData = state.user;
            state.user = {...oldData, ...action.payload};
        },
        setUserAuth: (state, action) => {
            state.user_auth = action.payload;
        },
        setToken: (state, action) => {
            const {data} = action.payload;
            localStorage.setItem('access_token', data.access_token);
            state.token = data.access_token;
        },
        setPrices: (state, action) => {
            const {data} = action.payload;
            state.service_prices = data;
        },
        setDataUndefined: state => {
            state.user = undefined;
            state.token = '';
            localStorage.removeItem('access_token');
        },
    },
});

export const {setUser, setDataUndefined, setToken, setPrices, setUserAuth} = slice.actions;

export default slice.reducer;
