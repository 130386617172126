export const GenerateTitle = (v: 'Senior Psychologist' | 'Psychologist' | 'Counselor' | 'Career Coach') => {
    const levels_translate = {
        'Senior Psychologist': 'Psikolog Senior',
        Psychologist: 'Psikolog',
        Counselor: 'Konselor',
        'Career Coach': 'Career Coach',
    };
    return levels_translate[v];
};

export const GenerateTitleEn = (v: 'Psikolog Senior' | 'Psikolog' | 'Konselor' | 'Career Coach') => {
    const levels_translate = {
        'Psikolog Senior': 'Senior Psychologist',
        Psikolog: 'Psychologist',
        Konselor: 'Counselor',
        'Career Coach': 'Career Coach',
    };
    return levels_translate[v];
};
