import toast from 'react-hot-toast';

export interface ErrorItem {
    message: string;
    error_code: string;
    status: number;
    error: string;
}

export const ErrorHandler = async (error?: any) => {
    if (!!error.error_code) {
        if (error.error_code !== 401) {
            toast.error('Error ' + error.status);
        }
    } else {
        try {
            const response = await error.response.json();
            const err_data: ErrorItem = response.response || response;
            if (err_data.status !== 401) {
                toast.error('Error ' + (err_data.error || err_data.message));
            }
        } catch (err) {
            toast.error('Error ' + error.message.replace('Request failed with status code ', ''));
        }
    }
};
