import StoreImage from "assets/images/install-logo.png";

export const Store = () => {
  return (
    <div className="relative md:w-1/2 mt-5">
      <img src={StoreImage} alt="Download Teduh" />
      <a className="absolute w-1/2 h-full left-0 top-0" href="https://play.google.com/store/apps/details?id=id.teduhio.app" target="_blank" rel="noreferrer"></a>
      <a className="absolute w-1/2 h-full right-0 top-0" href="https://apps.apple.com/id/app/teduh-konseling-meditasi/id1637005394" target="_blank" rel="noreferrer"></a>
    </div>
  );
}; 