import FirstScreening from "components/Screening/FirstScreening";
import SecondScreening from "components/Screening/SecondScreening";
import { Template } from "components/template/Template";
import { CounselingQuery } from "lib/react-query/query-hooks";
import { ICounseling } from "model/api";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

export const ScreeningPage = () => {
  const {sessionId} = useParams();
  const navigate = useNavigate();

  const [step, setStep] = useState<string>('first');

  const [screeningData, setScreeningData] = useState<ICounseling.Screening.Add>({
    id: sessionId as string,
    files: '',
    email: '',
    gender: '',
    religion: '',
    birthplace: '',
    birthdate: '',
    fullname: '',
    nickname: '',
    address: '',
    problem_description: '',
    expectation: '',
    notes_for_psychologist: ''
  });

  const handleChangeStep = (v: string) => {
    setStep(v);
    window.scrollTo(0, 0);
  };

  const {mutate: onSubmit} = CounselingQuery.AddScreening({
    onSuccess() {
      toast.success("Screening successfully sent!");
      navigate('/');
    },
    onError(error: any) {
      toast.error(error.message);
    }
  });

  const handleSubmit = () => {
    const payload = {
      ...screeningData,
      birthdate: new Date(screeningData.birthdate).toISOString().split('T')[0]
    };

    if (payload.problem_description !== "" && payload.problem_description.length <= 10000) {
      onSubmit(payload);
    } else {
      if (payload.problem_description === '') {
        toast.error("Problem description must be filled");
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Template>
      <div>
        {step === 'first' ? (
          <FirstScreening data={screeningData} setData={setScreeningData} setStep={handleChangeStep} />
        ) : (
          <SecondScreening data={screeningData} setData={setScreeningData} setStep={handleChangeStep} handleSubmit={handleSubmit} />
        )}
      </div>
    </Template>
  );
};