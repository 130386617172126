import { FiChevronLeft } from "react-icons/fi";

interface Props {
  show: boolean;
  setShow: (v: boolean) => void;
}

export const NavigationMenu = ({show, setShow, children}: React.PropsWithChildren<Props>) => {
  return (
    <div className={`fixed h-[100vh] w-[100vw] z-50 bg-white ${show ? 'translate-x-0' : '-translate-x-[100%]'} duration-300 ease-in-out px-10 py-20`}>
      <button
            className="border border-searchbar p-1 flex justify-center items-center rounded-md cursor-pointer select-none mb-10"
            onClick={() => setShow(false)}
        >
            <FiChevronLeft className="text-xl" strokeWidth={2} />
        </button>
        {children}
    </div>
  );
};