import GirlMeditate from '../../assets/images/b2b/girl.svg';
import News from '../../assets/images/b2b/news.svg';
import { FiChevronRight } from 'react-icons/fi';
import { translate } from 'i18n';
import { Markup } from 'interweave';
import { FiX } from 'react-icons/fi';

interface Props {
    isPopUpOpen: boolean;
    onClose: any;
}

export const ModalPopUp = ({isPopUpOpen, onClose} : Props) => {
    const phoneNumber = "6285219498199";
    const message = "Hello, I am (name) from (company's name). I visited Teduh for Business's website, and I am interested in learning more about Teduh's survey findings on employee mental health in Indonesia. May I obtain the survey's report?";
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;

    if (!isPopUpOpen) return null;

    return (
        <div>
            <div className="pointer-events-none fixed inset-0 bg-black opacity-50 z-[60] cursor-pointer"/>
            <div className="fixed inset-0 flex items-center justify-center z-[70] ">
                <div className='flex flex-col md:flex-row w-[85%] max-w-[976px] m-auto rounded-[24px] md:rounded-[50px] shadow-[0_0px_20px_0px_rgb(25,61,111,0.25)] bg-white overflow-hidden'>
                    <div className='sm:min-h-full overflow-hidden sm:w-full'>
                        <img src={GirlMeditate} className="sm:h-full w-full object-cover" />
                    </div>
                    <div className='p-5 md:px-8 md:py-5'>
                        <div className='flex justify-end' >
                            <button onClick={onClose}><FiX size={"48"} className='w-[40px] sm:w-[100px]'/></button>
                        </div>
                        <div className='flex gap-2 md:mb-6'>
                            <img src={News} alt="News"/>
                            <p className='text-[16px] md:text-2xl text-navbar-accent font-medium'>Report</p>
                        </div>
                        <h2 className='font-semibold text-[18px] md:text-2xl my-4'><Markup content={translate('b2b_resources_subtitle')}/></h2>
                        <p className='text-navbar-accent my-4 text-[14px] md:text-[20px] leading-[18px] md:leading-[30px]'>{translate('b2b_resources_desc')}</p>
                        <div className='flex justify-end'>
                        <button onClick={() => window.open(url)} className='py-3 rounded-md font-semibold text-navbar-accent text-[16px] md:text-xl text-bold flex items-center'>
                            <p>{translate('read_more_button')}</p>
                            <FiChevronRight className='w-[22px] ml-2 md:ml-8' />
                        </button>
                        </div>
                    </div>
                </div>
            </div>          
        </div>
    );
};