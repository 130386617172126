import ky from 'ky';

import {API_HOST} from '../../config/env';

import {authAfterResponseHooks, authBeforeResponseHooks} from './hooks';

const kyInstance = ky.create({
    headers: {
        'App-Verison': '2.3.7',
        'App-Type': 'web',
    },
});

const base = kyInstance.extend({
    prefixUrl: API_HOST,
    timeout: 6e4 * 4,
    hooks: {
        beforeRequest: [authBeforeResponseHooks],
        afterResponse: [authAfterResponseHooks],
    },
    retry: {
        limit: 1,
        methods: ['get', 'post', 'put', 'delete'],
        statusCodes: [400, 408, 413, 429, 500, 502, 503, 504, 404],
    },
});

const API = base.extend({});

export {API};
