import {MutationFunction, QueryFunction} from '@tanstack/react-query';
import {ErrorHandler} from 'helpers/ErrorHandler';
import {API} from 'lib/ky';
import {APIFile} from 'lib/ky/file';
import {ICounseling} from 'model/api';

const Psychologist: QueryFunction<ICounseling.Psychologist.Response> = async ({queryKey: [, filters]}) => {
    const data = filters as ICounseling.Filters;
    const sort = data.sort ? '&sort=' + data.sort : '';
    const schedule_gte = data.schedule_gte ? '&schedule[gte]=' + data.schedule_gte : '';
    const schedule_lte = data.schedule_lte ? '&schedule[lte]=' + data.schedule_lte : '';
    const name = !!data.name ? '&name[regex]=' + data.name : '';
    const topic = (data?.topic?.length || 0) > 0 ? '&keyword[all]=' + data?.topic?.join(',') : '';
    const title = !!data.title ? (data.title == 'Cari' ? '' : '&title=' + data.title) : '';
    const page = data.page ? '&limit=' + data.limit + '&page=' + data.page : '';
    const promo_code = data.promo_code ? '&promo_code=' + data.promo_code : '';
    const secondary_title = data.secondary_title ? '&secondary_title=' + data.secondary_title : '';
    try {
        const resp: ICounseling.Psychologist.Response = await API.get(
            'teleconsult/psychologist?&accountStatus=Active' +
                sort +
                schedule_gte +
                schedule_lte +
                name +
                topic +
                title +
                page +
                promo_code +
                secondary_title,
        ).json();
        return resp;
    } catch (error: any) {
        ErrorHandler(error);
        throw error;
    }
};

const Price: QueryFunction<ICounseling.Payment.Price.Response> = async () => {
    try {
        const resp: ICounseling.Payment.Price.Response = await API.get('teleconsult/cost').json();
        return resp;
    } catch (error: any) {
        ErrorHandler(error);
        throw error;
    }
};

const Payment: QueryFunction<ICounseling.Payment.Response> = async ({queryKey: [, filters]}) => {
    const data = filters as ICounseling.Filters;
    const sort = data.pending ? '&pending=' + data.pending : '';
    const page = data.page ? '&limit=' + data.limit + '&page=' + data.page : '';
    try {
        const resp: ICounseling.Payment.Response = await API.get(
            'teleconsult/payment/payment-history?' + sort + page,
        ).json();
        return resp;
    } catch (error: any) {
        ErrorHandler(error);
        throw error;
    }
};

const DetailPayment: QueryFunction<{data: ICounseling.Payment.Item}> = async ({queryKey: [, filters]}) => {
    const data = filters as {id: string};
    try {
        const resp: {data: ICounseling.Payment.Item} = await API.get(
            `teleconsult/payment/payment-history/${data.id}`,
        ).json();
        return resp;
    } catch (error: any) {
        ErrorHandler(error);
        throw error;
    }
};

const MethodPayment: QueryFunction<ICounseling.Payment.Method.Response> = async ({queryKey: [, filters]}) => {
    const data = filters as {cost_id: string};
    try {
        const resp: ICounseling.Payment.Method.Response = await API.get(
            'teleconsult/payment/payment-methods?cost_id=' + data.cost_id,
        ).json();
        return resp;
    } catch (error: any) {
        ErrorHandler(error);
        throw error;
    }
};

const AddPayment: MutationFunction<ICounseling.Payment.Success_Add, ICounseling.Payment.Add> = async (
    data: ICounseling.Payment.Add,
) => {
    try {
        const resp: ICounseling.Payment.Success_Add = await API.post('teleconsult/payment/create-payment', {
            json: data,
        }).json();
        return resp;
    } catch (error: any) {
        ErrorHandler(error);
        throw error;
    }
};

const UpdatePayment: MutationFunction<ICounseling.Payment.Success_Add, ICounseling.Payment.Add> = async (
    data: ICounseling.Payment.Add,
) => {
    try {
        const resp: ICounseling.Payment.Success_Add = await API.post(
            'teleconsult/payment/update-payment/' + data.trx_id,
            {
                json: data,
            },
        ).json();
        return resp;
    } catch (error: any) {
        ErrorHandler(error);
        throw error;
    }
};

const Session: QueryFunction<ICounseling.Session.Response> = async ({queryKey: [, filters]}) => {
    const data = filters as ICounseling.Filters;
    const is_psycholog = data.psycholog_id ? true : false;
    const sort = data.sort ? '&sort=' + data.sort : '';
    const schedule_gte = data.schedule_gte ? '&schedule[gte]=' + data.schedule_gte : '';
    const schedule_lte = data.schedule_lte ? '&schedule[lte]=' + data.schedule_lte : '';
    const status = data.status ? '&status[in]=' + data.status.join('&status[in]=') : '';
    const page = data.page ? '&limit=' + data.limit + '&page=' + data.page : '';
    const promo_code = data.promo_code ? '&promo_code=' + data.promo_code : '';
    try {
        const resp: ICounseling.Session.Response = await API.get(
            (is_psycholog
                ? 'teleconsult/psychologist/' + data.psycholog_id + '/session/lists?booked=true'
                : 'teleconsult/session/users?') +
                sort +
                schedule_gte +
                schedule_lte +
                status +
                page +
                promo_code,
        ).json();
        return resp;
    } catch (error: any) {
        ErrorHandler(error);
        throw error;
    }
};

const DetailSession: QueryFunction<{data: ICounseling.Session.Item}> = async ({queryKey: [, filters]}) => {
    const data = filters as {id: string};
    try {
        const resp: {data: ICounseling.Session.Item} = await API.get(`teleconsult/session/detail/${data.id}`).json();
        return resp;
    } catch (error: any) {
        ErrorHandler(error);
        throw error;
    }
};

const AddSession: MutationFunction<ICounseling.Session.Success_Add, ICounseling.Session.Add> = async (
    data: ICounseling.Session.Add,
) => {
    try {
        const resp = (await API.post('teleconsult/session/users', {
            json: {...data, method: undefined},
        }).json()) as ICounseling.Session.Success_Add;
        return resp;
    } catch (error: any) {
        ErrorHandler(error);
        throw error;
    }
};

const CancelSession: MutationFunction<ICounseling.Session.Success_Cancel, ICounseling.Session.Cancel> = async (
    data: ICounseling.Session.Cancel,
) => {
    try {
        const resp: ICounseling.Session.Success_Cancel = await API.post(`teleconsult/session/${data.id}/cancel`, {
            json: data,
        }).json();
        return resp;
    } catch (error: any) {
        ErrorHandler(error);
        throw error;
    }
};

const Discount_Code: MutationFunction<
    ICounseling.Discount_Code.Response,
    {code: string; price: number}
> = async (data: {code: string; price: number}) => {
    try {
        const resp: ICounseling.Discount_Code.Response = await API.get(
            `promo/calculate?promo_code=${data.code.toUpperCase()}&price=${data.price}`,
        ).json();
        return resp;
    } catch (error: any) {
        ErrorHandler(error);
        throw error;
    }
};

const AddReview: MutationFunction<ICounseling.Review.Success_Add, ICounseling.Review.Add> = async (
    data: ICounseling.Review.Add,
) => {
    try {
        const resp: ICounseling.Review.Success_Add = await API.post(`teleconsult/session/${data.session_id}/review`, {
            json: data,
        }).json();
        return resp;
    } catch (error: any) {
        ErrorHandler(error);
        throw error;
    }
};

const Review: QueryFunction<ICounseling.Review.Response> = async ({queryKey: [, filters]}) => {
    const data = filters as ICounseling.Filters;
    try {
        const resp: ICounseling.Review.Response = await API.get(
            `teleconsult/psychologist/${data.psycholog_id}/review?page=${data.page}&limit=${data.limit}&approved=true`,
        ).json();
        return resp;
    } catch (error: any) {
        ErrorHandler(error);
        throw error;
    }
};

const EndSession: MutationFunction<any, {id: string}> = async (data: {id: string}) => {
    try {
        const resp: any = await API.put(`teleconsult/session/${data.id}/endsession/user`).json();
        return resp;
    } catch (error: any) {
        ErrorHandler(error);
        throw error;
    }
};

const AddScreening: MutationFunction<ICounseling.Screening.Success_Add, ICounseling.Screening.Add> = async (
    data: ICounseling.Screening.Add,
) => {
    const formData = new FormData();
    const data_screening = data;
    for (const key in data_screening) {
        //@ts-ignore
        formData.append(key, data_screening[key]);
    }
    try {
        const resp: ICounseling.Screening.Success_Add = await APIFile.post(`teleconsult/session/${data.id}/screening`, {
            body: formData,
        }).json();
        return resp;
    } catch (error: any) {
        ErrorHandler(error);
        throw error;
    }
};

const Room: MutationFunction<{token: string}, {session_id: string; user_id: string}> = async (data: {
    session_id: string;
    user_id: string;
}) => {
    try {
        const resp: {token: string} = await API.post('teleconsult/session/join_room_user', {
            json: data,
        }).json();
        return resp;
    } catch (error: any) {
        ErrorHandler(error);
        throw error;
    }
};

export const CounselingService = {
    Psychologist,
    Price,
    Payment,
    DetailPayment,
    AddPayment,
    UpdatePayment,
    Session,
    DetailSession,
    CancelSession,
    EndSession,
    AddSession,
    Discount_Code,
    Review,
    AddReview,
    MethodPayment,
    AddScreening,
    Room,
};
