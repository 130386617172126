import {useEffect, useState, useCallback} from 'react';
import useEmblaCarousel, {EmblaOptionsType, EmblaCarouselType} from 'embla-carousel-react';
import {Konseling} from './landmark-carousel-data/Konseling';
import {TeduhForBusiness} from './landmark-carousel-data/TeduhForBusiness';
import {SelfHelp} from './landmark-carousel-data/SelfHelp';
import {Meditasi} from './landmark-carousel-data/Meditasi';
import {PemantauMood} from './landmark-carousel-data/PemantauMood';
import Autoplay, {AutoplayOptionsType} from 'embla-carousel-autoplay';

const carouselData = [
    {
        id: 1,
        Component: Konseling,
    },
    {
        id: 2,
        Component: TeduhForBusiness,
    },
    {
        id: 3,
        Component: SelfHelp,
    },
    {
        id: 4,
        Component: Meditasi,
    },
    {
        id: 5,
        Component: PemantauMood,
    },
];

interface Props {
    options?: EmblaOptionsType;
}

type CarouselPillProps = {
    selected: boolean;
    key: number;
    onClick: () => void;
};

const autoplayOption: AutoplayOptionsType = {
    delay: 7000,
    stopOnInteraction: false,
};

const CarouselPill = (props: CarouselPillProps) => {
    const {selected, key, onClick} = props;

    return (
        <button
            key={key}
            className={`${
                selected ? 'bg-primary h-3.5 w-10 rounded-full' : 'bg-sky-blue rounded-full h-3.5 w-3.5'
            } transition-all duration-[650ms]`}
            onClick={onClick}
        ></button>
    );
};

export const LandmarkCarousel = ({options}: Props) => {
    const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay(autoplayOption)]);

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

    const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

    const onInit = useCallback((emblaApiParams: EmblaCarouselType) => {
        setScrollSnaps(emblaApiParams.scrollSnapList());
    }, []);

    const onSelect = useCallback((emblaApiParams: EmblaCarouselType) => {
        setSelectedIndex(emblaApiParams.selectedScrollSnap());
    }, []);

    useEffect(() => {
        if (!emblaApi) return;

        onInit(emblaApi);
        onSelect(emblaApi);
        emblaApi.on('reInit', onInit);
        emblaApi.on('reInit', onSelect);
        emblaApi.on('select', onSelect);
    }, [emblaApi, onInit, onSelect]);
    return (
        <>
            <div className="embla py-4 h-[24rem] sm:h-full" id="landmark">
                <div className="embla__viewport" ref={emblaRef}>
                    <div className="embla__container">
                        {carouselData.map(carousel => {
                            const {id, Component} = carousel;
                            return (
                                <div className="embla__slide" key={id}>
                                    <div className=" w-full sm:h-full h-1/2 px-5">
                                        <Component />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="embla__pills" id="landmark">
                {scrollSnaps.map((_, index) => (
                    <CarouselPill key={index} onClick={() => scrollTo(index)} selected={index === selectedIndex} />
                ))}
            </div>
        </>
    );
};
