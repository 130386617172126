interface Props {
    label: string;
    icon: string;
    onClick: () => void;
    disable?: boolean;
}

export const SignInButton = ({label, icon, onClick, disable = false}: Props) => {
    return (
        <button
            disabled={disable}
            className="border border-primary p-2 rounded-xl text-primary flex items-center justify-center gap-3"
            onClick={onClick}
        >
            <img src={icon} alt="Google" className="h-6" />
            {label}
        </button>
    );
};
