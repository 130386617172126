import {LayananKonselingCard} from './LayananKonselingCard';
import PsikologSenior from 'assets/images/home/layanan-konseling/psikolog-senior.png';
import Psikolog from 'assets/images/home/layanan-konseling/psikolog.png';
import Konselor from 'assets/images/home/layanan-konseling/konselor.png';
import CareerCoach from 'assets/images/home/layanan-konseling/career-coach.png';
import EllipseOne from 'assets/images/illustrations/ellipse-1.svg';
import EllipseTwo from 'assets/images/illustrations/ellipse-2.svg';
import {Link} from 'react-router-dom';

const layananKonselingData = [
    {
        image: PsikologSenior,
        label: 'Psikolog Senior',
        link: '/practitioners',
    },
    {
        image: Psikolog,
        label: 'Psikolog',
        link: '/practitioners',
    },
    {
        image: Konselor,
        label: 'Konselor',
        link: '/practitioners',
    },
    {
        image: CareerCoach,
        label: 'Career Coach',
        link: '/practitioners',
    },
];

export const LayananKonseling = () => {
    return (
        <div className="md:bg-secondary py-10 md:py-32 md:px-20 md:m-7 md:rounded-xl flex flex-col md:flex-row items-center justify-around relative">
            <div className="w-full md:w-2/5 z-10 px-10 md:px-0 text-center md:text-left mb-4 md:mb-0">
                <h1 className="text-dark text-xl md:text-5xl leading-10 font-semibold md:mb-3">
                    Pilih Layanan Konseling
                </h1>
                <Link to="/layanan">
                    <p className="text-primary text-lg">Bandingkan Layanan & Harga</p>
                </Link>
            </div>
            <div className="grid grid-cols-2 gap-3 px-3 md:px-0 z-10">
                {layananKonselingData.map(data => (
                    <LayananKonselingCard image={data.image} label={data.label} link={data.link} />
                ))}
            </div>
            <img
                src={EllipseOne}
                alt="Illustration"
                className="absolute z-1 w-1/2 md:w-fit -right-[25%] top-10 md:right-2 md:top-4"
            />
            <img
                src={EllipseTwo}
                alt="Illustration"
                className="absolute z-1 -left-[15%] -bottom-10 md:left-[24%] md:bottom-16"
            />
        </div>
    );
};
