import {useEffect, useState} from 'react';
import FilterTags from './FilterTags';
import Layanan from './Layanan';
import {Link} from 'react-router-dom';
import {Button} from 'components/common/Button';
import {ICounseling} from 'model/api';
import Time from './Time';
import {SearchBar} from 'components/common/SearchBar';
import {ResponsiveModal} from 'components/PractitionersList/ResponsiveModal';
import { BackButton } from 'components/common/BackButton';

interface Props {
    setFilter: (data: ICounseling.Filters) => void;
    filters: ICounseling.Filters;
}

const Filter = ({setFilter, filters}: Props): JSX.Element => {
    const [all_filters, setAllFilters] = useState<ICounseling.Filters>(filters);
    const [isOpenFilter, setIsOpenFilter] = useState<Boolean>(false);

    const handleOpen = () => {
        setIsOpenFilter(true);
    };

    const handleClose = () => {
        setIsOpenFilter(false);
    };

    useEffect(() => {
        setFilter(all_filters);
    }, [all_filters]);

    return (
        <div className="md:mr-10 flex-col flex-1">
            <div className="mb-8">
                <div className="flex flex-col gap-3 mb-8 md:hidden">
                    <p className="text-xl text-center font-semibold self-center">Pilih Praktisi</p>
                    <Link to="/layanan">
                        <p className="text-primary text-center font-regular self-center">Bandingkan Layanan & Harga</p>
                    </Link>
                </div>
                <SearchBar
                    onChange={t => setAllFilters({...all_filters, name: t})}
                    open={handleOpen}
                    placeholder="Cari psikolog atau topik konseling"
                    className="
                    bg-gray-200 border py-1.5 px-2 pl-8 w-full rounded-md outline-0 border-searchbar placeholder:text-gray-800 text-sm
                    "
                />
            </div>
            <ResponsiveModal openModal={isOpenFilter} close={handleClose}>
                <div className="relative p-4 shadow-card flex items-center justify-center md:hidden">
                    <BackButton setOpen={setIsOpenFilter} />
                    <h3 className="font-semibold">Filter</h3>
                </div>
                <div className="p-6 flex flex-col gap-7 overflow-y-auto h-inherit">
                    <FilterTags data={all_filters.topic || []} setData={t => setAllFilters({...all_filters, topic: t})} />
                    <Time
                        setDate={t => setAllFilters({...all_filters, schedule_gte: t.toString(), schedule_lte: t.toString()})}
                    />
                    <Layanan
                        data={all_filters.title || all_filters.secondary_title || ''}
                        setData={t =>
                            setAllFilters({
                                ...all_filters,
                                title: t == 'Career Coach' ? '' : t,
                                secondary_title: t == 'Career Coach' ? 'Career Coach' : '',
                            })
                        }
                    />
                    <div className="mt-8">
                        <Button
                            className="bg-white border-primary border text-primary py-2 px-4 rounded-md font-normal w-full mt-2 "
                            label={'Reset'}
                            onClick={() => {
                                setAllFilters({title: all_filters.title, secondary_title: all_filters.secondary_title});
                            }}
                        />
                    </div>
                </div>
            </ResponsiveModal>
        </div>
    );
};

export default Filter;
