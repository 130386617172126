import {WhatsAppAskButton} from 'components/common/WhatsAppAskButton';
import Illustration from 'assets/images/signin/illustration.svg';
import {Signin} from 'components/signin/Signin';
import {Navigate, useNavigate} from 'react-router-dom';
import {UserQuery} from 'lib/react-query/query-hooks';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useAppSelector} from 'hooks/useAppSelector';
import {setToken, setUser, setUserAuth} from 'app/slice/main';

import GoogleIcon from 'assets/images/google-icon.svg';
import {SigninNavBar} from 'components/signin/SigninNavBar';
import {useGoogleLogin} from '@react-oauth/google';
import ky from 'ky';
import {NAME} from 'config/env';

export const SigninPage = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {user, token} = useAppSelector(s => s.main);

    const {refetch: getProfile} = UserQuery.Profile({
        onSuccess(data) {
            dispatch(setUser(data.data));
            navigate('/');
        },
        enabled: false,
    });

    const {mutate: onLogin} = UserQuery.Login({
        onSuccess(data) {
            dispatch(setToken(data));
            getProfile();
        },
        onError() {
            dispatch(setUserAuth(undefined));
        },
    });

    const GoogleSignIn = useGoogleLogin({
        onSuccess: async tokenResponse => {
            const userInfo: {
                email: string;
                email_verified: boolean;
                family_name: string;
                given_name: string;
                locale: string;
                name: string;
                picture: string;
                sub: string;
            } = await ky
                .get('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: {Authorization: 'Bearer ' + tokenResponse.access_token},
                })
                .json();
            dispatch(setUserAuth(userInfo));
            onLogin({
                email: userInfo?.email || '',
                auth_id: {
                    google: userInfo?.sub || '',
                },
                companyId: null,
                device_id: 'web-apps',
            });
        },
        onError: errorResponse => console.log(errorResponse),
    });

    // const AppleSignIn = async (res: any) => {
    //     console.log('apl', res);
    // };

    // const MicrosoftSignIn = async (res: any, err: any) => {
    //     console.log('ms', res, err);
    // };

    const signInMethod = [
        {
            name: 'Google',
            icon: GoogleIcon,
            onclick: () => GoogleSignIn(),
        },
        // {
        //     name: 'Apple',
        //     icon: GoogleIcon,
        //     onclick: (data: any) => AppleSignIn(data),
        // },
        // {
        //     name: 'Microsoft',
        //     icon: GoogleIcon,
        //     onclick: (data: any, err: any) => MicrosoftSignIn(data, err),
        // },
        
        NAME == 'staging' || NAME == 'uat'
            ? {
                  name: 'Test Account',
                  icon: GoogleIcon,
                  onclick: () =>
                      onLogin({
                          email: 'sandi08111998@gmail.com',
                          auth_id: {
                              google: '104344359032710832076',
                          },
                          companyId: null,
                          device_id: 'F8C141E8-C9B1-4EA5-B1C4-01356B108FBF',
                      }),
              }
            : null,
            NAME == 'staging' || NAME == 'uat'
            ? {
                  name: 'Test Account QA',
                  icon: GoogleIcon,
                  onclick: () =>
                      onLogin({
                        email: 'yanhizbar.r@mail.ugm.ac.id',
                          auth_id: {
                              google: '115218713822194043561',
                          },
                          companyId: null,
                          device_id: '978c69d4a31c8819',
                      }),
              }
            : null,
    ];

    if (user || token) {
        return <Navigate to="/" />;
    }

    return (
        <div className="h-screen w-screen">
            <SigninNavBar />
            <div className="h-full flex flex-col md:flex-row">
                <div className="left h-full flex items-center pt-24 pb-16 md:p-0 md:w-1/2 bg-signin-background bg-no-repeat bg-cover">
                    <Signin methods={signInMethod} />
                    <WhatsAppAskButton location="absolute bottom-5 left-5" />
                </div>
                <div className="right h-full flex items-center justify-center md:w-1/2 flex-col pt-12 pb-24 md:p-0">
                    <img src={Illustration} alt="Illustration" className="w-4/5 mb-5" />
                    <div className="text-center">
                        <h1 className="text-primary font-semibold text-2xl sm:text-3xl mb-5">Konseling Daring Teduh</h1>
                        <p className="w-3/4 font-medium mx-auto text-sm">
                            Ceritakan masalahmu dan gali solusinya bersama praktisi kesehatan mental Teduh. Pesan sessi
                            konseling semudah pesan tiket liburan. #BeneranHealing
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
