interface Props {
  value: string;
  selectedTime: string;
  availableTime: string[];
  selectedDate: string;
  bookedSchedule: {
    isoDate: string;
    time: string;
  }[] | undefined;
  selectTime: (time: string) => void;
}

export const TimeComponent = ({value, availableTime, selectedTime, selectedDate, bookedSchedule, selectTime}: Props) => {
  return (
    <button 
      className={`border border-searchbar py-1 px-3 rounded-lg w-fit cursor-pointer select-none ${!availableTime && 'bg-searchbar text-tertiary pointer-events-none'} ${availableTime && !availableTime.includes(value) && 'bg-searchbar text-tertiary pointer-events-none'} ${value === selectedTime && availableTime.includes(value) ? 'bg-primary text-white' : ''} ${ bookedSchedule?.find((schedule) => schedule.isoDate === selectedDate && schedule.time === value) && 'bg-searchbar text-tertiary pointer-events-none'}`}
      onClick={() => selectTime(value)}
    >
      <p>{value}</p>
    </button>
  );
};