import { ChartCard } from "./ChartCard";
import Percent25 from "../../assets/images/b2b/25.svg";
import Percent35 from "../../assets/images/b2b/35.svg";
import Percent40 from "../../assets/images/b2b/40.svg";
import SmallCircle from "../../assets/images/b2b/small-circle.svg";
import BigCircle from "../../assets/images/b2b/big-circle.svg";
import { translate } from "i18n";
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export const Company = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, 
  });

  const phoneNumber = "6285219498199";
  const message = "Hello, I am (name) from (company's name). I visited Teduh for Business's website, and I am interested in learning more about Teduh's survey findings on employee mental health in Indonesia. May I obtain the survey's report?";
  const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;

  return (
    <div className='flex flex-col justify-center items-center relative'>
        <h1 className='font-semibold text-2xl text-center sm:text-4xl md:text-5xl pb-4 px-3 text-black'>{translate('b2b_face_challenges_title')}</h1>
        <hr className="w-[60px] h-[10px] mx-auto mt-4 mb-12 bg-primary border-0 rounded"/>
        <img src={SmallCircle} className='absolute -z-10 bottom-[5%] left-[20%] -translate-x-[50%]'/>
        <img src={BigCircle} className='absolute -z-10 top-[20%] left-[80%] -translate-x-[50%]'/>
        <motion.div 
          ref={ref}
          initial={{ opacity: 0, y: -150 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.8 }}
          className='grid gap-[20px] xl:my-[100px] grid-cols-2 lg:grid-cols-3'>
          <ChartCard image={Percent25} description={translate('b2b_challenge_1')} link={url} css=''/>
          <ChartCard image={Percent35} description={translate('b2b_challenge_2')} link={url} css=''/>
          <div className="col-span-2 lg:col-span-1 justify-self-center">
          <ChartCard image={Percent40} description={translate('b2b_challenge_3')} link={url} css=''/>
          </div>
        </motion.div>
    </div>
  );
};