import { FiStar } from "react-icons/fi";

interface Props {
  content: string;
  author: string;
  image: string;
}

export const ReviewCard = ({content, author, image}: Props) => {
  return (
    <div className="rounded-lg shadow-box py-7 px-10 bg-white w-fit h-[13.5em] mr-7 flex flex-col justify-between select-none">
      <div className="flex items-center gap-4 mb-2">
        <img src={image} className="w-12 h-12 object-cover rounded-full" />
        <p className="font-bold">{author}</p>
      </div>
      <p className="w-[17em]">{content}</p>
      <div className="flex gap-2.5 mt-2">
        <FiStar className="text-yellow text-xl" fill="#FFD66D" />
        <FiStar className="text-yellow text-xl" fill="#FFD66D" />
        <FiStar className="text-yellow text-xl" fill="#FFD66D" />
        <FiStar className="text-yellow text-xl" fill="#FFD66D" />
        <FiStar className="text-yellow text-xl" fill="#FFD66D" />
      </div>
    </div>
  );
};