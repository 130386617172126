import {useAppSelector} from 'hooks/useAppSelector';
import {ICounseling} from 'model/api';
import {FiStar} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';

interface Props {
    practitioner: ICounseling.Psychologist.Item;
    setOpenModal: (open: boolean) => void;
    setSelectedPractitioner: (practitioner: ICounseling.Psychologist.Item) => void;
}

type Day = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export const PractitionerCard = ({practitioner, setOpenModal, setSelectedPractitioner}: Props) => {
    const {user} = useAppSelector(s => s.main);
    const navigate = useNavigate();
    const translateDayName = (availableDays: ICounseling.Psychologist.Item['availableDays']) => {
        const hashDay = {
            sunday: 'Minggu',
            monday: 'Senin',
            tuesday: 'Selasa',
            wednesday: 'Rabu',
            thursday: 'Kamis',
            friday: 'Jumat',
            saturday: 'Sabtu',
        };
        const days = [];

        for (const key in availableDays) {
            if (availableDays[key as Day] && availableDays[key as Day].length > 0) {
                days.push(hashDay[key as Day]);
            }
        }

        return days.join(', ');
    };

    const handleSelectPractitioner = (open: boolean, practitionerData: ICounseling.Psychologist.Item) => {
        if (!!!user) {
            navigate('/signin');
        } else {
            setOpenModal(open);
            setSelectedPractitioner(practitionerData);
        }
    };

    return (
        <div
            className="w-[18em] sm:w-[22em] h-[23em] rounded-xl shadow-box cursor-pointer"
            onClick={() => handleSelectPractitioner(true, practitioner)}
        >
            <div className="h-2/3">
                <img
                    className="h-full w-full object-cover rounded-t-xl"
                    src={
                        practitioner.profile_picture
                            ? practitioner.profile_picture
                            : require('../../../assets/images/Profile_avatar_placeholder_large.png')
                    }
                    alt="pp"
                />
            </div>
            <div className="h-1/3 p-3 flex flex-col justify-between">
                <h5 className="font-semibold text-dark">{practitioner.name_with_title}</h5>
                <div className="text-navbar-accent text-sm">
                    <p className="truncate">Topik: {practitioner.keyword.join(', ')}</p>
                    <p className="truncate">Jadwal: {translateDayName(practitioner.availableDays)}</p>
                </div>
                <p>
                    <FiStar className="text-yellow text-xl" fill="#FFD66D" /> {practitioner.rating}
                </p>
            </div>
        </div>
    );
};
