import {PsychologTitle} from 'components/PsychologTitle';
import moment from 'moment';
import {FiCalendar, FiClock, FiPhone, FiVideo} from 'react-icons/fi';

interface riwayatProps {
    data?: any;
    onItemClick: (index: number) => void;
}

export const ProfilRiwayatSesi: React.FC<riwayatProps> = ({data, onItemClick}) => {
    const formatDate = (dateString: string): string => {
        const date = moment(dateString);
        return date.format('dddd, D MMMM YYYY');
    };
    return (
        <div>
            {data?.map((appointment: any, index: number) => (
                <div
                    key={index}
                    onClick={() => onItemClick(index)}
                    style={{width: '326px'}}
                    className="px-4 py-4 md:mr-8 mb-3 rounded-lg shadow-box border border-searchbar cursor-pointer"
                >
                    <div className="flex">
                        <div className="rounded-full overflow-hidden mr-3" style={{width: '72px', height: '72px'}}>
                            <img
                                src={appointment.psychologist.profile_picture}
                                className="w-full h-full object-cover"
                                alt=""
                            />
                        </div>
                        <div>
                            <p className="font-semibold text-dark">{appointment.psychologist.name_with_title}</p>
                            <div className="flex gap-2">
                                <PsychologTitle title={appointment.psychologist?.title} />
                                {appointment.psychologist.secondary_title && (
                                    <PsychologTitle title={appointment.psychologist.secondary_title} />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="flex items-center mb-3">
                            <div className="bg-secondary rounded-full w-7 h-7 flex items-center justify-center ">
                                <FiCalendar className="text-primary text-xl" />
                            </div>
                            <p className="text-sm text-navbar-accent ml-2">{formatDate(appointment.schedule)}</p>
                        </div>
                        <div className="flex items-center mb-3">
                            <div className="bg-secondary rounded-full w-7 h-7 flex items-center justify-center ">
                                <FiClock className="text-primary text-xl " />
                            </div>
                            <p className="text-sm text-navbar-accent ml-2">
                                {appointment.hour}.00-{appointment.hour + 1}.00
                            </p>
                        </div>
                        <div className="flex items-center ">
                            <div className="bg-secondary rounded-full w-7 h-7 flex items-center justify-center ">
                                {appointment.type === 'video' ? (
                                    <FiVideo className="text-primary text-xl" />
                                ) : (
                                    <FiPhone className="text-primary text-xl" />
                                )}
                            </div>
                            <p className="text-sm text-navbar-accent ml-2">{appointment.type} call</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
