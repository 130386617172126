import {useEffect, useState} from 'react';
import {Modal} from 'components/common/Modal';
import {FiChevronLeft} from 'react-icons/fi';
import {PsikologProfileHeader} from 'components/profil/PsikologProfileHeader';
import {SessionInformation} from './common/SessionInformation';
import {MediaCard} from './common/MediaCard';
import {DateComponent} from './common/DateComponent';
import {TimeComponent} from './common/TimeComponent';
import {ICounseling} from 'model/api';
import {CompareDate, addTime, findClosestDate, findClosestTime} from 'helpers/DateFunctions';
import {CounselingQuery} from 'lib/react-query/query-hooks';
import {setPrices} from 'app/slice/main';
import {useAppSelector} from 'hooks/useAppSelector';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useNavigate, useParams} from 'react-router-dom';
import Reactotron from 'reactotron-react-js';

export type DateObject = {
    isoDate: string;
    day: string;
    date: number;
    dayFull: string;
};

type Day = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

interface Props {
    practitioner: ICounseling.Psychologist.Item;
    setOpen: (open: boolean) => void;
    setOpenProfile: (open: boolean) => void;
}

type BackButtonType = {
    handleClose: () => void;
};

const BackButton = ({handleClose}: BackButtonType) => {
    return (
        <button
            className="border border-searchbar p-1 flex justify-center items-center absolute rounded-md left-8 cursor-pointer select-none"
            onClick={handleClose}
        >
            <FiChevronLeft className="text-xl" strokeWidth={2} />
        </button>
    );
};

export const BookingSessionModal = ({practitioner, setOpen, setOpenProfile}: Props) => {
    const {discount_type} = useParams();
    Reactotron.log!(discount_type);
    const hours = [
        '00.00',
        '01.00',
        '02.00',
        '03.00',
        '04.00',
        '05.00',
        '06.00',
        '07.00',
        '08.00',
        '09.00',
        '10.00',
        '11.00',
        '12.00',
        '13.00',
        '14.00',
        '15.00',
        '16.00',
        '17.00',
        '18.00',
        '19.00',
        '20.00',
        '21.00',
        '22.00',
        '23.00',
    ];
    const daysOfWeek = ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'];
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const availableDays = practitioner.availableDays;
    const exceptionsSchedules = practitioner.exception_schedules;
    const {user} = useAppSelector(s => s.main);
    const dispatch = useAppDispatch();
    const [selectedTime, setSelectedTime] = useState<string>(findClosestTime(new Date(), hours));
    const [dates, setDates] = useState<DateObject[]>([]);
    const [selectedDate, setSelectedDate] = useState<DateObject>({
        isoDate: new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString(),
        day: daysOfWeek[new Date().getDay()],
        dayFull: days[new Date().getDay()],
        date: new Date().getDate(),
    });
    const [bookedSchedule, setBookedSchedule] = useState<{
        isoDate: string,
        time: string
    }[]>();

    const [selectedMedia, setSelectedMedia] = useState<ICounseling.Payment.Price.Item>();
    const [isNotComplete, setIsNotComplete] = useState<boolean>(true);
    const navigate = useNavigate();

    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 768);
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const {data: sessions_data, isLoading: session_load} = CounselingQuery.Session(
        {
            enabled: !!user ? true : false,
        },
        {
            sort: 'schedule',
            status: ['paid', 'paid-admin', 'ongoing'],
            psycholog_id: practitioner._id
        },
    );

    const getBookedSchedule = (sessions: ICounseling.Session.Item[]) => {
        const bookedScheduleData = sessions.map((session) => {
            const schedule = session.schedule;
            let [isoDate, time] = schedule.split('T');
            isoDate = new Date(new Date(isoDate).setUTCHours(0, 0, 0, 0)).toISOString();
            time = time.slice(0, 2) + '.00';
            return {
                isoDate,
                time
            };
        });

        return bookedScheduleData;
    };

    useEffect(() => {
        if (sessions_data && !session_load) {
            setBookedSchedule(getBookedSchedule(sessions_data.data));
        }
    }, [session_load]);

    useEffect(() => {
        setSelectedDate({
            isoDate: findClosestDate(getDates(isMobile ? 7 : 10), availableDays).toISOString(),
            day: daysOfWeek[findClosestDate(getDates(isMobile ? 7 : 10), availableDays).getDay()],
            dayFull: days[findClosestDate(getDates(isMobile ? 7 : 10), availableDays).getDay()],
            date: findClosestDate(getDates(isMobile ? 7 : 10), availableDays).getDate(),
        });
        setDates(getDates(isMobile ? 7 : 10));
    }, [isMobile]);

    const {data: prices_data, isLoading: prices_load} = CounselingQuery.Price({
        onSuccess(data) {
            dispatch(setPrices(data.data));
        },
        enabled: !!user ? true : false,
    });

    const getDates = (count: number) => {
        const dateList = [];
        const today = new Date();

        for (let i = 0; i < count; i++) {
            const currentDate = new Date(today);
            currentDate.setDate(today.getDate() + i);
            const day = daysOfWeek[currentDate.getDay()];
            if (day) {
                const dateInfo = {
                    isoDate: new Date(currentDate.setUTCHours(0, 0, 0, 0)).toISOString(),
                    day: daysOfWeek[currentDate.getDay()],
                    dayFull: days[currentDate.getDay()],
                    date: currentDate.getDate(),
                };
                dateList.push(dateInfo);
            }
        }

        return dateList;
    };

    const selectDate = (dateObject: DateObject) => {
        setSelectedDate(dateObject);
        setSelectedTime('');
    };

    const selectTime = (time: string) => {
        setSelectedTime(time);
    };

    const selectMedia = (media: ICounseling.Payment.Price.Item) => {
        setSelectedMedia(media);
    };

    const closeModal = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        const dataToPass = {
            selectedTime,
            selectedDate: selectedDate.isoDate.split('T')[0],
            selectedMedia,
            practitioner,
        };
        if (discount_type) {
            navigate(`/company/${discount_type}/confirm-payment`, {state: dataToPass});
        } else {
            navigate('/confirm-payment', {state: dataToPass});
        }
    };

    const openProfile = () => {
        setOpen(false);
        setOpenProfile(true);
    };

    const getAvailableTime = () => {
        const day: Day = selectedDate.dayFull as Day;
        const exceptionDate = exceptionsSchedules.find(exception =>
            CompareDate(new Date(exception.date), new Date(selectedDate.isoDate)),
        );

        let availableTime = availableDays[day];

        if (exceptionDate && availableTime) {
            availableTime = availableTime.filter(time => !exceptionDate.schedule.includes(time));
        }

        if (availableTime && availableTime.length > 0) {
            availableTime = availableTime.map(el => addTime(el, 7));
        } else {
            availableTime = [];
        }

        return availableTime;
    };

    useEffect(() => {
        if (prices_data && !prices_load) {
            const defaultMedia =
                prices_data &&
                prices_data.data.filter(price => price.num_of_sessions === 1 && price.title === practitioner.title);

            setSelectedMedia(defaultMedia.find(media => media.media_type === 'video'));
        }
    }, [prices_data, prices_load]);

    useEffect(() => {
        if (
            selectedMedia &&
            selectedDate.isoDate &&
            selectedDate.date &&
            selectedDate.dayFull &&
            selectedDate.day &&
            selectedTime
        ) {
            setIsNotComplete(false);
        } else {
            setIsNotComplete(true);
        }
    }, [selectedMedia, selectedDate, selectedTime]);

    return (
        <Modal close={closeModal}>
            <div className="relative p-4 shadow-card flex items-center justify-center">
                <BackButton handleClose={openProfile} />
                <h3 className="font-semibold">Profil</h3>
            </div>
            <div className="p-6 flex flex-col gap-7 overflow-y-auto h-inherit">
                <PsikologProfileHeader practitioner={practitioner} />
                <SessionInformation />
                <div>
                    <p className="font-semibold text-lg -mt-2">Pilih Layanan Konseling</p>
                    <div className="flex flex-wrap gap-2 mt-3">
                        {prices_load ? (
                            <div>
                                <p>Loading...</p>
                            </div>
                        ) : (
                            prices_data &&
                            prices_data.data
                                .filter(price => price.num_of_sessions === 1 && price.title === practitioner.title)
                                .map(price => (
                                    <MediaCard
                                        key={price.id}
                                        data={price}
                                        selectMedia={selectMedia}
                                        selectedMedia={selectedMedia}
                                    />
                                ))
                        )}
                    </div>
                </div>
                <div>
                    <p className="font-semibold text-lg -mt-2">Pilih Tanggal</p>
                    <div className="flex justify-between mt-3">
                        {dates.map(date => (
                            <DateComponent
                                dateObject={date}
                                selectedDate={selectedDate.isoDate}
                                availableDays={availableDays}
                                selectDate={selectDate}
                                key={date.isoDate}
                            />
                        ))}
                    </div>
                </div>
                <div>
                    <p className="font-semibold text-lg -mt-2">Pilih Jam</p>
                    <div className="flex gap-2 flex-wrap">
                        {hours.map(time => (
                            <TimeComponent
                                value={time}
                                key={time}
                                selectTime={selectTime}
                                selectedTime={selectedTime}
                                selectedDate={selectedDate.isoDate}
                                bookedSchedule={bookedSchedule}
                                availableTime={getAvailableTime()}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className="p-3 shadow-card flex items-center justify-end">
                <button
                    className={`bg-primary select-none px-20 py-2 rounded-md mr-6 ${
                        isNotComplete ? 'bg-searchbar text-tertiary pointer-events-none' : 'text-white'
                    }`}
                    onClick={handleSubmit}
                >
                    Lanjut ke Pembayaran
                </button>
            </div>
        </Modal>
    );
};
