import {ChevronLeft} from 'react-feather';
import {Link, useParams} from 'react-router-dom';

export const Header = (): JSX.Element => {
    const {discount_type} = useParams();
    return (
        <div className="flex justify-between items-center mt-16 h-20 px-4 mb-10">
            <Link to={discount_type ? `/company/${discount_type}/practitioners` : '/practitioners'}>
                <div className="flex justify-center items-center h-12 w-12 rounded cursor-pointer border-gray300 border ">
                    <ChevronLeft size={24} />
                </div>
            </Link>

            <h1 className="text-2xl font-semibold">Konfirmasi Sesi</h1>
            <div></div>
        </div>
    );
};
