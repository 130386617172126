import React from 'react';

interface ChipProps {
    label: string;
    backgroundColor?: string;
    textColor?: string;
    border?: string;
    textSize?: string;
}

const Chip: React.FC<ChipProps> = ({label, backgroundColor, textColor, border, textSize}) => {
    return (
        <div
            className={`rounded-3xl inline-block mb-2 select-none ${backgroundColor} ${border}`}
            style={{
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingLeft: '10px',
                paddingRight: '10px',
            }}
        >
            <p className={`${textSize} font-normal ${textColor}`}>{label}</p>
        </div>
    );
};

export default Chip;
