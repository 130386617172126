import {Calendar} from 'react-feather';

interface Props {
    setDate: (data: Date) => void;
}

const Time = ({setDate}: Props): JSX.Element => {
    return (
        <div className="mt-8">
            <h1 className="text-md font-semibold mb-2">Waktu</h1>
            <div className="flex flex-row place-items-center border-2 h-9  rounded-md gap-3 px-3">
                <input
                    className="h-full w-full outline-none text-secondary text-sm"
                    type="text"
                    id="search"
                    placeholder="Pilih tanggal"
                />
                <Calendar onChange={() => setDate(new Date())} className="text-blue-500" size={20} />
            </div>
        </div>
    );
};

export default Time;
