// import { NavigationBar } from 'components/common/NavigationBar';
import {LayananCard} from 'components/layanan/Card';
import {Template} from 'components/template/Template';
import {FiAward, FiChevronLeft, FiFrown, FiTag, FiUserCheck, FiUsers} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';

const layananDanHargaData = [
    {
        title: 'Psikolog Senior',
        information: [
            {
                Icon: FiUserCheck,
                description: 'Magister Psikologi dengan izin praktik \nHimpunan Psikologi Indonesia',
            },
            {
                Icon: FiAward,
                description: 'Pengalaman praktik 5 tahun lebih',
            },
            {
                Icon: FiFrown,
                description: 'Cocok menangani masalah sedang \nhingga berat',
            },
            {
                Icon: FiUsers,
                description: 'Jika konseling soal hubungan, boleh \najak pasangan',
            },
            {
                Icon: FiTag,
                description: 'Konseling video call: Rp400.000/sesi \nKonseling voice call: Rp350.000/sesi',
            },
        ],
    },
    {
        title: 'Psikolog',
        information: [
            {
                Icon: FiUserCheck,
                description: 'Magister Psikologi dengan izin praktik \nHimpunan Psikologi Indonesia',
            },
            {
                Icon: FiAward,
                description: 'Pengalaman praktik 1-5 tahun',
            },
            {
                Icon: FiFrown,
                description: 'Cocok menangani masalah ringan \nhingga sedang',
            },
            {
                Icon: FiUsers,
                description: 'Jika konseling soal hubungan, boleh \najak pasangan',
            },
            {
                Icon: FiTag,
                description: 'Konseling video call: Rp195.000/sesi \nKonseling voice call: Rp170.000/sesi',
            },
        ],
    },
    {
        title: 'Konselor',
        information: [
            {
                Icon: FiUserCheck,
                description: 'Sarjana Psikologi dengan izin praktik \nHimpunan Psikologi Indonesia',
            },
            {
                Icon: FiAward,
                description: 'Pengalaman praktik 1 - 5 tahun',
            },
            {
                Icon: FiFrown,
                description: 'Cocok menangani masalah ringan ',
            },
            {
                Icon: FiTag,
                description: 'Konseling video call: Rp145.000/sesi \nKonseling voice call: Rp125.000/sesi',
            },
        ],
    },
];

const BackButton = () => {
    const navigate = useNavigate();
    return (
        <button
            onClick={() => navigate(-1)}
            className="p-1 border border-searchbar bg-white rounded-lg absolute left-[-35%]"
        >
            <FiChevronLeft className="text-2xl" />
        </button>
    );
};

export const LayananDanHargaPage = () => {
    return (
        <Template>
            <div className="content">
                <div className="flex items-center justify-center mt-8 gap-3">
                    <div className="text-lg sm:text-2xl font-semibold text-center relative p-3 flex items-center">
                        <BackButton />
                        <h1>Bandingkan Layanan & Harga</h1>
                    </div>
                </div>
                <div className="flex flex-col justify-between min-w-fit mx-auto py-24 sm:flex-row">
                    {layananDanHargaData.map(data => (
                        <LayananCard {...data} />
                    ))}
                </div>
            </div>
        </Template>
    );
};
