import {Link} from 'react-router-dom';

interface Props {
    title: string;
    information: {
        Icon: any;
        description: string;
    }[];
}

interface CardTitleProps {
    title: string;
}

interface ButtonProps {
    label: string;
}

const CardTitle = ({title}: CardTitleProps) => {
    return (
        <div
            className={`rounded-full ${
                title === 'Psikolog Senior' ? 'bg-[#39DA00]' : title === 'Psikolog' ? 'bg-[#00B3FA]' : 'bg-[#FFA338]'
            } w-fit py-1 px-2 absolute inset-x-0 -top-[0.85rem] mx-auto`}
        >
            <h3 className="text-white font-medium text-center text-sm">{title}</h3>
        </div>
    );
};

const Button = ({label}: ButtonProps) => {
    return <button className="bg-primary text-white w-full rounded-md text-sm p-[0.4rem]">Pilih {label}</button>;
};

export const LayananCard = ({title, information}: Props) => {
    return (
        <div className="border border-searchbar shadow-card min-w-fit h-fit p-2 pt-5 rounded-xl relative mx-2 my-3">
            <CardTitle title={title} />
            <div className="flex flex-col gap-2 px-2 py-1 pb-4">
                {information.map(el => {
                    const Icon = el.Icon;
                    return (
                        <div className="flex items-center gap-3 text-navbar-accent">
                            <Icon className="text-lg" />
                            <p className="text-xs whitespace-pre-line">{el.description}</p>
                        </div>
                    );
                })}
            </div>
            <Link to="/practitioners" state={{title}}>
                <Button label={title} />
            </Link>
        </div>
    );
};
