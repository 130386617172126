import { translate } from "i18n";
import { Markup } from "interweave";

interface Props {
    image: any;
    name: string;
    htmlDesc: any;
    position: string;
}

export const ReviewCard = ({image, name, htmlDesc, position}: Props) => {
    return (
      <div className={`w-[328px] lg:w-[360px] rounded-xl shadow-[0_0px_20px_0px_rgb(0,56,161,0.15)] p-[24px] flex flex-col align-center gap-[20px] bg-white xl:absolute ${position}`}>
        <div className='flex justify-content items-center gap-4'>
            <img src={image} alt="person" width="36"/>
            <p className="font-bold text-base">{name}</p>
        </div>
        <div>
          <p className='text-navbar-accent text-[14px] xl:text-base'>
            <Markup content={translate(htmlDesc)}/>
          </p>
        </div>
      </div>
    );
};
