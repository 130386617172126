import ky from 'ky';

import {authAfterResponseHooks, authBeforeResponseHooks} from './hooks';
import {API_HOST} from '../../config/env';

const kyInstance = ky.create({
    headers: {},
});

const base = kyInstance.extend({
    prefixUrl: API_HOST,
    timeout: 6e4 * 4,
    hooks: {
        afterResponse: [authAfterResponseHooks],
        beforeRequest: [authBeforeResponseHooks],
    },
    retry: {
        limit: 2,
        methods: ['post', 'put'],
        statusCodes: [408, 500],
    },
});

const APIFile = base.extend({});

export {APIFile};
