import React from 'react';
import ReactDOM from 'react-dom/client';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {store} from 'app/store';
import {Provider} from 'react-redux';
import App from 'App';
import {GoogleOAuthProvider} from '@react-oauth/google';
import './ReactotronConfig';
import './styles/style.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();
root.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId="706195858330-0bmd1qkme93up66mns0f6nise9jiocl0.apps.googleusercontent.com">
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <App />
                </QueryClientProvider>
            </Provider>
        </GoogleOAuthProvider>
    </React.StrictMode>,
);
