interface HargaSesiProps {
    selectedMedia?: any;
    practitioner?: any;
}

export const HargaSesi: React.FC<HargaSesiProps> = ({selectedMedia, practitioner}) => {
    const getTitleLabel = (title: string): string => {
        const titleMapping: Record<string, string> = {
            'Senior Psychologist': 'Psikolog Senior',
            Psychologist: 'Psikolog',
            'Career Coach': 'Career Coach',
            Counselor: 'Konselor',
        };

        return titleMapping[title] || title;
    };

    const formattedPrice = selectedMedia.price.toLocaleString('id-ID', {
        style: 'currency',
        currency: 'IDR',
    });

    const label = getTitleLabel(practitioner.title);
    return (
        <div>
            <div className="flex items-center justify-betweens py-4 md:py-8 px-4 border-searchbar rounded-lg shadow-box justify-between  mb-4 md:mb-0 ">
                <p className="text-base text-dark mr-2">
                    1 sesi konseling {selectedMedia.media_type} call {label}
                </p>
                <div />
                <p className="text-lg text-dark font-semibold">{formattedPrice}</p>
            </div>
        </div>
    );
};
