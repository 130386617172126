import { FiHeart, FiAward, FiStar } from "react-icons/fi";

type SpecializationType = {
  specialization: string;
}

type ExperienceType = {
  experience: string | number;
}

type RatingType = {
  rating: number;
}

type LogoType = {
  type: string;
}

const InformationLogo = ({type}: LogoType) => {
  return (
    <div className="absolute -top-[15px] inset-x-0 mx-auto w-fit text-primary bg-secondary p-2 rounded-full">
      {type === 'specialization' ? (
        <FiHeart strokeWidth={2.5} />
      ) : type === 'experience' ? (
        <FiAward strokeWidth={2.5} />
      ) : (
        <FiStar strokeWidth={2.5} />
      )}
    </div>
  );
};

export const InformationCardSpectialization = ({specialization}: SpecializationType) => {
  return (
    <div className="bg-white shadow-box p-4 pb-2 pt-6 w-fit rounded-xl relative">
      <InformationLogo type="specialization" />
      <p className="text-sm">{specialization}</p>
    </div>
  );
};

export const InformationCardExperience = ({experience}: ExperienceType) => {
  return (
    <div className="bg-white shadow-box px-10 pb-2 pt-6 w-fit rounded-xl relative">
      <InformationLogo type="experience" />
      <p className="text-sm">{experience} tahun</p>
    </div>
  );
};

export const InformationCardRating = ({rating}: RatingType) => {
  return (
    <div className="bg-white shadow-box px-10 pb-2 pt-6 w-fit rounded-xl relative">
      <InformationLogo type="rating" />
      <p className="text-sm">{rating}/5</p>
    </div>
  );
};