import {colors} from 'assets/colors';
import {FiCheck, FiX} from 'react-icons/fi';

interface Props {
    data: any;
    onClick: (data: any, answer: any, add: number) => void;
    answears: any;
    yes: string;
    no: string;
}

export const YES_NO = ({data, onClick, answears, yes, no}: Props): JSX.Element => {
    const is_answeared = !!answears[data.id];
    return (
        <div className=" flex flex-col justify-center items-center w-full">
            <div className=" h-44 flex items-center mb-4">
                <h1 className="text-xl text-center font-medium ">{data?.question}</h1>
            </div>
            <button
                onClick={() => onClick(data, 'true', 1)}
                className={`${
                    is_answeared && answears[data.id] == 'true' ? ' bg-primaryLightest border-primary' : 'bg-white'
                } w-full h-12 shadow-sm rounded-md flex flex-row items-center  border sm:hover:border-primary sm:hover:bg-primaryLightest mb-4 text-sm px-4`}
            >
                <FiCheck size={20} color={colors.primary} />
                <h1 className="ml-2 text-primary text-md font-medium">{yes}</h1>
            </button>
            <button
                onClick={() => onClick(data, 'false', 1)}
                className={`${
                    is_answeared && answears[data.id] == 'false' ? ' bg-primaryLightest border-primary' : 'bg-white'
                } w-full h-12 shadow-sm rounded-md flex flex-row items-center  border sm:hover:border-primary sm:hover:bg-primaryLightest mb-4 text-sm px-4`}
            >
                <FiX size={20} color={colors.primary} />
                <h1 className="ml-2 text-primary text-md font-medium">{no}</h1>
            </button>
        </div>
    );
};
