import { FiClock, FiEdit3, FiWifi } from "react-icons/fi";

export const SessionInformation = () => {
  return (
    <div className="flex flex-col md:flex-row justify-around px-2 py-3 shadow-card rounded-lg text-navbar-accent gap-3 md:gap-0">
      <div className="flex flex-row md:flex-col items-center gap-4">
        <FiClock className="text-xl"/>
        <p className="md:text-center text-sm">Satu sesi berlangsung 45-60 menit</p>
      </div>
      <div className="flex flex-row md:flex-col items-center gap-4">
        <FiEdit3 className="text-xl"/>
        <p className="md:text-center text-sm w-2/3">Kamu diminta mengisi kuesioner screening sebelum sesi konseling</p>
      </div>
      <div className="flex flex-row md:flex-col items-center gap-4">
        <FiWifi className="text-xl"/>
        <p className="md:text-center text-sm">Pastikan internet lancar saat konseling</p>
      </div>
    </div>
  );
};