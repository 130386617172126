import {Link, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import { NavigationMenu } from './NavigationMenu';

// Images
import teduhLogo from 'assets/images/teduh-logo.png';
import {SearchBar} from './SearchBar';
import {ProfileNavBar} from 'components/profil/ProfileNavBar';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {UserQuery} from 'lib/react-query/query-hooks';
import {setDataUndefined} from 'app/slice/main';
import {NavLinkWithDrawer} from 'components/navigation/NavLinkWithDrawer';
import {SesiMendatangLink} from 'components/navigation/SesiMendatangLink';
import {FiBriefcase, FiClock, FiFileText, FiSunset, FiMenu} from 'react-icons/fi';

interface Props {
    status: string;
}

type LinkType = {
    label: string;
    path: string;
    icon: any;
};

export const NavigationBar = ({status}: Props) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [links, setLinks] = useState<LinkType[]>([]);
    const [show, setShow] = useState<boolean>(false);

    const {mutate: onLogout} = UserQuery.Logout({
        onSuccess() {
            dispatch(setDataUndefined());
            navigate('/signin');
        },
    });

    const handleLogout = () => {
        onLogout({});
    };

    useEffect(() => {
        if (status === 'pre-sign-in') {
            setLinks([
                {
                    label: 'Blog',
                    path: '/',
                    icon: FiFileText,
                },
                {
                    label: 'Teduh for Business',
                    path: '/business',
                    icon: FiBriefcase,
                },
            ]);
        } else if (status === 'post-sign-in') {
            setLinks([
                {
                    label: 'Sesi Mendatang',
                    path: '/',
                    icon: FiSunset,
                },
                {
                    label: 'History',
                    path: '/history',
                    icon: FiClock,
                },
                {
                    label: 'Blog',
                    path: '/',
                    icon: FiFileText,
                },
                {
                    label: 'Teduh for Business',
                    path: '/business',
                    icon: FiBriefcase,
                },
            ]);
        }
    }, []);

    const isMobile = useMediaQuery({query: '(max-width: 640px)'});

    return (
        <>
            {isMobile ? (
                <>
                    <nav className="w-screen flex justify-between px-10 h-14 items-center fixed bg-white z-50 border-b border-searchbar">
                        <div className="left flex gap-10 text-navbar-accent text-2xl">
                            <FiMenu onClick={() => setShow(true)} />
                        </div>
                        <div className="justify-center flex">
                            <Link to="/">
                                <div className="logo text-main font-medium text-lg flex items-center gap-2 select-none">
                                    <img src={teduhLogo} alt="Teduh Logo" className="w-8" />
                                    <h1>Teduh</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="right flex gap-5 items-center">
                            <ProfileNavBar onLogout={handleLogout} />
                        </div>
                    </nav>
                    <NavigationMenu show={show} setShow={setShow}>
                        <div className="links flex flex-col gap-8 text-navbar-accent text-sm">
                            {links.map(link =>
                                link.label === 'Blog' ? (
                                    <NavLinkWithDrawer label={link.label} />
                                ) : link.label === 'Sesi Mendatang' ? (
                                    <SesiMendatangLink label={link.label} />
                                ) : (
                                    <Link to={link.path} className='w-fit'>
                                        <div className="flex items-center">
                                            <div className="mr-2">
                                                <link.icon size={24} />
                                            </div>
                                            {link.label}
                                        </div>
                                    </Link>
                                ),
                            )}
                        </div>
                    </NavigationMenu>
                </>
            ) : (
                <nav className="w-screen flex justify-between px-10 h-16 items-center fixed bg-white z-50 border-b border-searchbar">
                    <div className="left flex gap-10">
                        <Link to="/">
                            <div className="logo text-main font-medium text-xl flex items-center gap-3 select-none">
                                <img src={teduhLogo} alt="Teduh Logo" className="w-10" />
                                <h1>Teduh</h1>
                            </div>
                        </Link>
                        <div className="links flex justify-around gap-8 items-center text-navbar-accent text-sm">
                            {links.map(link =>
                                link.label === 'Blog' ? (
                                    <NavLinkWithDrawer label={link.label} />
                                ) : link.label === 'Sesi Mendatang' ? (
                                    <SesiMendatangLink label={link.label} />
                                ) : (
                                    <Link to={link.path}>
                                        <div className="flex justify-center items-center">
                                            <div className="mr-2">
                                                <link.icon size={24} />
                                            </div>
                                            {link.label}
                                        </div>
                                    </Link>
                                ),
                            )}
                        </div>
                    </div>
                    <div className="right flex gap-5 items-center">
                        <SearchBar
                            onChange={() => console.log('aa')}
                            placeholder="Cari psikolog atau topik konseling"
                        />
                        <ProfileNavBar onLogout={handleLogout} />
                    </div>
                </nav>
            )}
        </>
    );
};
