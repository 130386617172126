import AppleLogin from 'react-apple-login';
import MicrosoftLogin from 'react-microsoft-login';
import {SignInButton} from './SignInButton';

interface Props {
    methods:
        | {
              name: string;
              icon: string;
              onclick: (res?: any, err?: any) => void;
          }[]
        | any;
}

export const Signin = ({methods}: Props) => {
    return (
        <div className="bg-white flex flex-col w-2/3 mx-auto rounded-xl text-center p-10 shadow-signin">
            <h1 className="font-semibold text-3xl text-primary mb-8">Sign In</h1>
            <div className="flex flex-col gap-5">
                {methods.map((method: any) => {
                    if (method) {
                        if (method?.name == 'Apple') {
                            return (
                                <AppleLogin
                                    clientId="id.teduh.io"
                                    redirectURI={window.location.href}
                                    callback={d => method.onclick(d)} // Catch the response
                                    scope="email name"
                                    responseMode="query"
                                    render={props => (
                                        <SignInButton
                                            label={`Sign in with ${method.name}`}
                                            icon={method.icon}
                                            onClick={() => props.onClick()}
                                        />
                                    )}
                                />
                            );
                        }
                        if (method?.name == 'Microsoft') {
                            return (
                                <MicrosoftLogin
                                    className="flex"
                                    children={
                                        <button className="border border-primary p-2 rounded-xl w-full text-primary flex items-center justify-center gap-3">
                                            <img src={method.icon} alt="Google" className="h-6" />
                                            {`Sign in with ${method.name}`}
                                        </button>
                                    }
                                    clientId={'8eacd0d8-09d1-4ab0-bceb-7872b4795327'}
                                    authCallback={(err, res) => method.onclick(res, err)}
                                    redirectUri={window.location.href}
                                />
                            );
                        }
                        return (
                            <SignInButton
                                label={`Sign in with ${method.name}`}
                                icon={method.icon}
                                onClick={method.onclick}
                            />
                        );
                    }
                })}
            </div>
        </div>
    );
};
