import {useState} from 'react';
import ProfileCard from './ProfileCard';
import {Link} from 'react-router-dom';
import {ICounseling} from 'model/api';
import {PractitionProfileModal} from '../profil/PractitionerProfileModal';
import {BookingSessionModal} from 'components/session/BookingSessionModal';
import Lottie from 'lottie-react';
import {lottieAnimation} from 'assets/lotte';

interface Props {
    practitioners: ICounseling.Psychologist.Item[] | undefined;
    isLoading: boolean;
}

const PractitionersList = ({practitioners, isLoading}: Props): JSX.Element => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openBookingModal, setOpenBookingModal] = useState<boolean>(false);
    const [selectedPractitioner, setSelectedPractitioner] = useState<ICounseling.Psychologist.Item>();

    return (
        <div className="h-fit flex flex-1 flex-col justify-center items-center gap-3 md:border-l md:pl-10">
            <div className="hidden md:flex flex-col gap-3">
                <p className="text-xl font-semibold self-center">Pilih Praktisi</p>
                <Link to="/layanan">
                    <p className="text-primary text-center font-regular self-center">Bandingkan Layanan & Harga</p>
                </Link>
            </div>
            {isLoading ? (
                <div className=" h-32 mt-4 rounded-md w-[36rem] relative dark:bg-gray-100 bg-white animate-pulse"></div>
            ) : !!practitioners && practitioners.length > 0 ? (
                practitioners.map((practitioner, index) => (
                    <ProfileCard
                        key={index}
                        practitioner={practitioner}
                        setOpenModal={setOpenModal}
                        setSelectedPractitioner={setSelectedPractitioner}
                    />
                ))
            ) : (
                <div className="w-[36rem] mt-4 rounded-md py-8 shadow-md">
                    <Lottie className="h-[16rem] w-[36rem]" animationData={lottieAnimation.tedoo_panik} />
                    <h1 className=" text-center text-md text-normal">Tidak Ada Hasil</h1>
                </div>
            )}
            {openModal && selectedPractitioner && (
                <PractitionProfileModal
                    setOpen={setOpenModal}
                    setOpenBookingModal={setOpenBookingModal}
                    practitioner={selectedPractitioner}
                />
            )}
            {openBookingModal && selectedPractitioner && (
                <BookingSessionModal
                    setOpen={setOpenBookingModal}
                    setOpenProfile={setOpenModal}
                    practitioner={selectedPractitioner}
                />
            )}
        </div>
    );
};

export default PractitionersList;
