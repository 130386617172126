import { translate } from "i18n";

interface Props {
    image: any;
    title: any;
    description: any;
    className: string;
}

export const ServiceCard = ({image, title, description, className}: Props) => {
  
  return (
      <div className={className}>
        <img src={image} alt="Interactive Seminar" width="70"/>
        <div>
          <h1 className='font-semibold text-base sm:text-2xl'>
          {translate(title)}
          </h1>
          <p className='text-navbar-accent text-[14px] sm:text-base'>
            {translate(description)}
          </p>
        </div>
      </div>
    );
};
