import {FiSearch, FiFilter} from 'react-icons/fi';

interface Props {
    placeholder: string;
    className?: string;
    onChange: (value: string) => void;
    open?: () => void;
}

export const SearchBar = ({placeholder, className, onChange, open}: Props) => {
    return (
        <div className="relative flex items-center">
            <input
                type="text"
                placeholder={placeholder}
                className={
                    className ||
                    'border py-1.5 px-2 pl-8 w-[19rem] rounded-md outline-0 border-searchbar placeholder:text-darkgray text-sm'
                }
                onChange={e => onChange(e.target.value)}
            />
            <FiSearch className="text-gray absolute left-2.5" />
            <button className="text-gray absolute right-2.5 md:hidden" onClick={open}><FiFilter className="text-gray"/></button>
        </div>
    );
};
