import moment from 'moment';

interface transaksiProps {
    data?: any;
    onItemClick: (index: number) => void;
}
export const RiwayatTransaksiProfil: React.FC<transaksiProps> = ({data, onItemClick}) => {
    const getTitleLabel = (title: string): string => {
        const titleMapping: Record<string, string> = {
            'Senior Psychologist': 'Psikolog Senior',
            Psychologist: 'Psikolog',
            'Career Coach': 'Career Coach',
            Counselor: 'Konselor',
        };

        return titleMapping[title] || title;
    };

    const formatDate = (dateString: string): string => {
        const date = moment(dateString);
        return date.format('D MMM YYYY, HH.mm');
    };
    return (
        <div>
            {data?.map((transaksi: any, index: number) => (
                <div key={index} onClick={() => onItemClick(index)} className="w-[326px] mb-6 cursor-pointer">
                    <div className="flex justify-between">
                        <p className="text-dark text-sm">ID Transaksi</p>
                        <p className="text-navbar-accent text-sm">ABCD1234</p>
                    </div>
                    <div className="flex justify-between mb-1">
                        <p className="text-dark text-sm">ABC1234</p>
                        <p className="text-navbar-accent text-sm">{formatDate(transaksi.date)}</p>
                    </div>
                    <div className="border border-searchbar rounded-lg shadow-box p-3">
                        <p className="text-dark">1 sesi konseling {transaksi.media_type} call</p>
                        <p className="text-dark">{getTitleLabel(transaksi.psychologist_profile?.title)}</p>
                        <div className="flex justify-end text-lg text-dark font-semibold">
                            <p>
                                {transaksi.price.price.toLocaleString('id-ID', {
                                    style: 'currency',
                                    currency: 'IDR',
                                })}
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
