import {ReviewCard} from 'components/b2b/ReviewCard';
import Background from '../../assets/images/b2b/ulasan-bg.svg';
import Erich from '../../assets/images/b2b/Erich.svg';
import Quantum from '../../assets/images/b2b/Quantum.svg';
import ZA from '../../assets/images/b2b/ZA.svg';
import AMT from '../../assets/images/b2b/AMT.svg';
import {PercentageCard} from './PercentageCard';
import ArrowDown from '../../assets/images/b2b/arrow-down.svg';
import ArrowUp from '../../assets/images/b2b/arrow-up.svg';
import Ribbon from '../../assets/images/b2b/ribbon.svg';
import FiveStars from '../../assets/images/b2b/five-stars.svg';
import { translate } from 'i18n';
import { Markup } from 'interweave';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


const reviewData = [
    {
        image: Erich,
        name: 'Erich, Villa Finder',
        htmlDesc: 'b2b_reviews_erich_desc',
        position: 'top-[70px] left-[180px]',
    },
    {
        image: Quantum,
        name: 'K, Quantum',
        htmlDesc: 'b2b_reviews_quantum_desc',
        position: 'top-[100px] 2xl:top-[140px] right-[50px]',
    },
    {
        image: ZA,
        name: 'ZA, Dekan FISIPOL UI',
        htmlDesc: 'b2b_reviews_quantum_desc',
        position: '2xl:left-[60px] top-[300px]',
    },
    {
        image: AMT,
        name: 'AMT, 25 y.o.',
        htmlDesc: 'b2b_reviews_amt_desc',
        position: 'bottom-[60px] right-[180px]',
    },
];

const percentageData = [
    {
        image: ArrowDown,
        number: '30%',
        description: 'b2b_percentage_card_30',
        isWhite: true,
        isNumberFirst: true,
        position: 'top-[440px] left-[55%] 2xl:left-[50%] flex-col xl:-translate-y-[50%]',
        imageStyle: 'px-[24px] h-[48px] sm:h-[96px] flex',
    },
    {
        image: ArrowUp,
        number: '83,3%',
        description: 'b2b_percentage_card_83',
        isWhite: true,
        isNumberFirst: false,
        position: 'bottom-[60px] left-[200px] flex-col-reverse',
        imageStyle: 'px-[24px] h-[50px] sm:h-[68px]',
    },
    {
        image: Ribbon,
        number: '77%',
        description: 'b2b_percentage_card_77',
        isWhite: false,
        isNumberFirst: true,
        position: 'top-[440px] left-[34%] xl:-translate-y-[50%] flex-col-reverse',
        imageStyle: 'ml-4 h-[55px] sm:h-[78px]',
    },
];

export const CustomerReview = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    
    return (
      <div className="flex flex-col justify-end mb-18 xl:mb-36 px-[7%]">
            <motion.div 
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={inView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 2 }}
            >
                <h1 className="font-semibold text-2xl sm:text-4xl md:text-5xl pb-4 text-black md:text-center md:leading-[75px]">
                    <Markup content={translate('b2b_reviews_title')}/>
                </h1>
            </motion.div>
            <hr className="w-[60px] h-[10px] mx-auto mt-4 mb-12 bg-primary border-0 rounded" />
            <div className="xl:py-24 xl:relative mx-auto grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 gap-[30px] xl:gap-[0px] xl:mx-0">
                <img src={Background} className='w-[1100px] mx-auto hidden xl:block' />
                <img src={FiveStars} className="absolute -right-[40px] 2xl:right-[20px] bottom-[285px] hidden xl:block" />
                {reviewData.map((data) => <ReviewCard image={data.image} name={data.name} htmlDesc={data.htmlDesc} position={data.position}/>)}
                <div className='grid grid-cols-2 sm:grid-cols-3 place-items-center gap-[15px] sm:col-span-2 w-full'>
                    {percentageData.map((data) => 
                        <PercentageCard
                            image={data.image}
                            number={data.number}
                            description={data.description}
                            isWhite={data.isWhite}
                            isNumberFirst={data.isNumberFirst}
                            position={data.position}
                            imageStyle={data.imageStyle}
                        />
                    )}
                </div>
            </div>
            <button onClick={() => window.open('https://wa.me/6285219498199')} className="px-5 py-3 rounded-md font-medium text-white text-sm bg-gradient-custom from-start-carousel-one to-end-carousel-one hidden xl:block w-[174px] mx-auto">
                Hubungi Kami
            </button>
        </div>
    );
};
