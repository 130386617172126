interface Props {
    children: any;
    close: () => void;
}

export const Modal = ({children, close}: Props) => {
    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                onClick={close}
            >
                <div
                    className={`bg-white rounded-xl w-full md:w-[80%] md:max-w-[48em] h-full md:max-h-[35em] flex flex-col`}
                    onClick={e => e.stopPropagation()}
                >
                    {children}
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};
