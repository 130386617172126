import { DateObject } from "components/session/BookingSessionModal";

export const CompareDate = (a: Date, b: Date) => {
  const date1 = a.getDate();
  const month1 = a.getMonth();
  const year1 = a.getFullYear();

  const date2 = b.getDate();
  const month2 = b.getMonth();
  const year2 = b.getFullYear();

  return date1 === date2 && month1 === month2 && year1 === year2;
};

export const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

export const addTime = (time: string, increment: number) => {
  let intTime = parseInt(time);

  intTime += increment;

  const timeString = zeroPad(intTime, 2);

  return timeString + '.00';
};

export const findClosestTime = (targetTime: Date, hours: string[]): string => {
  const targetTimestamp = targetTime.getTime();
  let closestHour = null;
  let minTimeDifference = Infinity;

  for (const hour of hours) {
    const [hourValue, minuteValue] = hour.split('.').map(Number);
    const time = new Date(targetTime);
    time.setHours(hourValue);
    time.setMinutes(minuteValue);

    const timeDifference = Math.abs(time.getTime() - targetTimestamp);
    if (timeDifference < minTimeDifference) {
      minTimeDifference = timeDifference;
      closestHour = hour;
    }
  }

  return closestHour === null ? '' : closestHour;
};

export const findClosestDate = (dateList: DateObject[], availableDays: Record<string, string[]>): Date => {
  const current = new Date(new Date().setUTCHours(0, 0, 0, 0));

  const filteredDate = dateList.filter((date) => availableDays[date.dayFull].length > 0);

  if (filteredDate.find((date) => date.isoDate === current.toISOString()) || filteredDate.length <= 0) {
    return current;
  }

  let closestDate = new Date(filteredDate[0].isoDate);
  let minTimeDifference = Math.abs(current.getTime() - new Date(filteredDate[0].isoDate).getTime());

  filteredDate.forEach((date) => {
    const timeDifference = Math.abs(current.getTime() - new Date(date.isoDate).getTime());
    if (timeDifference < minTimeDifference) {
      closestDate = new Date(filteredDate[0].isoDate);
      minTimeDifference = timeDifference;
    }
  });

  return closestDate;
};