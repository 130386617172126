import {useEffect, useState} from 'react';
import {CatatanScreening} from './CatatanScreening';
import {IconRiwayat} from './IconRiwayat';
import {ProfilRiwayatSesi} from './ProfilRiwayatSesi';
import {RiwayatSesiStatus} from './RiwayatSesiStatus';
import Spinner from 'assets/images/ZKZx.gif';
import {useMediaQuery} from 'react-responsive';
import {ChevronLeft} from 'react-feather';

interface riwayatProps {
    data?: any;
}

export const RiwayatSesi: React.FC<riwayatProps> = ({data}) => {
    const [indexProfil, setIndexProfil] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const handleItemClick = (index: number) => {
        setIndexProfil(index); // Update the state with the clicked index
    };
    console.log(indexProfil);

    const SpinnerComponent = () => (
        <div className="h-12 w-12">
            <img src={Spinner} alt="Loading" />
        </div>
    );

    const isMobile = useMediaQuery({maxWidth: 768});

    // When data is available, set isLoading to false
    useEffect(() => {
        if (data) {
            setIsLoading(false);
        }
        if (isMobile && indexProfil !== null) {
            window.scrollTo(0, 150);
        }
        if (isMobile && indexProfil == null) {
            window.scrollTo(0, 0);
        }
    }, [data, isMobile, indexProfil]);

    return (
        <div className="flex flex-col md:flex-row">
            <div className="flex flex-col md:flex-row">
                <div className="">
                    {isLoading ? (
                        <SpinnerComponent />
                    ) : indexProfil !== null && isMobile ? (
                        <div>
                            <div className="flex items-center mb-6">
                                <div
                                    className="flex justify-center items-center h-12 w-12 rounded cursor-pointer border-gray300 border mr-14 "
                                    onClick={() => setIndexProfil(null)}
                                >
                                    <ChevronLeft size={24} />
                                </div>
                                <p className="font-semibold text-2xl">Detail Sesi</p>
                            </div>
                            <RiwayatSesiStatus data={data} indexProfil={indexProfil} />
                            <CatatanScreening />
                        </div> // Replace ProfilRiwayatSesi with CatatanScreening in mobile view
                    ) : (
                        <ProfilRiwayatSesi data={data} onItemClick={handleItemClick} />
                    )}
                </div>
                {isMobile ? null : <div className="border-r h-[650px]"></div>}

                {indexProfil !== null ? (
                    <>
                        <div className="mr-[85px]">{isMobile ? null : <CatatanScreening />}</div>
                        <div>{isMobile ? null : <RiwayatSesiStatus data={data} indexProfil={indexProfil} />}</div>
                    </>
                ) : isMobile ? null : (
                    <IconRiwayat />
                )}
            </div>
        </div>
    );
};
