import GirlMeditate from '../../assets/images/b2b/girl.svg';
import Resource from '../../assets/images/b2b/resource.svg';
import News from '../../assets/images/b2b/news.svg';
import { FiChevronRight } from 'react-icons/fi';
import { translate } from 'i18n';
import { Markup } from 'interweave';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


export const Resources = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const phoneNumber = "6285219498199";
  const message = "Hello, I am (name) from (company's name). I visited Teduh for Business's website, and I am interested in learning more about Teduh's survey findings on employee mental health in Indonesia. May I obtain the survey's report?";
  const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;

    return (
      <motion.div 
      ref={ref}
      initial={{ opacity: 0, x: -100 }}
      animate={inView ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 2 }}
      className='w-[85%] md:w-[75%] bg-blueLight mx-auto my-24 flex flex-col justify-center rounded-[24px] md:rounded-[64px] py-16 relative border-2'>
        <img src={Resource} alt="Resource" className='w-[100px] -top-[50px] md:w-[200px] absolute md:-top-[100px] left-[50%] -translate-x-[50%]'/>
        <div className='text-center'>
          <div>
          </div>
          <p className='font-semibold te'>
            RESOURCES
          </p>
          <h1 className='font-semibold text-2xl sm:text-4xl md:text-5xl pb-4 text-black text-center w-[70%] m-auto lg:leading-[72px]'>
            <Markup content={translate('b2b_resources_title')}/>
          </h1>
        </div>
        <div className='flex flex-col md:flex-row w-[85%] max-w-[976px] m-auto rounded-[24px] md:rounded-[50px] shadow-[0_0px_20px_0px_rgb(25,61,111,0.25)] bg-white overflow-hidden'>
          <div className='sm:min-h-full overflow-hidden sm:w-full'>
              <img src={GirlMeditate} className="sm:h-full w-full object-cover" />
          </div>
          <div className='p-5 md:px-8 md:py-5'>
              <div className='flex gap-2 md:mb-6 md:mt-4'>
                  <img src={News} alt="News"/>
                  <p className='text-[16px] md:text-2xl text-navbar-accent font-medium'>Report</p>
              </div>
              <h2 className='font-semibold text-[18px] md:text-2xl my-4'><Markup content={translate('b2b_resources_subtitle')}/></h2>
              <p className='text-navbar-accent my-4 text-[14px] md:text-[20px] leading-[18px] md:leading-[30px]'>{translate('b2b_resources_desc')}</p>
              <div className='flex justify-end'>
              <button onClick={() => window.open(url)} className='py-3 rounded-md font-semibold text-navbar-accent text-[16px] md:text-xl text-bold flex items-center'>
                  <p>{translate('read_more_button')}</p>
                  <FiChevronRight className='w-[22px] ml-2 md:ml-8' />
              </button>
              </div>
          </div>
      </div>
      </motion.div>

    );
};
