interface Props {
  image: any;
  alt: string;
  number: number;
  label: string
}

export const Bubble = ({image, alt, number, label}: Props) => {
  return (
    <div className="flex flex-col items-center gap-7 w-24">
      <div className='w-24 h-24 rounded-full bg-sky-blue relative'>
        <p className="absolute top-[-4px] left-0 text-3xl font-semibold text-navbar-accent">{number}</p>
        <div className="h-full w-full flex items-center justify-center">
          <img src={image} alt={alt} className="w-14" />
        </div>
        <div className='mini-bubble absolute w-6 h-6 bg-sky-blue border-2 border-white rounded-full bottom-[-2px] right-1'></div>
      </div>
      <p className="text-center text-xs">{label}</p>
    </div>
  );
};