import React, {useState} from 'react';

interface PopupPilihMetodeProps {
    isOpen: boolean;
    onClose: () => void;
    data?: any;
    setLakukanPembayaran: (value: boolean) => void;
    on_create_session: (method: any) => void;
}

export const PopupPilihMetode: React.FC<PopupPilihMetodeProps> = ({
    data,
    isOpen,
    onClose,
    setLakukanPembayaran,
    on_create_session,
}) => {
    const [selectedBank, setSelectedBank] = useState<string | null>(null);

    const handleBankClick = (bankName: string, item: any, type: string) => {
        setSelectedBank(bankName);
        if (type === 'wallet') {
            on_create_session({...item, type: 'e-wallet'});
        } else if (type === 'bank') {
            on_create_session({...item, type: 'virtual-account'});
        }
    };

    const banks = data?.data.banks;
    const wallets = data?.data.wallets;

    const isButtonEnabled = !!selectedBank;

    if (!isOpen) {
        return null; // If the popup is not open, return null to render nothing
    }

    const handleContentClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    };

    const handleButtonClick = () => {
        if (isButtonEnabled) {
            setLakukanPembayaran(true);
            onClose();
        }
    };

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50"
            onClick={onClose}
        >
            <div
                className=" bg-white p-5   rounded-lg shadow-md w-96 h-[550px] overflow-hidden relative"
                onClick={handleContentClick}
            >
                <div className="  flex flex-col items-center">
                    <h2 className="text-2xl font-semibold mb-2">Pilih Metode</h2>
                </div>

                <div className="h-full overflow-hidden overflow-y-scroll box-content ">
                    <p className="text-lg text-dark font-semibold mt-4 mb-3">Terintegrasi Google</p>
                    <div className="border rounded-lg">
                        {wallets.map((wallet: any, index: any) => (
                            <div
                                key={index}
                                className={`border-b p-3 flex items-center cursor-pointer ${
                                    selectedBank === wallet.value ? 'bg-gray-200' : ''
                                }`}
                                onClick={() => handleBankClick(wallet.value, wallet, 'wallet')}
                            >
                                <img src={wallet.icon} alt={wallet.name} className="h-7 w-8" />
                                <p className="text-base text-dark ml-3">{wallet.name}</p>
                            </div>
                        ))}
                    </div>

                    <p className="text-lg text-dark font-semibold mt-3 mb-3">Transfer Bank</p>
                    <div className="border rounded-lg mb-32">
                        {banks.map((bank: any, index: any) => (
                            <div
                                key={index}
                                className={`border-b p-3 flex items-center cursor-pointer ${
                                    selectedBank === bank.value ? 'bg-gray-200' : ''
                                }`}
                                onClick={() => handleBankClick(bank.value, bank, 'bank')}
                            >
                                <img src={bank.icon} alt={bank.name} className="h-7 w-8" />
                                <p className="text-base text-dark ml-3">{bank.name}</p>
                            </div>
                        ))}
                        <div className="absolute bottom-0 w-full inset-x-0 p-5  bg-white">
                            <div
                                className={`flex flex-col items-center rounded-lg ${
                                    isButtonEnabled ? 'bg-primary' : 'bg-searchbar'
                                }`}
                            >
                                <button
                                    disabled={!isButtonEnabled}
                                    className={`text-base w-full flex justify-center items-center ${
                                        isButtonEnabled ? 'text-white' : 'text-darkgray'
                                    } py-3`}
                                    onClick={handleButtonClick}
                                >
                                    Lakukan Pembayaran
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
