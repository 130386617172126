import {useEffect, useState} from 'react';
import {IconRiwayat} from './IconRiwayat';
import {RiwayatTransaksiDetail} from './RiwayatTransaksiDetail';
import {CounselingQuery} from 'lib/react-query/query-hooks';
import {RiwayatTransaksiProfil} from './RiwayatTransakisProfil';
import Spinner from 'assets/images/ZKZx.gif';
import {useMediaQuery} from 'react-responsive';
import {ChevronLeft} from 'react-feather';

export const RiwayatTransaksi = (): JSX.Element => {
    const [page, setPage] = useState(1);
    const [pageMax, setPageMax] = useState(0);
    const [error, setError] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [indexProfil, setIndexProfil] = useState<number | null>(null);

    const handleItemClick = (index: number) => {
        setIndexProfil(index);
    };
    console.log(indexProfil);

    const SpinnerComponent = () => (
        <div className="h-12 w-12">
            <img src={Spinner} alt="Loading" />
        </div>
    );

    const isMobile = useMediaQuery({maxWidth: 768});

    const {isLoading} = CounselingQuery.Payment(
        {
            onSettled(result: any) {
                if (result?.data.length == 0) {
                    setError(true);
                } else {
                    setPageMax(result?.totalPages || 0 - 1);
                    page == 0 ? setData(result?.data || []) : setData([...data, ...(result?.data || [])]);
                }
            },
        },
        {
            page,
            limit: 10,
        },
    );
    console.log(error);
    console.log(pageMax);
    console.log('Loading', isLoading);
    console.log(data);

    useEffect(() => {
        setPage(0);
        if (isMobile && indexProfil !== null) {
            window.scrollTo(0, 150);
        }
        if (isMobile && indexProfil == null) {
            window.scrollTo(0, 0);
        }
    }, [isMobile, indexProfil]);

    return (
        <div className="flex flex-col md:flex-row">
            <div className="">
                {isLoading ? (
                    <SpinnerComponent />
                ) : indexProfil !== null && isMobile ? (
                    <div>
                        <div className="flex items-center mb-6">
                            <div
                                className="flex justify-center items-center h-12 w-12 rounded cursor-pointer border-gray300 border mr-14 "
                                onClick={() => setIndexProfil(null)}
                            >
                                <ChevronLeft size={24} />
                            </div>
                            <p className="font-semibold text-2xl">Detail Sesi</p>
                        </div>
                        <RiwayatTransaksiDetail data={data} indexProfil={indexProfil} />
                    </div>
                ) : (
                    <RiwayatTransaksiProfil data={data} onItemClick={handleItemClick} />
                )}
            </div>
            {isMobile ? null : <div className="border-r h-[650px] ml-8"></div>}

            {indexProfil !== null ? (
                <div>{isMobile ? null : <RiwayatTransaksiDetail data={data} indexProfil={indexProfil} />}</div>
            ) : isMobile ? null : (
                <IconRiwayat />
            )}
        </div>
    );
};
