export const MainQueryKey = {
    UPLOAD_FILE: 'UPLOAD_FILE',
    GET_COUNTRIES: 'GET_COUNTRIES',
    GET_RELIGIONS: 'GET_RELIGIONS',
    GET_LANGUAGES: 'GET_LANGUAGES',
    ADD_FEEDBACK: 'ADD_FEEDBACK',
};

export const UserQueryKey = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',

    PROFILE: 'PROFILE',
    EDIT_PROFILE: 'EDIT_PROFILE',
    DELETE_PROFILE: 'DELETE_PROFILE',

    REMINDER: 'REMINDER',
    EDIT_REMINDER: 'EDIT_REMINDER',

    ADD_SUBSCRIPTION: 'ADD_SUBSCRIPTION',

    EDIT_LANGUAGE: 'EDIT_LANGUAGE',

    NOTIFICATION: 'NOTIFICATION',
};

export const CounselingQueryKey = {
    PSYCHOLOGIST: 'PSYCHOLOGIST',
    PRICE: 'PRICE',
    PAYMENT: 'PAYMENT',
    DETAIL_PAYMENT: 'DETAIL_PAYMENT',
    METHOD_PAYMENT: 'METHOD_PAYMENT',
    ADD_PAYMENT: 'ADD_PAYMENT',
    UPDATE_PAYMENT: 'UPDATE_PAYMENT',
    SESSION: 'SESSION',
    DETAIL_SESSION: 'DETAIL_SESSION',
    ADD_SESSION: 'ADD_SESSION',
    CANCEL_SESSION: 'CANCEL_SESSION',
    END_SESSION: 'END_SESSION',
    DISCOUNT_CODE: 'DISCOUNT_CODE',
    ADD_REVIEW: 'ADD_REVIEW',
    REVIEW: 'REVIEW',
    ADD_SCREENING: 'ADD_SCREENING',
    ROOM: 'ROOM',
};

export const PsychologicalTestsQueryKey = {
    ADD_RESULT: 'ADD_RESULT',
};
