import {useAppDispatch} from 'hooks/useAppDispatch';
import {useAppSelector} from 'hooks/useAppSelector';
import {UserQuery} from 'lib/react-query/query-hooks';
import {IUser} from 'model/api';
import {useState, useEffect, useRef} from 'react';
import {FiUser} from 'react-icons/fi';
import {Link} from 'react-router-dom';
import {setUser} from 'app/slice/main';

type ProfileDrawerType = {
    isOpen: boolean;
    setOpen: (v: boolean) => void;
    onLogout: () => void;
};

const ProfileDrawer = ({isOpen, onLogout}: ProfileDrawerType) => {
    return (
        <div
            className={`bg-white shadow-box absolute right-10 top-14 px-3 py-4 flex flex-col gap-3 text-sm text-left rounded-lg border ${
                isOpen ? '' : 'hidden'
            }`}
        >
            <p className="cursor-pointer select-none">Riwayat</p>
            <a
                className="cursor-pointer select-none"
                href="https://id.teduh.io/privacy-policy"
                target="_blank"
                rel="noreferrer"
            >
                Syarat dan Ketentuan
            </a>
            <a
                className="cursor-pointer select-none"
                href="https://id.teduh.io/privacy-policy"
                target="_blank"
                rel="noreferrer"
            >
                Kebijakan Privasi
            </a>
            <p className="cursor-pointer select-none text-danger" onClick={onLogout}>
                Keluar
            </p>
        </div>
    );
};

interface Props {
    onLogout: () => void;
}

export const ProfileNavBar = ({onLogout}: Props) => {
    const {user, user_auth} = useAppSelector(s => s.main);
    const dispatch = useAppDispatch();

    const [userData, setUserData] = useState(user);

    const {refetch: getProfile} = UserQuery.Profile({
        onSuccess(data: IUser.Profile.Response) {
            if (!userData) {
                setUserData(data.data);
                dispatch(setUser(data.data));
            }
        },
        enabled: false,
    });

    useEffect(() => {
        getProfile();
    }, []);


    const [open, setOpen] = useState(false);

    const handleOpenDrawer = () => {
        setOpen(!open);
    };

    const ref = useRef<any>(null);

    useEffect(() => {
        const handleClickOutsite = (e: any) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutsite);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsite);
        };
    }, [ref]);

    return (
        <>
            {userData ? (
                <div ref={ref}>
                    <div
                        className={`cursor-pointer select-none rounded-full h-8 w-8 ${
                            userData.profile_pic ? '' : 'border'
                        }`}
                        onClick={handleOpenDrawer}
                    >
                        {!!user_auth?.picture ? (
                            <img className=" rounded-full" src={user_auth?.picture || ''} />
                        ) : (
                            <FiUser className="h-full w-2/3 mx-auto" />
                        )}
                    </div>
                    <ProfileDrawer isOpen={open} setOpen={setOpen} onLogout={onLogout} />
                </div>
            ) : (
                <Link to="/signin">
                    <button className="px-4 py-1.5 rounded-md font-medium text-white text-sm bg-gradient-custom from-start-carousel-one to-end-carousel-one">
                        Sign In
                    </button>
                </Link>
            )}
        </>
    );
};
