import employeeWellness from 'assets/images/b2b/employee-wellness.svg';
import {PercentageCard} from './PercentageCard';
import Ribbon from '../../assets/images/b2b/ribbon.svg';
import LeftImage from '../../assets/images/b2b/indonesian.svg';
import RightImage from '../../assets/images/b2b/english.svg';
import {changeLanguage} from 'i18next';
import i18n, {translate} from 'i18n';
import {Link} from 'react-router-dom';
import { motion } from 'framer-motion';

export const EmployeeWellness = () => {
    const handleToggle = () => {
        changeLanguage(i18n.languages[0] == 'ind' ? 'en' : 'ind');
    };

    return (
        <div className="flex items-center justify-center bg-blueLight mb-18 xl:mb-36 flex-col sm:flex-row pt-[100px]">
            <div className="px-[7%] sm:w-[50%]">
                <motion.div
                        initial={{ opacity: 0, x: -120 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.9 }}
                >
                    <button onClick={handleToggle} className="relative w-[116px] h-[60px] rounded-full bg-white">
                        <img
                            src={LeftImage}
                            alt="Indonesian"
                            className="absolute left-[14px] top-[6px] w-[32px] h-12 z-10"
                        />
                        <img
                            src={RightImage}
                            alt="English"
                            className="absolute right-[14px] top-[6px] w-[32px] h-12 z-10"
                        />
                        <div
                            className={`w-[52px] h-[52px] top-[4px] left-[4px] rounded-full absolute transition-transform ${
                                i18n.languages[0] == 'ind' ? 'translate-x-0' : 'translate-x-14'
                            } bg-sky-blue`}
                        />
                    </button>
                </motion.div>
                <motion.div
                        initial={{ opacity: 0, x: -120 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1 }}
                >
                    <h1 className="font-semibold text-2xl sm:text-[52px]/normal pb-4 text-black">
                        Employee <span className="text-primary"> Wellness </span>Unleashed{' '}
                    </h1>
                </motion.div>
                <motion.div
                        initial={{ opacity: 0, x: -120 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1.4 }}
                >
                    <p className="mb-5 text-navbar-accent text-[14px] sm:text-base">{translate('b2b_ew_des')}</p>
                </motion.div>
                <motion.div
                        initial={{ opacity: 0, x: -120 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1.5 }}
                >
                    <button
                        onClick={() => window.open('https://wa.me/6285219498199')}
                        className="px-5 py-3 rounded-md font-medium text-white text-sm bg-gradient-custom from-start-carousel-one to-end-carousel-one"
                    >
                        {translate('contact_us_button')}
                    </button>
                    <Link to="/">
                        <button className="px-5 py-3 rounded-md font-semibold text-primary text-sm text-bold">
                            {translate('see_how_it_works_button')}
                        </button>
                    </Link>
                </motion.div>
            </div>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 2  }}
                className="sm:w-[40%] pl-[20px] relative"
            >
                <img src={employeeWellness} className=" pl-[20%] sm:w-[100%] sm:pl-0" />
                <PercentageCard
                    image={Ribbon}
                    number="77%"
                    description={translate('b2b_percentage_card_77')}
                    isWhite={true}
                    isNumberFirst={true}
                    position="-bottom-[30%] -left-[15%] -translate-y-[50%] flex-col-reverse"
                    imageStyle="ml-4 h-[55px] sm:h-[78px]"
                />
            </motion.div>
        </div> 
    );
};
