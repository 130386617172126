import {Bubble} from './Bubble';
import PeopleIcon from 'assets/images/home/people-icon.svg';
import VideoCallIcon from 'assets/images/home/video-call-icon.svg';
import TimetableIcon from 'assets/images/home/timetable-icon.svg';
import MoneyIcon from 'assets/images/home/money-icon.svg';

const panduanKonselingData = [
    {
        image: PeopleIcon,
        alt: 'Pilih layanan atau psikolog',
        label: 'Pilih layanan atau psikolog',
    },
    {
        image: VideoCallIcon,
        alt: 'Pilih video call atau voice call',
        label: 'Pilih video call atau voice call',
    },
    {
        image: TimetableIcon,
        alt: 'Tentukan waktu sesimu',
        label: 'Tentukan waktu sesimu',
    },
    {
        image: MoneyIcon,
        alt: 'Bayar sesi konseling',
        label: 'Bayar sesi konseling',
    },
];

export const PanduanKonseling = () => {
    return (
        <div className="flex flex-col sm:flex-row justify-around w-full sm:items-center sm:pt-24 mb-24 gap-8 px-4">
            <div className="text-dark">
                <h1 className=" text-xl sm:text-4xl font-semibold mb-3">Panduan Konseling</h1>
                <h1 className=" text-xl sm:text-4xl font-semibold">di Teduh</h1>
            </div>
            <div className="flex flex-wrap gap-10 justify-center">
                {panduanKonselingData.map((data, i) => (
                    <Bubble image={data.image} alt={data.alt} number={i + 1} label={data.label} />
                ))}
            </div>
        </div>
    );
};
