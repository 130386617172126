import Illustration from "assets/images/home/carousel/teduh-for-business-illustration.webp";
import KelebihanIllustration from "assets/images/home/kelebihan-illustration.svg";
import ClientOne from "assets/images/home/clients/client-1.png";
import ClientTwo from "assets/images/home/clients/client-2.png";
import ClientThree from "assets/images/home/clients/client-3.png";
import ClientFour from "assets/images/home/clients/client-4.png";
import ClientFive from "assets/images/home/clients/client-5.png";
import ClientSix from "assets/images/home/clients/client-6.png";
import ClientSeven from "assets/images/home/clients/client-7.png";
import ClientEight from "assets/images/home/clients/client-8.png";

const clients = [
  ClientOne,
  ClientTwo,
  ClientThree,
  ClientFour,
  ClientFive,
  ClientSix,
  ClientSeven,
  ClientEight
];

export const TeduhForBusiness = () => {
  return (
    <div className="py-10 my-20">
      <div className="flex flex-col px-8 md:flex-row justify-center items-center">
        <div className="md:w-1/4 mb-2">
          <div className="py-3 bg-secondary rounded-xl relative w-[18em] h-[20em]">
            <img src={Illustration} alt="Teduh For Business" className="absolute w-full scale-[1.6] top-[-15%] inset-x-[auto] inset-y-0" />
            <img src={KelebihanIllustration} alt="Kelebihan Teduh for Business" className="absolute bottom-[15%] left-[-10%] md:left-[-25%] w-full md:scale-[1.15]" />
          </div>
        </div>
        <div className="md:w-1/3 flex flex-col gap-2 md:gap-5">
          <h1 className="font-semibold text-2xl md:text-4xl">Teduh For Business</h1>
          <p className="text-navbar-accent text-sm md:text-md">Bersama Teduh dapatkan layanan terpersonalisasi untuk menjaga kesehatan mental karyawan atau sivitas akademika</p>
          <div className="flex gap-5 text-sm">
            <button className="bg-primary py-2 text-white rounded-lg w-full md:w-[10em]">Demo</button>
            <button className="border border-primary py-2 text-primary rounded-lg w-full md:w-[10em]">Lihat Layanan</button>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-8 items-center gap-4 px-5 md:px-0">
        <div className="flex gap-5 items-center justify-center md:justify-start flex-wrap">
          <h6 className="font-semibold">Dipercaya oleh:</h6>
          {clients.map((client) => {
            const ClientImage = client;
            return (
              <img src={ClientImage} alt="Clients" />
            );
          })}
        </div>
      </div>
    </div>
  );
};