import {Link} from 'react-router-dom';

interface Props {
    image: any;
    label: string;
    link: string;
}

export const LayananKonselingCard = ({image, label, link}: Props) => {
    return (
        <Link to={link} state={{title: label}}>
            <div className="py-2 px-4 md:px-10 md:py-4 rounded-lg shadow-card bg-white w-fit h-full w-full flex md:flex-col md:justify-between md:hover:-translate-y-1 md:duration-200 md:ease">
                <div className="md:h-full flex items-center md:py-6 mr-4">
                    <img src={image} alt={label} className="md:mx-auto md:self-start w-[6em] md:w-full" />
                </div>
                <p className="text-sm md:text-center md:mt-5 flex items-center md:block">{label}</p>
            </div>
        </Link>
    );
};
