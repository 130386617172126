import {FAQ} from 'components/home/FAQ';
import {LayananKonseling} from 'components/home/LayananKonseling';
import {LandmarkCarousel} from 'components/home/LandmarkCarousel';
import {Reviews} from 'components/home/Reviews';
import {Template} from 'components/template/Template';
import {Sessions} from 'components/home/Sessions';
import {TeduhAppInstall} from 'components/home/TeduhAppInstall';
import {AboutTeduh} from 'components/home/AboutTeduh';
import {TeduhForBusiness} from 'components/home/TeduhForBusiness';
import {PractitionerList} from 'components/home/PractitionerList';

export const HomePage = () => {
    return (
        <Template>
            <div className="content">
                <LandmarkCarousel options={{loop: true}} />
                <Sessions />
                <AboutTeduh />
                <LayananKonseling />
                <PractitionerList options={{containScroll: 'trimSnaps', loop: true, dragFree: true}} />
                <TeduhAppInstall />
                <TeduhForBusiness />
                <Reviews />
                <FAQ />
            </div>
        </Template>
    );
};
