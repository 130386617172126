import moment, { Moment } from 'moment';
import {FiCalendar, FiClock} from 'react-icons/fi';

interface Props {
    value: Moment;
    date?: boolean;
    time?: boolean;
}

export const DateTime = ({value, date = true, time = true}: Props) => {
    return (
        <div className="flex flex-1">
            {date ? (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'inline-flex',
                        flexDirection: 'row',
                        flex: 2,
                    }}
                >
                    <div className="p-1.5 bg-sky-blue rounded-full">
                        <FiCalendar className="text-primary" />
                    </div>
                    <div className="ml-1">
                        <h2 className="font-normal text-sm line-clamp-1">
                            {moment(value).format('dddd, DD MMM YYYY')}
                        </h2>
                    </div>
                </div>
            ) : null}
            {time ? (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'inline-flex',
                        flexDirection: 'row',
                        flex: 1,
                    }}
                >
                    <div className="p-1.5 bg-sky-blue rounded-full">
                        <FiClock className="text-primary" />
                    </div>

                    <div className="ml-1">
                        <h2 className="font-normal text-sm line-clamp-1">{moment(value).format('HH.mm')}</h2>
                    </div>
                </div>
            ) : null}
        </div>
    );
};
